import { createSelector } from "reselect";

export const getOrganisationState = state => state.organisation;

export const getOrganisationLoaded = state => getOrganisationState(state).loaded;

export const getOrganisation = state => getOrganisationState(state).organisation;

export const getOrganisationListLoaded = state => getOrganisationState(state).organisationListLoaded;

export const getOrganisationListLoading = state => getOrganisationState(state).organisationListLoading;

export const getLicenseInfo = state => (getOrganisation(state) ? getOrganisation(state).get("licenses") : null);

export const getOrganisationJS = createSelector([getOrganisation], organisation => organisation?.toJS() ?? null);

export const getOrganisationId = createSelector([getOrganisationJS], organisation => organisation?.id ?? null);

export const getOrganisationName = createSelector([getOrganisationJS], organisation => organisation?.name);

export const getOrganisationList = createSelector(
  state => getOrganisationState(state).organisationList,
  orgs => (orgs.toJS ? orgs.toJS() : orgs),
);

export const getOrganisationCount = createSelector([getOrganisationList], orgs => orgs.length);

export const getLicenseInfoJS = createSelector([getLicenseInfo], licenseInfo =>
  licenseInfo?.toJS ? licenseInfo.toJS() : licenseInfo,
);

export const getLicensesLoading = createSelector([getOrganisationState], state => state.licensesLoading);

export const getLicenseError = createSelector([getOrganisationState], state => state.licenseError);

export const getTotalLicenseCount = createSelector([getLicenseInfo], licenses => {
  if (!licenses) return 0;
  return licenses.reduce((count, license) => count + license.quantity, 0);
});

export const getTotalAssignedLicenseCount = createSelector([getLicenseInfo], licenses => {
  if (!licenses) return 0;
  return licenses.reduce((count, license) => count + license.allocated, 0);
});

export const getTotalAvailableLicenseCount = createSelector([getLicenseInfo], licenses => {
  if (!licenses) return 0;
  return licenses.reduce((count, license) => count + (license.quantity - license.allocated), 0);
});

export const getLicenseTotals = createSelector(
  [getTotalLicenseCount, getTotalAssignedLicenseCount, getTotalAvailableLicenseCount],
  (totalLicenseCount, totalAssignedLicenseCount, totalAvailableLicenseCount) => ({
    totalLicenseCount,
    totalAssignedLicenseCount,
    totalAvailableLicenseCount,
  }),
);

export const getHasDomainLicense = createSelector([getOrganisationJS], organisation => Boolean(organisation?.isDomain));

export const getIsApplyToAllLicense = createSelector([getLicenseInfoJS], licenses =>
  Boolean(licenses?.length && licenses.every(license => license.applyToAll)),
);

export const getShowOrgPicker = createSelector([getOrganisationState], state => Boolean(state.showOrgPicker));

export const getOrganisationFeatureFlags = createSelector(
  [getOrganisationJS],
  organisation => organisation?.features ?? null,
);

export const getHasOrgLibraries = createSelector([getOrganisationFeatureFlags], flags => Boolean(flags?.orgLibraries));
