import React from "react";
import PropTypes from "prop-types";
import SvgIcon from "../../ui/svg-icon";
import BrandBar from "../../ui/brand-bar";
import "./index.scss";

const IconCard = props => {
  const { icon, iconName, link, onClick, title } = props;

  const isSvg = icon.indexOf("<svg") > -1;

  let container;

  const handleClick = e => {
    if (onClick) {
      onClick();
    }
    container.blur();
  };

  return (
    <a
      className="icon-card"
      href={link}
      ref={c => (container = c)}
      onClick={handleClick}
      // don't set rel=noreferrer as we own the target and the referrer will be useful in analytics
      // eslint-disable-next-line
      target="_blank">
      <div className="icon-card__icon">
        {isSvg ? (
          <SvgIcon icon={icon} name={iconName} />
        ) : (
          <img
            src={icon}
            alt={title}
            className={`img-icon img-icon--${iconName}`}
          />
        )}
      </div>
      <span className="icon-card__title">{title}</span>
      <BrandBar />
    </a>
  );
};

IconCard.propTypes = {
  icon: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  // -
  onClick: PropTypes.func,
};

export default IconCard;
