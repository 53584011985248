import React, { Component } from "react";
import ChromeIcon from "../../../images/browser-icons/chrome.png";
import SafariIcon from "../../../images/browser-icons/safari.png";
import IEIcon from "../../../images/browser-icons/ie.png";
import EdgeIcon from "../../../images/browser-icons/edge.png";
import "./index.scss";

class SupportedBrowsers extends Component {
  render() {
    const browsers = [
      {
        name: "Chrome",
        version: "Latest version",
        icon: ChromeIcon,
        downloadLink: "https://www.google.com/chrome/",
      },
      {
        name: "Safari",
        version: "8+",
        icon: SafariIcon,
        downloadLink: "https://www.apple.com/safari/",
      },
      {
        name: "Internet Explorer",
        version: "11",
        icon: IEIcon,
        downloadLink: "https://windows.microsoft.com/ie",
      },
      {
        name: "Microsoft Edge",
        version: "Latest version",
        icon: EdgeIcon,
        downloadLink: "https://www.microsoft.com/windows/microsoft-edge",
      },
    ];

    const browserComponents = browsers.map((browser, i) => {
      return (
        <div className="browser" key={i}>
          <div className="browser__header">
            <div className="browser__icon">
              <img
                src={browser.icon}
                alt={browser.name}
                className="browser__icon-image"
              />
            </div>
            <span className="browser__name">
              <span>{browser.name}</span>
              <span className="browser__version">{browser.version}</span>
            </span>
          </div>
          <a
            href={browser.downloadLink}
            className="browser__link"
            rel="noreferrer"
            target="_blank">
            Download
          </a>
        </div>
      );
    });

    return <div className="browsers">{browserComponents}</div>;
  }
}

export default SupportedBrowsers;
