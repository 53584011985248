import React, { useState, useEffect, useCallback } from "react";
import { getInitialsFromName } from "../../../utils";
import StarIcon from "../../../images/svg/star.svg";
import "./index.scss";
import classNames from "classnames";
import SvgIcon from "../ui/svg-icon";
import { Tooltip } from "react-tooltip";

const Avatar = ({ url, id, name, size, overlayIcon, isLibraryManager }) => {
  const [imageFailed, setImageFailed] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);

  const loadImage = useCallback(url => {
    const image = new Image();
    image.src = url;

    image.onload = () => {
      setImageUrl(url);
      setImageFailed(false);
    };

    image.onerror = () => {
      setImageFailed(true);
    };
  }, []);

  // Handle initial load and url changes
  useEffect(() => {
    if (url) {
      loadImage(url);
    } else {
      setImageUrl(null);
    }
  }, [url, loadImage]);

  const style = {
    width: size,
    height: size,
  };

  const initialsScale = size ? size / 40 : 1;
  const initialsStyle = {
    transform: size ? `scale(${initialsScale})` : null,
  };

  let avatarIcon;
  if (imageUrl && !imageFailed) {
    avatarIcon = <img src={imageUrl} onError={() => setImageFailed(true)} alt={name} />;
  } else {
    avatarIcon = (
      <span className="avatar__initials" style={initialsStyle}>
        {getInitialsFromName(name)}
      </span>
    );
  }

  const className = classNames("avatar", {
    "avatar--library-manager": isLibraryManager,
  });

  const badgeClassName = classNames("avatar__badge", {
    "avatar__badge--library-manager": isLibraryManager,
  });

  const tooltipId = isLibraryManager ? `tooltip-${id}` : null;

  return (
    <div className="avatar-container" data-tooltip-content data-tooltip-id={tooltipId}>
      <div className={className} style={style}>
        {avatarIcon}
        {overlayIcon ? (
          <div className="overlay">
            <div className="icon">{overlayIcon}</div>
          </div>
        ) : null}
      </div>
      {isLibraryManager ? (
        <div className={badgeClassName}>
          <SvgIcon icon={StarIcon} name="star" className="avatar__badge-icon" />
        </div>
      ) : null}
      {tooltipId ? (
        <Tooltip id={tooltipId} multiline={false}>
          Library Manager
        </Tooltip>
      ) : null}
    </div>
  );
};

export default Avatar;
