import { addErrorMessage } from "../notifications/actions";
import { getOrganisationId } from "../organisation";
import { getUserId } from "../auth";
import * as OrgsAPI from "../organisation/orgs-api";

import {
  RECEIVE_LMS_DATA,
  SET_LMS_LOADED,
  SET_LMS_GENERATING,
  SET_REVOKE_CONFIRMATION_VISIBILITY,
} from "./action-types";

export function loadCredentials() {
  return async (dispatch, getState) => {
    const orgId = getOrganisationId(getState());
    const userId = getUserId(getState());

    const response = await OrgsAPI.getOrganisationLTICredentials(orgId, userId);
    if (!response.ok) {
      dispatch(addErrorMessage("There was a problem getting the credentials"));
    } else {
      let payload = {
        consumerKey: null,
        consumerSecret: null,
      };
      if (response.data.lti) {
        payload = response.data.lti;
      }
      dispatch({ type: RECEIVE_LMS_DATA, payload: payload });
    }
    dispatch({ type: SET_LMS_LOADED, payload: true });
  };
}

export function generateCredentials() {
  return async (dispatch, getState) => {
    const orgId = getOrganisationId(getState());
    const userId = getUserId(getState());
    dispatch({ type: SET_LMS_GENERATING, payload: true });

    const response = await OrgsAPI.generateLTICredentials(orgId, userId);
    if (!response.ok) {
      addErrorMessage("There was a problem generating the credentials");
    } else {
      dispatch({ type: RECEIVE_LMS_DATA, payload: response.data.credentials });
    }
    dispatch({ type: SET_LMS_GENERATING, payload: false });
  };
}

export function revokeCredentials() {
  return async (dispatch, getState) => {
    const orgId = getOrganisationId(getState());
    const userId = getUserId(getState());

    dispatch({ type: SET_LMS_GENERATING, payload: true });
    const response = await OrgsAPI.revokeLTICredentials(orgId, userId);
    if (!response.ok) {
      addErrorMessage("There was a problem revoking the credentials");
    } else {
      dispatch({
        type: RECEIVE_LMS_DATA,
        payload: {
          consumerKey: null,
          consumerSecret: null,
        },
      });
    }
    dispatch({ type: SET_LMS_GENERATING, payload: false });
  };
}

export function setRevokeConfirmationVisibility(payload) {
  return {
    type: SET_REVOKE_CONFIRMATION_VISIBILITY,
    payload,
  };
}
