import React, { useState } from "react";
import classNames from "classnames";
import Modal from "src/common/components/Modal";
import Checkbox from "src/views/components/checkbox";
import Button from "../../../button";

import "./index.scss";

interface IReasonModal {
  onClose?: () => void;
  onSubmit?: (reason) => void;
}

const ReasonsForVisit = [
  "Check-in on teacher activity",
  "Check-in on student activity",
  "Help teacher with content",
  "Check library settings",
  "Add remove library teachers/students",
  "Provide teacher support",
];
const CustomReason = "Other (specify reason)";

export default function ReasonsForVisitModal({
  onClose,
  onSubmit,
}: IReasonModal) {
  const [reasonForVisit, setReasonForVisit] = useState<string>("");
  const [customReasonChecked, setCustomReasonChecked] = useState<boolean>(
    false
  );

  const customReasonCssClass = classNames(
    "reasons-modal__list-box-custom-reason-input",
    {
      "reasons-modal__list-box-custom-reason-input-selected": customReasonChecked,
    }
  );

  const onReasonClick = selectedReason => {
    setCustomReasonChecked(false);
    setReasonForVisit(selectedReason);
  };

  const onCustomReasonClick = () => {
    //unselect other reasons
    setReasonForVisit("");
    setCustomReasonChecked(true);
  };

  const onChangeCustomReason = e => {
    const reason = e.currentTarget.value;
    setReasonForVisit(reason);
  };

  const onSubmitHandler = e => {
    onSubmit(reasonForVisit);
  };

  return (
    <Modal onClose={onClose} showX width={430}>
      <div className="reasons-modal">
        <h2 className="reasons-modal__title">
          Share why you are visiting this library.
        </h2>
        <p className="reasons-modal__desc">
          The teacher will be notified of your visit and will see your message
        </p>
        <div className="reasons-modal__list">
          <div className="reasons-modal__list-title">
            SELECT REASON (REQUIRED)
          </div>
          <div className="reasons-modal__list-box">
            {ReasonsForVisit.map((reason, idx) => (
              <div className="reasons-modal__list-box-item" key={idx}>
                <Checkbox
                  onClick={() => onReasonClick(reason)}
                  checked={reasonForVisit === reason}
                  value={reasonForVisit}
                  reverse>
                  {reason}
                </Checkbox>
              </div>
            ))}
            <div className="reasons-modal__list-box-custom-reason">
              <div className="reasons-modal__list-box-item no-border">
                <Checkbox
                  onClick={onCustomReasonClick}
                  checked={customReasonChecked}
                  value={reasonForVisit}
                  reverse>
                  {CustomReason}
                </Checkbox>
              </div>
              <input
                className={customReasonCssClass}
                type="text"
                disabled={!customReasonChecked}
                onChange={onChangeCustomReason}
                placeholder="Specify reason (max. 65 characters)"
                maxLength={65}
                value={customReasonChecked ? reasonForVisit : ""}
              />
            </div>
          </div>
        </div>
        <div className="reasons-modal__footer">
          <Button
            className="reasons-modal__footer-cancel-btn"
            onClick={onClose}>
            Cancel
          </Button>
          <Button disabled={!reasonForVisit} onClick={onSubmitHandler}>
            Visit Library
          </Button>
        </div>
      </div>
    </Modal>
  );
}
