import React from "react";
import classNames from "classnames";
import "./index.scss";

const Content = props => {
  const { className } = props;

  const cssClass = classNames("content", {
    [className]: className,
  });

  return <div className={cssClass}>{props.children}</div>;
};

export default Content;
