import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { BOOKCREATOR_ASSETS_URL } from "../../../config";
import { authActions, getIsBookCreatorUser, getDisplayName, getEmail, getUserId } from "../../../core/auth";
import NavItem from "./item";
import AvatarIcon from "../../../images/svg/user.svg";
import SvgIcon from "../../components/ui/svg-icon";
import Logo from "../../../images/book-creator-logo.png";
import getRoutes from "./routes";
import "./index.scss";
import {
  getOrganisationCount,
  getOrganisationId,
  getOrganisationFeatureFlags,
  organisationActions,
} from "../../../core/organisation";
import { useLocation } from "react-router-dom";

function Nav(props) {
  const {
    displayName,
    email,
    signOut,
    userId,
    organisationCount,
    setShowOrgPicker,
    organisationId,
    organisationFeatureFlags,
  } = props;

  const location = useLocation();

  const [avatarFailed, setAvatarFailed] = useState(false);

  const avatarUrl = `${BOOKCREATOR_ASSETS_URL}/${userId}/avatar`;

  useEffect(() => {
    const img = new Image();

    img.onerror = () => setAvatarFailed(true);
    img.src = avatarUrl;
  }, [avatarUrl]);

  const hasPortfoliosEnabled = organisationFeatureFlags?.studentPortfolios;
  const routes = getRoutes({
    signOut,
    organisationId,
    hasPortfoliosEnabled,
  });

  const navItems = routes.map((route, i) => {
    return (
      <NavItem
        to={route.route}
        href={route.href}
        icon={route.icon}
        iconName={route.iconName}
        location={location}
        active={location.pathname === route.route}
        disabled={route.disabled}
        childRoutes={route.childRoutes}
        onClick={route.onClick}
        key={i}>
        {route.name}
      </NavItem>
    );
  });

  const avatarStyle = {
    backgroundImage: !avatarFailed ? `url(${avatarUrl})` : null,
  };

  const avatarContents = avatarFailed ? <SvgIcon icon={AvatarIcon} iconName="avatar" /> : null;

  const avatarProps = {
    className: "nav__avatar fs-exclude",
    style: avatarStyle,
  };

  return (
    <nav className="nav">
      <svg className="nav__svg-defs">
        <defs>
          <linearGradient id="icon-selected">
            <stop offset="31%" stopColor="#0040C2" />
            <stop offset="51%" stopColor="#1357E2" />
            <stop offset="91%" stopColor="#00C2FF" />
          </linearGradient>
        </defs>
      </svg>
      <div className="nav__header">
        {organisationCount > 0 ? (
          <button onClick={() => setShowOrgPicker(true)} {...avatarProps}>
            {avatarContents}
          </button>
        ) : (
          <div {...avatarProps}>{avatarContents}</div>
        )}
        <div className="nav__user-details">
          <span className="nav__username fs-mask">{displayName}</span>
          <span className="nav__email fs-mask">{email}</span>
        </div>
      </div>
      <div className="nav__items">{navItems}</div>
      <a
        href="https://app.bookcreator.com"
        className="nav__logo"
        target="_blank"
        title="Go to the Book Creator app"
        rel="noopener noreferrer">
        <img src={Logo} alt="Book Creator" className="nav__logo-img" />
      </a>
    </nav>
  );
}

const mapStateToProps = createSelector(
  getIsBookCreatorUser,
  getDisplayName,
  getEmail,
  getUserId,
  getOrganisationCount,
  getOrganisationId,
  getOrganisationFeatureFlags,
  (isBookCreatorUser, displayName, email, userId, organisationCount, organisationId, organisationFeatureFlags) => ({
    isBookCreatorUser,
    displayName,
    email,
    userId,
    organisationCount,
    organisationId,
    organisationFeatureFlags,
  }),
);

const mapDispatchToProps = {
  ...authActions,
  ...organisationActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
