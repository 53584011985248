import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Licenses from "../pages/licenses";
import Teachers from "../pages/teachers";
import Portfolios from "../pages/portfolios";
import Admin from "../pages/admin";
import Analytics from "../pages/analytics";
import Resources from "../pages/resources";
import LMS from "../pages/lms";
import AppManagementPage from "../pages/apps/container";
import ImageSearchPage from "../pages/image-search";
import "./main.scss";

export default function Main() {
  return (
    <div className="main">
      <Routes>
        <Route path="/" element={<Licenses />} />
        <Route path="/teachers" element={<Teachers />} />
        <Route path="/portfolios" element={<Portfolios />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/analytics" element={<Analytics />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/lms" element={<LMS />} />
        <Route path="/apps" element={<AppManagementPage />} />
        <Route path="/image-search" element={<ImageSearchPage />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  );
}
