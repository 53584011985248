import React from "react";
import { List } from "immutable";
import Button from "src/common/components/Button";
import { IRecord } from "../types";
import Input, { ImportConfirmationHeader as Header } from "./input";
import "./index.scss";
import { bem } from "src/utils/bem";

interface IProps {
  inputs: List<IRecord>;
  onChange(inputs: List<IRecord>): void;
  loading: boolean;
  assignLicenses: boolean;
  availableLicenses: number;
  validSingleMessage: string;
  validMultipleMessage: string;
  invalidSingleMessage: string;
  invalidMultipleMessage: string;
  submitLabel: string;
  validator(value: string): boolean;
  onSubmit(): void;
  deleteInput(index: number): void;
}

export default function MultilineImportConfirmation(props: IProps) {
  const {
    inputs,
    onChange,
    loading,
    availableLicenses,
    assignLicenses: tryAssignLicenses,
    validSingleMessage,
    validMultipleMessage,
    invalidSingleMessage,
    invalidMultipleMessage,
    submitLabel,
    validator,
    onSubmit,
    deleteInput,
  } = props;

  const validInputCount = inputs.filter(input => validator(input.email)).size;
  const invalidInputCount = inputs.filter(input => !validator(input.email))
    .size;

  const rootClass = bem("multiline-import-confirmation");

  const assignLicenses = tryAssignLicenses && availableLicenses > 0;

  const remainingLicenses = inputs.reduce(
    (total, input) => (input.license ? total - 1 : total),
    availableLicenses
  );

  const hasRemainingLicenses = remainingLicenses > 0;

  const handleSubmit = evt => {
    if (invalidInputCount === 0) {
      onSubmit();
    }
    if (evt) {
      evt.preventDefault();
    }
  };

  const onCheckAll = () => {
    onChange(List(inputs.map(record => ({ ...record, license: true }))));
  };

  const onUncheckAll = () => {
    onChange(List(inputs.map(({ license, ...record }) => record)));
  };

  let inputToFocusHasBeenSet = false;

  const getValidMessage = () => {
    const message =
      validInputCount === 1 ? validSingleMessage : validMultipleMessage;
    return message.replace("%0", `<strong>${validInputCount}</strong>`);
  };

  const getInvalidMessage = () => {
    const message =
      invalidInputCount === 1 ? invalidSingleMessage : invalidMultipleMessage;
    return message.replace("%0", `<strong>${invalidInputCount}</strong>`);
  };

  const getLicenseMessage = () => {
    if (!assignLicenses) {
      return null;
    }

    const licensePluralise = `${remainingLicenses} license${
      remainingLicenses === 1 ? "" : "s"
    }`;

    return (
      <>
        <span>
          You have{" "}
          <strong
            className={rootClass.element("licenses", [
              hasRemainingLicenses ? "remaining" : "empty",
            ])}>
            {licensePluralise}
          </strong>{" "}
          available to assign.
        </span>
        <br />
      </>
    );
  };

  return (
    <form className={rootClass()} onSubmit={handleSubmit}>
      <p className={rootClass.element("intro")}>
        <span dangerouslySetInnerHTML={{ __html: getValidMessage() }} />
        <br />
        {invalidInputCount ? (
          <>
            <span dangerouslySetInnerHTML={{ __html: getInvalidMessage() }} />
            <br />
          </>
        ) : null}
        {getLicenseMessage()}
      </p>
      {assignLicenses ? (
        <div className={rootClass.element("header")}>Assign License</div>
      ) : null}
      <div className={rootClass.element("list")}>
        {assignLicenses && availableLicenses >= inputs.size && (
          <Header {...{ inputs, onCheckAll, onUncheckAll }} />
        )}
        {inputs.toArray().map((input, i) => {
          let focus = false;
          if (!inputToFocusHasBeenSet && !validator(input.email)) {
            focus = true;
            inputToFocusHasBeenSet = true;
          }

          const onInputChange = (value: IRecord) => {
            onChange(inputs.set(i, value));
          };

          const onDelete = () => deleteInput(i);

          return (
            <Input
              input={input}
              key={i}
              focus={focus}
              assignLicenses={assignLicenses}
              hasRemainingLicenses={hasRemainingLicenses}
              onChange={onInputChange}
              onDelete={onDelete}
              validator={validator}
              invalidMessage={invalidSingleMessage}
            />
          );
        })}
      </div>
      <Button
        minWidth={158}
        htmlType="submit"
        type="tertiary"
        disabled={!inputs.size || validInputCount !== inputs.size || loading}
        loading={loading}>
        {submitLabel || "Import"}
      </Button>
    </form>
  );
}
