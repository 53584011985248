import React, { Component } from "react";
import { connect } from "react-redux";
import { adminsActions } from "../../../../../core/admins";
import Modal from "../../../modal";
import Loader from "../../../ui/loader";

class RemoveAdminsConfirmation extends Component {
  render() {
    const {
      adminsToRemove,
      setAdminsToRemove,
      removeAdmins,
      removingAdmins,
    } = this.props;

    const adminsPluralOrSingle = adminsToRemove.size > 1 ? "admins" : "admin";

    const title = `Remove ${adminsToRemove.size} ${adminsPluralOrSingle}?`;

    return (
      <div>
        {removingAdmins ? (
          <Modal
            title={`Removing ${adminsPluralOrSingle}`}
            align="center"
            bodyRenderer={() => {
              return (
                <div style={{ height: 100, position: "relative" }}>
                  <Loader center />
                </div>
              );
            }}
            modal
            hideFooter
          />
        ) : (
          <Modal
            title={title}
            bodyRenderer={() => (
              <span>
                {`Remove ${adminsToRemove.size} ${adminsPluralOrSingle} from your organisation?`}
              </span>
            )}
            modal
            clickBackgroundToClose
            onConfirm={e => removeAdmins({ admins: adminsToRemove.toJS() })}
            confirmText="Remove"
            confirmButtonType="negative"
            onCancel={e => setAdminsToRemove(null)}
            cancelText="Cancel"
          />
        )}
        }
      </div>
    );
  }
}

const mapDispatchToProps = {
  ...adminsActions,
};

export default connect(null, mapDispatchToProps)(RemoveAdminsConfirmation);
