import { applyMiddleware, compose, legacy_createStore as createStore } from "redux";
import {thunk} from "redux-thunk";
// import { configureStore } from '@reduxjs/toolkit'
import reducers from "./reducers";

const store = (initialState = {}) => {
  let middleware = applyMiddleware(thunk);

  if (process.env.NODE_ENV !== "production") {
    // configure redux-devtools-extension
    // @see https://github.com/zalmoxisus/redux-devtools-extension
    const devToolsExtension = window.devToolsExtension;
    if (typeof devToolsExtension === "function") {
      middleware = compose(middleware, devToolsExtension());
    }
  }

  const store = createStore(reducers, initialState, middleware);
  
  // TODO: use redux toolkit (needs reducer refactor to serialisable objects)
  // https://redux.js.org/introduction/why-rtk-is-redux-today
  // const store = configureStore({reducer});

  if (module.hot) {
    module.hot.accept("./reducers", () => {
      store.replaceReducer(require("./reducers").default);
    });
  }

  return store;
};

export default store;
