import React from "react";
import SvgIcon from "../../../ui/svg-icon";
import WarningIcon from "../../../../../images/svg/warning.svg";
import "./index.scss";

function StudentName(props) {
  const { children } = props;

  return (
    <div className="student-name">
      <a href={`mailto:${children}`} className="fs-mask">
        {children}
      </a>
      <div className="student-name__warning">
        <SvgIcon icon={WarningIcon} cssClass="student-name__icon" />
        Student account
      </div>
    </div>
  );
}

export default StudentName;
