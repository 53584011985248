import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./index.scss";

export default function Loader(props) {
  const { center, fullPage, color, style, large, small, tiny } = props;

  const cssClass = classNames("loader", {
    "loader--large": large,
    "loader--small": small,
    "loader--tiny": tiny,
    "loader--center": center,
    "loader--full-page": fullPage,
    "loader--grey": color === "grey",
    "loader--white": color === "white",
    "loader--dark-grey": color === "darkgrey",
  });

  return (
    <div className={cssClass} style={style}>
      <div className="loader__ball-container">
        <div className="loader__ball" />
        <div className="loader__ball" />
        <div className="loader__ball" />
      </div>
    </div>
  );
}

Loader.propTypes = {
  center: PropTypes.bool,
  colour: PropTypes.string,
  css: PropTypes.string,
  large: PropTypes.bool,
  small: PropTypes.bool,
  tiny: PropTypes.bool,
};
