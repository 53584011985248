import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { TIMES } from "../../../pages/analytics/constants";
import Chart from "../chart";
import { totalBookCountRenderer, pieChartRenderer } from "./data-renderers";
import "./index.scss";

const Charts = props => {
  const { organisationId, auth } = props;
  return (
    <div className="charts">
      <Fragment>
        <Chart
          title="Logins"
          legendToggle
          organisationId={organisationId}
          timeFilter
          endpoint="sessions"
          auth={auth}
          isCustomAnalytics
        />
        <Chart
          title="Time using Book Creator (hours)"
          organisationId={organisationId}
          timeFilter
          rowFormatter={v => v / 3600}
          endpoint="sessions-duration"
          auth={auth}
          isCustomAnalytics
        />
      </Fragment>
      <Chart
        title="Total books created"
        endpoint="books/count/periodic"
        dataRenderer={totalBookCountRenderer}
        organisationId={organisationId}
        timeFilter
        auth={auth}
      />

      <Fragment>
        <Chart
          halfWidth
          title="Sessions by OS"
          chartType="PieChart"
          organisationId={organisationId}
          dataRenderer={pieChartRenderer}
          userFilter
          defaultTimeDimension={TIMES.DAY}
          endpoint="sessions-by-os"
          auth={auth}
          isCustomAnalytics
        />
        <Chart
          halfWidth
          title="Logins by provider"
          chartType="PieChart"
          organisationId={organisationId}
          dataRenderer={pieChartRenderer}
          userFilter
          defaultTimeDimension={TIMES.DAY}
          endpoint="sessions-by-provider"
          auth={auth}
          isCustomAnalytics
        />
      </Fragment>
    </div>
  );
};

Charts.propTypes = {
  isAuthenticated: PropTypes.bool,
  organisationId: PropTypes.string,
  auth: PropTypes.shape({
    expiresAt: PropTypes.number,
    getAuth: PropTypes.func,
    urls: PropTypes.object,
  }),
};

export default Charts;
