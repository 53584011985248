import PropTypes from "prop-types";
import React from "react";
import FullStory from "react-fullstory";
import { Provider, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";
import { FULLSTORY_ORG_ID } from "../config";
import { isAuthenticated } from "../core/auth";
import App from "./app";
import SignIn from "./pages/sign-in";
import Unsupported from "./pages/unsupported";

export default function Root({ history, store }) {
  return (
    <Provider store={store}>
      <FullStory org={FULLSTORY_ORG_ID} />
      {/* ConnectedRouter will use the store from Provider automatically */}
      <BrowserRouter store={store}>
        <Routes>
          <Route path="/sign-in/*" element={<SignIn />} />
          <Route path="/unsupported" element={<Unsupported />} />
          <Route path="*" element={<RequireAuth />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

function RequireAuth() {
  const location = useLocation();
  const isAuthed = useSelector(isAuthenticated);
  return isAuthed ? <App /> : <Navigate to="/sign-in" replace state={{ from: location.pathname }} />;
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
};
