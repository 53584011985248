import React, { Component } from "react";
import "./index.scss";

export default class TableList extends Component {
  render() {
    const { data } = this.props;

    return (
      <table className="table-list">
        <tbody>
          {data.map((row, i) => {
            return (
              <tr key={i}>
                <th>{row.heading}</th>
                <td>{row.body}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}
