import React, { Component } from "react";
import Select from "react-select";
import "./index.scss";

export default class SearchableDropdown extends Component {
  render() {
    const { onChange, options, placeholder, value } = this.props;

    return (
      <Select
        value={value}
        className="searchable-dropdown"
        classNamePrefix="searchable-dropdown"
        placeholder={placeholder}
        onChange={onChange}
        options={options}
        isSearchable
      />
    );
  }
}
