import React from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { BOOKCREATOR_API_URL, INTERCOM_APP_ID } from "src/config";
import { getAuth } from "src/core/auth";
import { getOrganisation } from "src/core/organisation";
import Toggle from "./toggle";
import * as Intercom from "./Intercom";

interface IState {
  booted: boolean;
  initialised: boolean;
  user_hash: string | null;
}

function IntercomComponent(props) {
  const [state, setState] = React.useState<IState>({
    booted: false,
    initialised: false,
    user_hash: null,
  });
  const { booted, initialised, user_hash } = state;
  const { auth, organisation } = props;
  const { authenticated, id: userId, email } = auth.toJS();

  React.useEffect(() => {
    if (!user_hash) {
      fetch(
        `${BOOKCREATOR_API_URL}/users/v2/${userId}/intercom/identity-verification`,
        {
          credentials: "include",
        }
      )
        .then(res => res.text())
        .then(user_hash => setState(state => ({ ...state, user_hash })));
    }
  }, [user_hash, userId]);

  React.useEffect(() => {
    // initialise on first run
    if (!initialised) {
      Intercom.initialise(INTERCOM_APP_ID);
      setState(state => ({ ...state, initialised: true }));
    }

    // boot once initialised and user_hash available
    if (!booted && initialised && user_hash && organisation) {
      const { id, name } = organisation.toJS();
      Intercom.boot({
        app_id: INTERCOM_APP_ID,
        user_id: userId,
        user_hash,
        email: email,
        company: { id, name },
      });
      setState(state => ({ ...state, booted: true }));
    }

    // de-initialise if logged out
    if (!authenticated && (booted || initialised)) {
      Intercom.shutdown();
      setState(state => ({ ...state, booted: false, initialised: false }));
    }
  }, [
    booted,
    initialised,
    user_hash,
    authenticated,
    email,
    organisation,
    userId,
  ]);

  return booted ? <Toggle /> : null;
}

const mapStateToProps = createSelector(
  getAuth,
  getOrganisation,
  (auth, organisation) => ({
    auth,
    organisation,
  })
);

export default connect(mapStateToProps, null)(IntercomComponent);
