import React from "react";
import classNames from "classnames";
import "./index.scss";

const ToggleItem = props => {
  const { value, onChange, label, selected } = props;

  const handleClick = e => {
    onChange(value);
    e.preventDefault();
  };

  const cssClass = classNames("toggle-item", {
    "toggle-item--selected": selected,
  });

  return (
    <button onClick={handleClick} className={cssClass}>
      {label}
    </button>
  );
};

export default ToggleItem;
