import React, { Component } from "react";
import { connect } from "react-redux";
import { teacherActions } from "../../../../../core/teachers";
import { getBookCountFromLicense } from "../../../../../utils/plan";
import NotEnoughLicenses from "../not-enough-licenses";
import Modal from "../../../modal";
import Loader from "../../../ui/loader";

class AssignLicensesConfirmation extends Component {
  render() {
    const {
      licensesToAssign,
      setLicensesToAssign,
      assigningLicenses,
      assignLicenses,
      teachersToAssignCurrentLicenses,
    } = this.props;

    const license = licensesToAssign && licensesToAssign.get("license");
    const bookCount = getBookCountFromLicense(license);

    const licenseAssignTitle =
      licensesToAssign && licensesToAssign.get("userIds").size > 1
        ? `Assign ${bookCount} book license to ${
            licensesToAssign.get("userIds").size
          } teachers?`
        : licensesToAssign
        ? `Assign ${bookCount} book license?`
        : null;
    const idsToAssign = licensesToAssign.get("userIds");
    const licenseCount = idsToAssign.size;
    const availableLicenseCount =
      license.get("quantity") - license.get("allocated");
    let remainingLicenses = availableLicenseCount;

    idsToAssign.forEach(id => {
      if (
        !teachersToAssignCurrentLicenses[id] ||
        teachersToAssignCurrentLicenses[id] !== license.get("id")
      ) {
        remainingLicenses--;
      }
    });

    return (
      <div>
        {licensesToAssign && remainingLicenses > -1 ? (
          assigningLicenses ? (
            <Modal
              title="Assigning licenses"
              align="center"
              bodyRenderer={() => {
                return (
                  <div style={{ height: 100, position: "relative" }}>
                    <Loader center />
                  </div>
                );
              }}
              modal
              hideFooter
            />
          ) : (
            <Modal
              title={licenseAssignTitle}
              body={`This will use ${licenseCount} of your available licenses. You will have ${remainingLicenses} unassigned licenses remaining.`}
              modal
              clickBackgroundToClose
              onConfirm={e => assignLicenses(licensesToAssign.toJS())}
              confirmText="Assign"
              onCancel={e => setLicensesToAssign(null)}
              cancelText="Cancel"
            />
          )
        ) : (
          <NotEnoughLicenses
            onCancel={e => setLicensesToAssign(null)}
            bookCount={bookCount}
            licenseCount={availableLicenseCount}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = {
  ...teacherActions,
};

export default connect(null, mapDispatchToProps)(AssignLicensesConfirmation);
