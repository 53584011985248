import React, { Component } from "react";
import "./email-list.scss";

export default class ConfirmEmail extends Component {
  render() {
    const { emails, maxHeight } = this.props;

    return (
      <ul className="email-list" style={{ maxHeight }}>
        {emails.map(email => {
          return (
            <li key={email} className="email-list__email fs-mask">
              {email}
            </li>
          );
        })}
      </ul>
    );
  }
}
