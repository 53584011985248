import React, { useState, useEffect } from "react";
import Table from "../../table";
import SelectionHeader from "../../selection-header";
import SelectionHeaderLabel from "../../selection-header/label";
import DropdownMenu from "../../ui/dropdown-menu";
import HeaderButton from "../../button/header";
import TruncatedText from "../../ui/truncated-text";
import RowActions from "../row-actions";
import MenuItems from "../menu-items";

const AdminsTable = props => {
  const [tableData, setTableData] = useState({
    headings: [
      {
        name: "Email",
        sortable: "email",
      },
      {
        name: "Organization",
        sortable: "organisation",
      },
      {
        name: "",
        width: 100,
      },
    ],
    body: null,
  });
  const [sortBy, setSortBy] = useState(props.sortBy || props.defaultSortBy);
  const [bulkAction, setBulkAction] = useState(null);

  const { admins, selectedAdmins, sortBy: propsSortBy, defaultSortBy, setAdminsToRemove } = props;

  useEffect(() => {
    const getAdminRows = () => {
      return admins.map(admin => {
        const adminJS = admin.toJS();
        const { email, organisation } = adminJS;
        return {
          ...adminJS,
          selected: selectedAdmins.indexOf(email) > -1,
          sortData: {
            email,
            organisation,
          },
          rowId: email,
          cells: [
            <a className="fs-mask" href={`mailto:${email}`}>
              {email}
            </a>,
            <TruncatedText className="fs-mask">{organisation}</TruncatedText>,
            <RowActions admin={adminJS} setAdminsToRemove={setAdminsToRemove} />,
          ],
        };
      });
    };
    setTableData(prevData => ({
      ...prevData,
      body: getAdminRows(),
    }));
    setSortBy(propsSortBy || defaultSortBy);
  }, [admins, selectedAdmins, propsSortBy, defaultSortBy, setAdminsToRemove]);

  useEffect(() => {
    if (selectedAdmins && !selectedAdmins.size && selectedAdmins.size !== 0) {
      resetBulkAction();
    }
  }, [selectedAdmins]);

  const handleRowSelect = emails => {
    props.toggleAdminSelection(emails);
  };

  const handleSelectAll = emails => {
    props.selectAdmins(emails);
  };

  const handleDeselectAdmins = emails => {
    props.deselectAdmins(emails);
  };

  const handleDeselectAllAdmins = () => {
    props.deselectAllAdmins();
  };

  const handleBulkAction = () => {
    const { selectedAdmins, setAdminsToRemove } = props;
    switch (bulkAction) {
      case "Remove admin":
        setAdminsToRemove(selectedAdmins.toJS());
        break;
      default:
        return;
    }
  };

  const resetBulkAction = () => {
    setBulkAction(null);
  };

  const getHeaderMenuItems = () => {
    return <MenuItems onRemoveAdminClick={() => setBulkAction("Remove admin")} />;
  };

  return (
    <div>
      {props.selectedAdmins?.size ? (
        <SelectionHeader onCancel={handleDeselectAllAdmins}>
          <SelectionHeaderLabel>
            <strong>{props.selectedAdmins.size}</strong> {props.selectedAdmins.size === 1 ? "admin" : "admins"} selected
          </SelectionHeaderLabel>
          <DropdownMenu
            placeholder="Bulk Actions"
            value={bulkAction}
            width={168}
            maxWidth={250}
            onChange={value => setBulkAction(value)}>
            {getHeaderMenuItems()}
          </DropdownMenu>
          <HeaderButton onClick={handleBulkAction} disabled={bulkAction === null}>
            Apply
          </HeaderButton>
        </SelectionHeader>
      ) : null}
      {tableData.body && (
        <Table
          data={tableData}
          sortBy={sortBy}
          selectable
          rowHeight={58}
          onRowSelect={handleRowSelect}
          onSelectAll={handleSelectAll}
          onDeselectAll={handleDeselectAdmins}
          onDeselectRows={handleDeselectAdmins}
          resetSelection={handleDeselectAllAdmins}
          onHeadingClick={props.setSortBy}
          selectedRows={props.selectedAdmins}
        />
      )}
    </div>
  );
};

export default AdminsTable;
