import { routerReducer } from "react-router-redux";
import { combineReducers } from "redux";
import { authReducer } from "./auth";
import { analyticsReducer } from "./analytics";
import { teachersReducer } from "./teachers";
import { organisationReducer } from "./organisation";
import { notificationsReducer } from "./notifications";
import { adminsReducer } from "./admins";
import { lmsReducer } from "./lms";

export default combineReducers({
  auth: authReducer,
  analytics: analyticsReducer,
  routing: routerReducer,
  teachers: teachersReducer,
  organisation: organisationReducer,
  notifications: notificationsReducer,
  admins: adminsReducer,
  lms: lmsReducer,
});
