import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { getConfirmations } from "../../../../core/admins";
import RemoveAdminsConfirmation from "./remove-admins";

class AdminConfirmations extends Component {
  render() {
    const { confirmations } = this.props;

    const { adminsToRemove, removingAdmins } = confirmations;
    return (
      <div>
        {adminsToRemove ? (
          <RemoveAdminsConfirmation
            adminsToRemove={adminsToRemove}
            removingAdmins={removingAdmins}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = createSelector(getConfirmations, confirmations => ({
  confirmations,
}));

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AdminConfirmations);
