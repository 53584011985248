import React, { Component } from "react";
import { connect } from "react-redux";
import { teacherActions } from "../../../../../core/teachers";
import Modal from "../../../modal";
import Loader from "../../../ui/loader";

class RemoveLicensesConfirmation extends Component {
  render() {
    const { licensesToRemove, setLicensesToRemove, removeLicenses, removingLicenses } = this.props;

    const teacherPluralOrSingle = licensesToRemove.size > 1 ? "teachers" : "teacher";
    const licensePluralOrSingle = licensesToRemove.size > 1 ? "licenses" : "license";
    const thisThese = licensesToRemove.size > 1 ? "These teachers" : "This teacher";
    const title = `Remove license from ${licensesToRemove.size} ${teacherPluralOrSingle}?`;

    return (
      <div>
        {removingLicenses ? (
          <Modal
            title={`Removing ${licensePluralOrSingle}`}
            align="center"
            bodyRenderer={() => {
              return (
                <div style={{ height: 100, position: "relative" }}>
                  <Loader center />
                </div>
              );
            }}
            modal
            hideFooter
          />
        ) : (
          <Modal
            title={title}
            bodyRenderer={() => <span>{thisThese} will be downgraded to the free Book Creator plan.</span>}
            modal
            clickBackgroundToClose
            onConfirm={e => removeLicenses({ teachers: licensesToRemove.toJS() })}
            confirmText="Remove"
            confirmButtonType="negative"
            onCancel={e => setLicensesToRemove(null)}
            cancelText="Cancel"
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = {
  ...teacherActions,
};

export default connect(null, mapDispatchToProps)(RemoveLicensesConfirmation);
