import React from "react";
import ArrowIcon from "../../../images/svg/arrow.svg";
import SvgIcon from "../../components/ui/svg-icon";
import "./index.scss";

const SelectionHeader = props => {
  const { onCancel, children } = props;

  return (
    <div className="selection-header">
      <button
        type="button"
        onClick={onCancel}
        className="selection-header__cancel">
        <SvgIcon icon={ArrowIcon} name="arrow" />
        Cancel
      </button>
      <div className="selection-header__actions">{children}</div>
    </div>
  );
};

export default SelectionHeader;
