import React, { Component } from "react";
import Helmet from "react-helmet";
import SupportedBrowsers from "../../components/supported-browsers";
import SvgIcon from "../../components/ui/svg-icon";
import UnsupportedBrowser from "../../../images/svg/unsupported-browser.svg";
import "./index.scss";

export default class Unsupported extends Component {
  render() {
    return (
      <div className="unsupported-browser">
        <Helmet title="Unsupported browser - Book Creator Admin" />
        <div className="unsupported-browser__content">
          <div className="unsupported-browser__icon">
            <SvgIcon icon={UnsupportedBrowser} name="unsupported-browser" />
          </div>
          <h1 className="unsupported-browser__title">
            Your browser isn't supported
          </h1>
          <p className="unsupported__body">
            To access this site, please upgrade to one of the following
            browsers:
          </p>
          <SupportedBrowsers />
        </div>
      </div>
    );
  }
}
