import React, { Component } from "react";
import { connect } from "react-redux";
import { teacherActions } from "../../../../../core/teachers";
import Modal from "../../../modal";
import Loader from "../../../ui/loader";

class RemoveTeachersConfirmation extends Component {
  render() {
    const { teachersToRemove, setTeachersToRemove, removeTeachers, removingTeachers } = this.props;

    const teacherPluralOrSingle = teachersToRemove.size > 1 ? "teachers" : "teacher";

    const title = `Remove ${teachersToRemove.size} ${teacherPluralOrSingle}?`;

    return (
      <div>
        {removingTeachers ? (
          <Modal
            title={`Removing ${teacherPluralOrSingle}`}
            align="center"
            bodyRenderer={() => {
              return (
                <div style={{ height: 100, position: "relative" }}>
                  <Loader center />
                </div>
              );
            }}
            modal
            hideFooter
          />
        ) : (
          <Modal
            title={title}
            bodyRenderer={() => (
              <span>
                {`Remove ${teachersToRemove.size} ${teacherPluralOrSingle} from your organisation?`}
                <br />
                <br />
                These teachers will be downgraded to the free Book Creator plan, and their accounts removed from your
                organisation.
              </span>
            )}
            modal
            clickBackgroundToClose
            onConfirm={e => removeTeachers({ teachers: teachersToRemove.toJS() })}
            confirmText="Remove"
            confirmButtonType="negative"
            onCancel={e => setTeachersToRemove(null)}
            cancelText="Cancel"
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = {
  ...teacherActions,
};

export default connect(null, mapDispatchToProps)(RemoveTeachersConfirmation);
