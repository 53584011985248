import React from "react";
import PropTypes from "prop-types";
import SvgIcon from "../../../ui/svg-icon";
import HelpIcon from "../../../../../images/svg/info--border.svg";
import getIcon from "../../get-icon";
import "./index.scss";

function AppPickerIntro(props) {
  const { id, name, privacyPolicyURL, termsURL } = props;

  return (
    <div className="app-picker-detail-intro">
      <div className="app-picker-detail-intro__icon">
        <img src={getIcon(id, true)} alt={`${name} icon`} />
      </div>
      <div className="app-picker-detail-intro__details">
        <div className="app-picker-detail-intro__heading">
          <h2 className="app-picker-detail-intro__title">{name}</h2>
        </div>
        <div className="app-picker-detail-intro__links">
          {privacyPolicyURL ? (
            <a
              className="app-picker-detail-intro__privacy"
              href={privacyPolicyURL}
              target="blank"
              rel="nofollow noopener">
              <SvgIcon icon={HelpIcon} /> Privacy Policy
            </a>
          ) : null}
          {termsURL ? (
            <a
              className="app-picker-detail-intro__terms"
              href={termsURL}
              target="blank"
              rel="nofollow noopener">
              Terms of Service
            </a>
          ) : null}
        </div>
      </div>
    </div>
  );
}

AppPickerIntro.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  privacyPolicyURL: PropTypes.string,
  termsURL: PropTypes.string,
};

export default AppPickerIntro;
