import React, { Component } from "react";
import MoreOptionsMenu from "../../ui/more-options-menu";
import MenuItems from "../menu-items";

export default class RowOptions extends Component {
  getMenuItems = () => {
    const { admin, setAdminsToRemove } = this.props;
    return (
      <MenuItems
        admin={admin}
        onRemoveAdminClick={e => setAdminsToRemove([admin.email])}
      />
    );
  };

  render() {
    return <MoreOptionsMenu textonly>{this.getMenuItems()}</MoreOptionsMenu>;
  }
}
