import React, { useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import Loader from "../../ui/loader";
import "./index.scss";

const FormButton = ({ type, text, href, flat, loading, disabled, minWidth, onClick }) => {
  const [clicks, setClicks] = useState([]);

  const handleClick = e => {
    if (onClick) {
      onClick();
    }

    const { layerX, layerY } = e.nativeEvent;
    setClicks(prevClicks => {
      const newClicks = [...prevClicks, { x: layerX, y: layerY }];

      // Schedule removal of click after animation
      setTimeout(() => {
        setClicks(currentClicks => currentClicks.slice(1));
      }, 1000);

      return newClicks;
    });
  };

  const waves = clicks.map((click, i) => (
    <div className="form-button__wave" key={i} style={{ margin: `${click.y}px 0 0 ${click.x}px` }} />
  ));

  const buttonClass = classNames("form-button", {
    "form-button--flat": flat,
  });

  const buttonStyle = {
    minWidth,
  };

  if (href) {
    return (
      <Link to={href} className={buttonClass}>
        {text}
      </Link>
    );
  }

  return (
    <button
      type={type || "button"}
      className={buttonClass}
      style={buttonStyle}
      disabled={loading || disabled}
      onClick={handleClick}>
      {loading ? <Loader tiny /> : text}
      {waves}
    </button>
  );
};

export default FormButton;
