import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classNames from "classnames";
import SvgIcon from "../../ui/svg-icon";
import ChevronIcon from "../../../../images/svg/chevron.svg";
import ChildItem from "../child-item";
import "./index.scss";

export default function NavItem(props) {
  const { active, children, childRoutes, href, icon, iconName, location, onClick, to, disabled } = props;

  const [expanded, setExpanded] = useState(false);

  const hasChildren = !!(childRoutes && childRoutes.length > 0);
  const parentClassName = classNames("nav__item-parent-button", {
    "nav__item-parent-button--open": expanded,
  });

  const button = (
    <div className="nav__item-button">
      <SvgIcon icon={icon} name={iconName} />
      <span className="nav__item-text">{children}</span>
    </div>
  );

  const handleParentClick = evt => {
    setExpanded(!expanded);
    evt.preventDefault();
  };

  const childNavItems = hasChildren ? (
    <div className="nav__item-children">
      {childRoutes.map(childRoute => (
        <ChildItem
          to={childRoute.route}
          href={childRoute.href}
          key={childRoute.name}
          active={location.pathname === childRoute.route}
          disabled={childRoute.disabled}>
          {childRoute.name}
        </ChildItem>
      ))}
    </div>
  ) : null;

  const parent = (
    <Fragment>
      <button className={parentClassName} onClick={handleParentClick}>
        {button}
        <SvgIcon icon={ChevronIcon} name="chevron" />
      </button>
      {expanded ? childNavItems : null}
    </Fragment>
  );

  const cssClass = classNames("nav__item", {
    "nav__item--active": active,
    "nav__item--parent": hasChildren,
  });

  const sharedProps = {
    className: cssClass,
    disabled,
    onClick,
  };

  const content = hasChildren ? parent : button;

  if (hasChildren) {
    return <div {...sharedProps}>{content}</div>;
  } else if (to) {
    return (
      <Link to={to} {...sharedProps}>
        {content}
      </Link>
    );
  } else if (onClick && !to && !href) {
    return <button {...sharedProps}>{content}</button>;
  } else {
    return (
      <a href={href} rel="noreferrer" target="_blank" {...sharedProps}>
        {content}
      </a>
    );
  }
}

NavItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.string,
  childRoutes: PropTypes.array,
  href: PropTypes.string,
  icon: PropTypes.object,
  iconName: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  onClick: PropTypes.func,
  to: PropTypes.string,
  disabled: PropTypes.bool,
};
