import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./index.scss";

class SvgIcon extends Component {
  static propTypes = {
    icon: PropTypes.object,
  };

  render() {
    const { style, name, icon, cssClass, className, ariaHidden } = this.props;

    const cssClasses = classNames("icon", {
      [`icon--${name}`]: name,
      [`${cssClass}`]: cssClass,
      className,
    });
    const iconStyle = style ? style : null;
    const svgIcon = React.createElement(icon);
    return (
      <span
        className={cssClasses}
        aria-hidden={ariaHidden}
        style={iconStyle}
      >
        {svgIcon}
      </span>
    );
  }
}

export default SvgIcon;
