import React, { Fragment } from "react";
import PropTypes from "prop-types";
import GroupTitle from "../group-title";
import CardGroup from "../../card-group";
import IconCard from "../../card-group/icon-card";

import ArtIcon from "../../../../images/teacher-resources/by-subject/art.png";
import EnglishIcon from "../../../../images/teacher-resources/by-subject/english.png";
import LanguagesIcon from "../../../../images/teacher-resources/by-subject/languages.png";
import MathIcon from "../../../../images/teacher-resources/by-subject/maths.png";
import ScienceIcon from "../../../../images/teacher-resources/by-subject/science.png";
import SocialIcon from "../../../../images/teacher-resources/by-subject/social.png";
import SpecialEdIcon from "../../../../images/teacher-resources/by-subject/special-ed.png";
import SportIcon from "../../../../images/teacher-resources/by-subject/sport.png";

const BySubject = props => {
  const { strings } = props;

  const cards = [
    {
      title: strings.english,
      link:
        "https://bookcreator.com/resources-for-teachers/english-language-arts/",
      icon: EnglishIcon,
      iconName: "english",
    },
    {
      title: strings.math,
      link: "https://bookcreator.com/resources-for-teachers/mathematics/",
      icon: MathIcon,
      iconName: "math",
    },
    {
      title: strings.science,
      link: "https://bookcreator.com/resources-for-teachers/science/",
      icon: ScienceIcon,
      iconName: "science",
    },
    {
      title: strings.socialStudies,
      link: "https://bookcreator.com/resources-for-teachers/social-studies/",
      icon: SocialIcon,
      iconName: "social",
    },
    {
      title: strings.languages,
      link: "https://bookcreator.com/resources-for-teachers/languages/",
      icon: LanguagesIcon,
      iconName: "languages",
    },
    {
      title: strings.specialEducation,
      link: "https://bookcreator.com/resources-for-teachers/special-education/",
      icon: SpecialEdIcon,
      iconName: "special-ed",
    },
    {
      title: strings.creativeArts,
      link: "https://bookcreator.com/resources-for-teachers/creative-arts/",
      icon: ArtIcon,
      iconName: "arts",
    },
    {
      title: strings.pe,
      link: "https://bookcreator.com/resources-for-teachers/pe-health/",
      icon: SportIcon,
      iconName: "pe",
    },
  ];

  return (
    <Fragment>
      <GroupTitle>{strings.title}</GroupTitle>
      <CardGroup>
        {cards.map(card => (
          <IconCard {...card} key={card.title} />
        ))}
      </CardGroup>
    </Fragment>
  );
};

BySubject.propTypes = {
  strings: PropTypes.shape({
    creativeArts: PropTypes.string.isRequired,
    english: PropTypes.string.isRequired,
    languages: PropTypes.string.isRequired,
    math: PropTypes.string.isRequired,
    pe: PropTypes.string.isRequired,
    science: PropTypes.string.isRequired,
    socialStudies: PropTypes.string.isRequired,
    specialEducation: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default BySubject;
