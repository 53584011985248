import Cookies from "js-cookie";

import {
  RECEIVE_ORG_DATA,
  RECEIVE_ORG_LIST,
  LOAD_LICENSES_DATA_ATTEMPT,
  LOAD_LICENSES_DATA_ERROR,
  RECEIVE_LICENSE_DATA,
  SET_ORGANISATION_NAME,
  SET_SHOW_ORG_PICKER,
  LOAD_ORG_LIST,
} from "./action-types";

import { signOut, signInError } from "../auth/actions";
import { resetResourceAuth } from "../analytics/actions";
import { addErrorMessage } from "../notifications/actions";
import {
  getOrganisationId,
  getOrganisationListLoaded,
  getOrganisationListLoading,
} from "./selectors";
import { getUserId } from "../auth";
import * as OrgsAPI from "./orgs-api";

export function getOrganisations(orgIdUrlParam) {
  return async (dispatch, getState) => {
    const orgsLoaded = getOrganisationListLoaded(getState());
    const orgsLoading = getOrganisationListLoading(getState());

    if (orgsLoaded || orgsLoading) return;

    dispatch({ type: LOAD_ORG_LIST });

    const lastOrg = orgIdUrlParam || Cookies.get("BC_ORG");
    const userId = getUserId(getState());

    const response = await OrgsAPI.getOrganisations(userId);
    if (!response.ok || !response.data?.length) {
      dispatch(signOut({ preserveError: true }));
      dispatch(
        signInError({
          message:
            response.message || "You do not have an account on this site",
        })
      );
    } else {
      const orgs = response.data;
      if (lastOrg) {
        orgs.forEach(org => {
          if (org.id === lastOrg) {
            dispatch(setOrganisation(org));
          }
        });
      }
      if (orgs.length && orgs.length > 1) {
        dispatch({
          type: RECEIVE_ORG_LIST,
          payload: orgs,
        });
      } else {
        dispatch(setOrganisation(orgs[0]));
      }
    }
  };
}

export function setOrganisation(org) {
  return async (dispatch, getState) => {
    const userId = getUserId(getState());
    const response = await OrgsAPI.getOrganisationById(org.id, userId);

    if (response.ok) {
      const org = response.data;
      dispatch({
        type: RECEIVE_ORG_DATA,
        payload: org,
      });
      dispatch({
        type: RECEIVE_LICENSE_DATA,
        payload: org.licenses,
      });
    }

    //reset analytics state to refresh org urls
    dispatch(resetResourceAuth());
    Cookies.set("BC_ORG", org.id);
  };
}

export function loadLicenses(id) {
  return async (dispatch, getState) => {
    dispatch({ type: LOAD_LICENSES_DATA_ATTEMPT });
    const orgId = id || getOrganisationId(getState());
    const userId = getUserId(getState());

    const response = await OrgsAPI.getOrganisationLicenses(orgId, userId);
    if (response.ok) {
      dispatch({
        type: RECEIVE_LICENSE_DATA,
        payload: response.data.licenses,
      });
    } else {
      dispatch({
        type: LOAD_LICENSES_DATA_ERROR,
      });
    }
  };
}

export function updateOrganisationName(payload) {
  return async (dispatch, getState) => {
    const currentName = getState().organisation.getIn(["organisation", "name"]);
    const orgId = getOrganisationId(getState());
    const userId = getUserId(getState());

    const newName = payload;
    if (!newName || !newName.length) {
      dispatch(addErrorMessage("Organisation name cannot be empty"));
      dispatch({
        type: SET_ORGANISATION_NAME,
        payload: currentName,
      });
      return;
    }
    const response = await OrgsAPI.renameOrganisation(orgId, newName, userId);
    if (!response.ok) {
      dispatch({
        type: SET_ORGANISATION_NAME,
        payload: currentName,
      });
      dispatch(
        addErrorMessage("Something went wrong updating the organisation name")
      );
    } else {
      dispatch({
        type: SET_ORGANISATION_NAME,
        payload: newName,
      });
    }
  };
}

export function setShowOrgPicker(payload) {
  return {
    type: SET_SHOW_ORG_PICKER,
    payload,
  };
}
