import React, { Component } from "react";
import Modal from "../../../modal";
import OrganisationPicker from "..";

export default class OrganisationPickerModal extends Component {
  render() {
    const { onClose } = this.props;

    return (
      <Modal
        onCancel={onClose}
        showX
        width={360}
        bodyRenderer={() => {
          return <OrganisationPicker onSubmit={onClose} />;
        }}
        modal
        hideFooter
      />
    );
  }
}
