import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { createSelector } from "reselect";
import { notificationsActions } from "../../../core/notifications";
import Header from "../../components/header";
import Content from "../../components/content";
import Button from "../../components/button";
import "./index.scss";
import SvgIcon from "../../components/ui/svg-icon";
import DownloadIcon from "../../../images/svg/download.svg";
import { getOrganisationId } from "src/core/organisation";
import { getUserId } from "src/core/auth";
import * as OrgsApi from "../../../core/organisation/orgs-api";

function Portfolios(props) {
  const { addErrorMessage, organisationId, userId } = props;
  const [loading, setLoading] = useState(false);
  const [download, setDownload] = useState(false);
  const [error, setError] = useState(null);
  const [result, setResult] = useState(null);

  const handleSubmit = async evt => {
    evt.preventDefault();
    if (result?.expiresAt <= Date.now()) {
      setResult(null);
    }
    setDownload(true);
  };

  useEffect(() => {
    if (result?.url && download) {
      setDownload(false);
      const a = document.createElement("a");
      a.href = result.url;
      a.download = `portfolios-${result.generatedAt}.csv`;
      a.click();
    }
  }, [result, download]);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      setResult(null);
      setError(null);

      const response = await OrgsApi.downloadStudentPortfolios(organisationId, userId);

      if (response.ok) {
        const data = response.data;
        data.expiresAt = Date.now() + 1000 * data.refreshIn;

        setResult(data);
      } else {
        setError(response.status);
        if (response.status !== 425) {
          addErrorMessage(response.message);
        }
      }

      setLoading(false);
    };
    if (!result) {
      getData();
    }
  }, [result, organisationId, userId, addErrorMessage]);

  const portfolioNotReady = error === 425;
  return (
    <>
      <Helmet title="Portfolios" />
      <Header title="Portfolios" />
      <Content>
        <div className="portfolios">
          {!loading && (!error || portfolioNotReady) ? (
            <form className="portfolios__form" onSubmit={handleSubmit}>
              <header className="portfolios__header">
                <h1
                  className="portfolios__title"
                  dangerouslySetInnerHTML={{
                    __html: portfolioNotReady ? "Portfolio data will be ready soon" : "Download portfolio data",
                  }}
                />
              </header>
              <div className="portfolios__body">
                <p>
                  {portfolioNotReady
                    ? "Student portfolio is enabled for your district and we are processing your student's links. This can take up to 24hrs from portfolios first being enabled."
                    : "This file contains student information and their shareable portfolio links. Please keep this information safe and in line with privacy guidance."}
                </p>
              </div>
              {result ? (
                <Button type="submit" className="portfolios__icon" loading={loading} disabled={loading}>
                  <SvgIcon icon={DownloadIcon} name="download" />
                  Download as CSV
                </Button>
              ) : null}
            </form>
          ) : null}
        </div>
      </Content>
    </>
  );
}
const mapStateToProps = createSelector(getOrganisationId, getUserId, (organisationId, userId) => ({
  organisationId,
  userId,
}));

const mapDispatchToProps = {
  ...notificationsActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Portfolios);
