import React from "react";
import PropTypes from "prop-types";
import "./index.scss";

const ColorCard = props => {
  const { color, link, title, onClick } = props;

  let container;

  const handleClick = e => {
    if (onClick) {
      onClick();
    }
    container.blur();
  };

  return (
    <a
      className={`color-card color-card--${color}`}
      href={link}
      ref={c => (container = c)}
      onClick={handleClick}
      // don't set rel=noreferrer as we own the target and the referrer will be useful in analytics
      // eslint-disable-next-line
      target="_blank">
      {title}
    </a>
  );
};

ColorCard.propTypes = {
  color: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  // -
  onClick: PropTypes.func,
};

export default ColorCard;
