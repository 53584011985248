import React from "react";
import dayjs from "dayjs";

export const getApiTime = time => (
  <span className="api-time">
    {dayjs(time).format("MMMM Do YYYY")}
    <small>{dayjs(time).format("h:mma")}</small>
  </span>
);

// adapted from moment-range https://github.com/rotaready/moment-range/blob/master/lib/moment-range.js
export const dateRange = (startDate, endDate) => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);

  const contains = (
    other,
    options = { excludeStart: false, excludeEnd: false }
  ) => {
    let oStart = other.valueOf();
    let oEnd = other.valueOf();
    let excludeStart = options.excludeStart || false;
    let excludeEnd = options.excludeEnd || false;

    if (options.hasOwnProperty("exclusive")) {
      excludeStart = excludeEnd = options.exclusive;
    }

    const startInRange = start < oStart || (start <= oStart && !excludeStart);
    const endInRange = end > oEnd || (end >= oEnd && !excludeEnd);

    return startInRange && endInRange;
  };

  const by = (interval, options = { excludeEnd: false, step: 1 }) => {
    const range = { start, end };

    return {
      [Symbol.iterator]() {
        const step = options.step || 1;
        const diff = Math.abs(range.start.diff(range.end, interval)) / step;
        let excludeEnd = options.excludeEnd || false;
        let iteration = 0;

        if (options.hasOwnProperty("exclusive")) {
          excludeEnd = options.exclusive;
        }

        return {
          next() {
            const current = range.start.clone().add(iteration * step, interval);
            const done = excludeEnd
              ? !(iteration < diff)
              : !(iteration <= diff);

            iteration++;

            return {
              done,
              value: done ? undefined : current,
            };
          },
        };
      },
    };
  };

  return { start, end, contains, by };
};
