import React, { useState } from "react";
import Modal from "../../../../common/components/Modal";
import apps from "../../../pages/apps/apps";
import Intro from "./intro";
import Details from "./details";
import "./index.scss";

function AppPrivacy(props) {
  const { id } = props;

  const [showModal, setShowModal] = useState(false);

  const appData = apps.find(app => app.id === id);

  return (
    <div className="app-privacy">
      <button
        className="app-privacy__toggle"
        onClick={() => setShowModal(true)}>
        See details
      </button>
      {showModal ? (
        <Modal title="Privacy" onClose={() => setShowModal(false)} width={445}>
          <div className="app-privacy__overlay">
            <Intro
              id={id}
              name={appData.name}
              termsURL={appData.termsURL}
              privacyPolicyURL={appData.privacyPolicyURL}
            />
            <Details {...appData} />
          </div>
        </Modal>
      ) : null}
    </div>
  );
}

export default AppPrivacy;
