export function sortRows(rows, sortBy) {
  const sort = sortBy.split(".");
  const sortKey = sort[0];
  const sortDirection = sort[1];
  rows = rows.sort((a, b) => {
    const aValue =
      a.sortData[sortKey] === true
        ? 1
        : !a.sortData[sortKey]
        ? 0
        : a.sortData[sortKey];
    const bValue =
      b.sortData[sortKey] === true
        ? 1
        : !b.sortData[sortKey]
        ? 0
        : b.sortData[sortKey];
    if (aValue < bValue) {
      return -1;
    } else if (aValue > bValue) {
      return 1;
    } else {
      return 0;
    }
  });
  if (sortDirection === "DESC") {
    rows = rows.reverse();
  }
  return rows;
}
