import React, { Component } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import SvgIcon from "../ui/svg-icon";
import "./flat.scss";

class FlatButton extends Component {
  render() {
    const {
      type,
      iconPosition,
      css,
      download,
      href,
      onClick,
      link,
      children,
      title,
      style,
      icon,
      iconName,
    } = this.props;

    const cssClass = classNames("flat-button", {
      "flat-button--negative": type && type === "negative",
      "flat-button--active": type && type === "active",
      "flat-button--primary": type && type === "primary",
      "flat-button--secondary": type && type === "secondary",
      "flat-button--icon-right": iconPosition && iconPosition === "right",
      "flat-button--no-icon": !icon,
      "flat-button--icon-only": !children,
      [`${css}`]: css,
    });

    if (link) {
      return (
        <Link className={cssClass} to={link} title={title} style={style}>
          {icon ? <SvgIcon icon={icon} name={iconName} /> : null}
          {children ? (
            <span className="flat-button__text">{children}</span>
          ) : null}
        </Link>
      );
    } else if (href) {
      return (
        <a
          className={cssClass}
          download={download}
          href={href}
          onClick={onClick}
          title={title}
          style={style}>
          {icon ? <SvgIcon icon={icon} name={iconName} /> : null}
          {children ? (
            <span className="flat-button__text">{children}</span>
          ) : null}
        </a>
      );
    } else {
      return (
        <button
          className={cssClass}
          onClick={onClick}
          title={title}
          style={style}>
          {icon ? <SvgIcon icon={icon} name={iconName} /> : null}
          {children ? (
            <span className="flat-button__text">{children}</span>
          ) : null}
        </button>
      );
    }
  }
}

export default FlatButton;
