import React, { useState, useRef } from "react";
import classNames from "classnames";
import "./index.scss";
import SvgIcon from "../../../ui/svg-icon";
import ChevronIcon from "src/images/svg/chevron.svg";
import Select, { components } from "react-select";
import LibraryName from "../../library-menu/table-item/name";
import LibraryProperty from "../../library-menu/table-item/property";
import UserInfo from "./user-info";
import { LibProps, List } from "../../library-menu/types";

interface TeacherLibraryDropdownProps {
  teacher: any;
  libraries: List<LibProps>;
  onVisitLibraryClick?: (libId: string) => void;
}
export default function TeacherLibraryDropdown({
  teacher,
  libraries,
  onVisitLibraryClick,
}: TeacherLibraryDropdownProps) {
  const ref = useRef(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const teacherLibraries: any = libraries.map(library => ({
    value: library.id,
    label: library.name,
    authors: library.userCount,
    books: library.bookCount,
  }));

  const CustomOption = ({ data, innerRef, innerProps, ...props }) => (
    <components.Option {...props} innerRef={innerRef} {...innerProps}>
      <div onClick={toggleMenuIsOpen} className="library-menu-option">
        <LibraryName name={data.label} isOwner={true} className="library-menu-option">
          <div className="library-menu-item__description">
            <LibraryProperty name="name">{data.label}</LibraryProperty>
            <div className="library-menu-item__details">
              <LibraryProperty name="authors">{data.authors ?? 0} authors</LibraryProperty>
              <div className="library-menu-item__separator" />
              <LibraryProperty name="books">{data.books ?? 0} books</LibraryProperty>
            </div>
          </div>
        </LibraryName>
        <LibraryProperty name="visit" visitLabel="Visit" onClickHandler={() => onVisitLibraryClick(data.value)} />
      </div>
    </components.Option>
  );

  const WrapperComponent = (componentName, { children, ...props }) => {
    const cssClass = classNames({
      isOpen: menuIsOpen,
    });
    const Component = components[componentName];
    return (
      <Component {...props} className={cssClass}>
        {children}
      </Component>
    );
  };

  const toggleMenuIsOpen = () => {
    setMenuIsOpen(value => !value);
    const selectEl = ref.current;
    if (!selectEl) return;
    if (menuIsOpen) selectEl.blur();
    else selectEl.focus();
  };

  return (
    <div className="teacher-libs">
      <Select
        ref={ref}
        menuIsOpen={menuIsOpen}
        classNamePrefix="teacher-libs-select"
        components={{
          Control: props => WrapperComponent("Control", props),
          DropdownIndicator: props =>
            WrapperComponent("DropdownIndicator", {
              ...props,
              children: <SvgIcon icon={ChevronIcon} onClick={toggleMenuIsOpen} />,
            }),
          Option: CustomOption,
          ValueContainer: () => UserInfo({ libraries, onClick: toggleMenuIsOpen, teacher }),
          IndicatorSeparator: () => null,
        }}
        name="libraries"
        options={teacherLibraries}
      />
    </div>
  );
}
