import React, { Component } from "react";
import classNames from "classnames";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import FlatButton from "../button/flat";
import CrossIcon from "../../../images/svg/cross.svg";
import ArrowIcon from "../../../images/svg/arrow.svg";
import SvgIcon from "../ui/svg-icon";
import Loader from "../ui/loader";
import "./index.scss";

export default class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promptedToCancel: false,
      bodyWidth: props.width ? props.width : null,
      visible: true,
    };
  }

  componentDidMount() {
    const { onMount } = this.props;
    document.addEventListener("keydown", this.handleKeyDown);
    if (onMount) {
      onMount();
    }
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown = e => {
    const { onCancel, disableEscToClose } = this.props;
    if (e.which === 27 && !disableEscToClose) {
      if (onCancel) {
        onCancel(e);
      }
    }
  };

  handlePromptToCancelClick = e => {
    this.setState({
      promptedToCancel: true,
    });
    e.preventDefault();
  };

  handleBackgroundClick = e => {
    const { onCancel, onConfirm, clickBackgroundToClose } = this.props;
    const event = onCancel ? onCancel : onConfirm ? onConfirm : null;
    if (clickBackgroundToClose && event) {
      event(e);
    }
  };

  render() {
    const {
      title,
      body,
      bodyRenderer,
      onConfirm,
      onCancel,
      showX,
      showBackButton,
      onBackButtonClick,
      confirmText,
      cancelText,
      confirmButtonType,
      confirmButtonHref,
      confirmButtonDownload,
      cancelButtonType,
      promptToCancel,
      promptToCancelText,
      promptToCancelType,
      modal,
      children,
      topOfScreenOnIpad,
      hideFooter,
      align,
      notransition,
      loadingMessage,
    } = this.props;

    const { promptedToCancel } = this.state;

    const modalClass = classNames("modal", {
      "modal--top-on-ipad": topOfScreenOnIpad,
      "modal--no-transition": notransition,
      [`modal--align-${align}`]: align,
    });

    const showTransition = !notransition;

    return (
      <TransitionGroup>
        <CSSTransition
          classNames="modal"
          timeout={{
            exit: showTransition ? 1000 : 0,
            enter: showTransition ? 1000 : 0,
          }}
          appear={showTransition}>
          <div className={modalClass} onClick={this.handleBackgroundClick}>
            <div
              open
              className="modal__dialog scrollable"
              onClick={e => {
                e.stopPropagation();
              }}
              style={{
                width: this.state.bodyWidth ? this.state.bodyWidth : null,
                visibility: this.state.visible ? "visible" : "hidden",
              }}>
              {title ? (
                <header className="modal__header">
                  <h1
                    className="modal__title"
                    dangerouslySetInnerHTML={{ __html: title }}
                  />
                </header>
              ) : null}
              <div className="modal__body">
                {body ? (
                  <div dangerouslySetInnerHTML={{ __html: body }} />
                ) : null}
                {bodyRenderer ? bodyRenderer() : null}
                {children}
              </div>
              {!hideFooter ? (
                <footer className="modal__footer">
                  {promptToCancel && !promptedToCancel ? (
                    <FlatButton
                      onClick={this.handlePromptToCancelClick}
                      type={promptToCancelType ? promptToCancelType : null}>
                      {promptToCancelText ? promptToCancelText : "Cancel"}
                    </FlatButton>
                  ) : null}
                  {onCancel && (!promptToCancel || promptedToCancel) ? (
                    <FlatButton
                      onClick={onCancel}
                      type={cancelButtonType ? cancelButtonType : null}>
                      {cancelText ? cancelText : "Cancel"}
                    </FlatButton>
                  ) : null}
                  {onConfirm ? (
                    <FlatButton
                      onClick={onConfirm}
                      type={confirmButtonType ? confirmButtonType : null}
                      download={confirmButtonDownload}
                      href={confirmButtonHref}>
                      {confirmText ? confirmText : "Confirm"}
                    </FlatButton>
                  ) : null}
                </footer>
              ) : null}
              {onCancel && showX ? (
                <button
                  className="modal__close"
                  onClick={e => {
                    e.preventDefault();
                    onCancel();
                  }}>
                  <SvgIcon icon={CrossIcon} name="cross" />
                </button>
              ) : null}
              {loadingMessage ? (
                <div className="modal__loading">
                  <p className="modal__loading-message">{loadingMessage}</p>
                  <Loader />
                </div>
              ) : null}
              {showBackButton && onBackButtonClick ? (
                <button className="modal__back" onClick={onBackButtonClick}>
                  <SvgIcon icon={ArrowIcon} name="arrow" />
                </button>
              ) : null}
            </div>
            {modal ? <div className="modal__background" /> : null}
          </div>
        </CSSTransition>
      </TransitionGroup>
    );
  }
}
