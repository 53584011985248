import * as React from "react";
import classnames from "classnames";
import "./index.scss";

interface Props {
  value: boolean;
  onChange(value: boolean): any;
}

export default function Switch({ value, onChange }: Props) {
  const cssClass = classnames("switch", {
    "switch--on": value,
  });
  return (
    <div className={cssClass} onClick={() => onChange(!value)}>
      <div className="switch__toggle" />
    </div>
  );
}
