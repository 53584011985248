import { firebaseAuth, firebaseDb, onValueOnce } from "../firebase";
import {
  getIdTokenResult,
  GoogleAuthProvider,
  onIdTokenChanged,
  signInWithCustomToken as firebaseSignInWithCustomToken,
  signInWithPopup,
  signOut as firebaseSignOut,
} from "firebase/auth";
import Cookies from "js-cookie";
import * as FullStory from "react-fullstory";
import superagent from "superagent";
import {
  AUTH_COOKIE_NAME,
  AUTH_COOKIE_DOMAIN,
  PAYMENTS_DOMAIN,
  BOOKCREATOR_API_URL,
  BOOKCREATOR_AUTH_URL,
} from "../../config";

import {
  INIT_AUTH,
  SIGN_IN_ERROR,
  SIGN_IN_SUCCESS,
  SIGN_OUT_SUCCESS,
  CREATE_USER_ATTEMPT,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  CLEAR_AUTH_ERROR,
  CACHE_AUTH_TOKEN,
  SET_USER_ORG_ROLES,
  OFFICE_365_ERROR,
  SET_VERIFIED,
  SET_UNVERIFIED,
} from "./action-types";
import * as OrgsAPI from "../organisation/orgs-api";

const cookieAttributes = {
  path: "/",
  secure: true,
  domain: AUTH_COOKIE_DOMAIN,
  expires: 1 / 24,
};

export function initAuth(user) {
  return dispatch => {
    if (user) {
      dispatch(onSignIn({ user }));
    } else {
      dispatch(doServerAuth());
    }
    // Check token when returning from background tab
    document.addEventListener("visibilitychange", () => {
      if (!document.hidden) {
        dispatch(checkAuthCookie());
      }
    });
  };
}

export function doServerAuth() {
  return dispatch => {
    superagent
      .post(`${PAYMENTS_DOMAIN}/v1/admin/auth`)
      .withCredentials()
      .end((err, res) => {
        if (err) {
          dispatch({
            type: INIT_AUTH,
          });
        } else {
          dispatch(signInWithCustomToken(res.body));
        }
      });
  };
}

export function signInWithCustomToken(token) {
  return dispatch => {
    firebaseSignInWithCustomToken(firebaseAuth, token)
      .then(() => {
        dispatch(initAuth(firebaseAuth.currentUser));
      })
      .catch(error => {
        dispatch({
          type: INIT_AUTH,
        });
      });
  };
}

function authenticate(provider) {
  return dispatch => {
    signInWithPopup(firebaseAuth, provider)
      .then(result => {
        dispatch(validateAuthWithServer({ user: result.user, delay: true }));
      })
      .catch(error => {
        console.error(error);
        dispatch(signInError(error));
      });
  };
}

export function signInError(error) {
  return {
    type: SIGN_IN_ERROR,
    payload: error,
  };
}

export function onSignIn(payload) {
  return dispatch => {
    const { user } = payload;
    const { uid, photoURL } = user;
    onValueOnce(firebaseDb, `/users/${uid}/`)
      .then(snapshot => {
        const userData = snapshot;
        dispatch(
          signInSuccess({
            user: {
              ...user,
              email: userData?.email ?? user?.email ?? null,
              displayName: userData
                ? userData.displayName
                : user
                  ? user.displayName
                  : null,
              photoURL,
            },
          }),
        );
      })
      .catch(error => {
        console.error(error);
      });
  };
}

export function signInSuccess(payload) {
  const { user } = payload;
  return dispatch => {
    const emailDomain =
      user.email && user.email.includes("@")
        ? "@" + user.email.split("@").pop()
        : null;
    FullStory.identify(user.uid, {
      isAdmin_bool: true,
      emailDomain_str: emailDomain,
    });
    const { emailVerified } = user;
    dispatch(getAuthToken(true));
    setVerified(emailVerified);
    // Manually refresh the token after 45 minutes
    setInterval(getAuthToken, 1000 * 60 * 45);
    dispatch({
      type: SIGN_IN_SUCCESS,
      payload: user,
    });
  };
}

export function createUserAttempt(user) {
  return {
    type: CREATE_USER_ATTEMPT,
    payload: user,
  };
}

export function createUserSuccess(user) {
  return {
    type: CREATE_USER_SUCCESS,
    payload: user,
  };
}

export function createUserError(error) {
  return {
    type: CREATE_USER_ERROR,
    payload: error,
  };
}

export function validateAuthWithServer(payload) {
  return async dispatch => {
    const { user } = payload;
    const { currentUser } = firebaseAuth;
    const { token } = await getIdTokenResult(currentUser, true);
    Cookies.set(AUTH_COOKIE_NAME, token, cookieAttributes);

    const response = await OrgsAPI.getOrganisations(user.uid);
    if (response.ok && response.data.length) {
      dispatch(signInSuccess({ user }));
    } else {
      const errorMessage =
        response.message ||
        "Something went wrong logging you in, please try again.";
      dispatch(signOut({ preserveError: true }));
      dispatch(signInError({ message: errorMessage }));
    }
  };
}

async function authEmailPasswordUser(params) {
  const res = await fetch(`${BOOKCREATOR_AUTH_URL}/email`, {
    method: "POST",
    credentials: "include",
    body: new URLSearchParams(params),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });

  if (res.status === 400) {
    throw new Error("Invalid email or password");
  } else if (res.status === 200) {
    const { token } = await res.json();
    const { user } = await firebaseSignInWithCustomToken(firebaseAuth, token);
    return user;
  }
  return null;
}

async function allowedAsTeacher({ email, userId }) {
  const body = new URLSearchParams({ email });
  if (typeof userId === "string") body.set("userId", userId);
  try {
    const res = await fetch(`${BOOKCREATOR_AUTH_URL}/allowed-as-teacher`, {
      body,
      method: "POST",
      credentials: "omit",
    });
    if (res.status === 403) {
      return false;
    }
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
}

export function signInWithEmailAndPassword(payload) {
  return async dispatch => {
    try {
      //reset unverified state
      dispatch(setUnverified(null));

      const { email, password } = payload;

      const params = {
        email,
        password,
        isTeacher: true,
      };

      const user = await authEmailPasswordUser(params);
      if (!user) {
        dispatch(setUnverified(email));
        return;
      }

      dispatch(validateAuthWithServer({ user }));
    } catch (error) {
      dispatch(signInError(error));
    }
  };
}

export function createUserWithEmailAndPassword(payload) {
  const { email, password, displayName } = payload;
  return async dispatch => {
    try {
      dispatch(createUserAttempt(payload));
      const params = {
        email,
        password,
        displayName,
        isTeacher: true,
        registrationAttempt: true,
      };
      const canSignInWithEmailAddress = await allowedAsTeacher({
        email,
      });
      if (!canSignInWithEmailAddress) {
        dispatch(
          signInError("You can't sign up as admin with this email address"),
        );
        return;
      }

      const user = await authEmailPasswordUser(params);
      if (!user) {
        dispatch(setUnverified(email));
        return;
      }

      dispatch(validateAuthWithServer({ user }));
    } catch (error) {
      dispatch(createUserError(error));
    }
  };
}

export function checkAuthCookie() {
  return dispatch => {
    const authCookie = Cookies.get(AUTH_COOKIE_NAME);
    if (!authCookie) {
      dispatch(getAuthToken());
    }
  };
}

let idTokenUnsubscribe;

export function getAuthToken(changeListener) {
  return async dispatch => {
    const { currentUser } = firebaseAuth;
    if (currentUser) {
      const { token } = await getIdTokenResult(currentUser, true);
      dispatch(cacheAuthToken(token));
      logAccess();
    }
    if (changeListener) {
      idTokenUnsubscribe = onIdTokenChanged(firebaseAuth, async user => {
        if (user) {
          const { currentUser } = firebaseAuth;
          if (currentUser) {
            const { token } = await getIdTokenResult(currentUser);
            dispatch(cacheAuthToken(token));
          }
        }
      });
    }
  };
}

function cacheAuthToken(token) {
  return dispatch => {
    if (token) {
      Cookies.set(AUTH_COOKIE_NAME, token, cookieAttributes);
      const payload = JSON.parse(atob(token.split(".")[1]));
      dispatch({
        type: SET_USER_ORG_ROLES,
        payload: payload["bc_orgs"],
      });
      dispatch({
        type: CACHE_AUTH_TOKEN,
        payload: token,
      });
    }
  };
}

export function logAccess() {
  fetch(`${BOOKCREATOR_API_URL}/v2/access-log/re-auth`, {
    method: "POST",
    credentials: "include",
    keepalive: true,
  });
}

export function signInWithGoogle() {
  let provider = new GoogleAuthProvider();
  return authenticate(provider);
}

export function signOut(payload) {
  return dispatch => {
    firebaseSignOut(firebaseAuth).then(() => {
      dispatch(signOutSuccess(payload));
    });
  };
}

export function clearAuthError() {
  return {
    type: CLEAR_AUTH_ERROR,
  };
}

export function signOutSuccess(payload) {
  Cookies.remove(AUTH_COOKIE_NAME, cookieAttributes);
  Cookies.remove("BC_ORG");
  FullStory.identify(false); // log user out of FullStory

  if (firebaseAuth.currentUser) {
    firebaseAuth.currentUser.reload();
  }
  if (idTokenUnsubscribe) {
    idTokenUnsubscribe();
  }
  return {
    type: SIGN_OUT_SUCCESS,
    payload,
  };
}

export function doOffice365Login(payload) {
  const { token } = payload;
  return dispatch => {
    const endpoint = `/users/v2/providers/office365`;
    superagent
      .post(`${BOOKCREATOR_API_URL}${endpoint}`)
      .send({
        token,
        userProperties: {
          isTeacher: true,
          needsLibraryOnboarding: true,
        },
      })
      .end((err, res) => {
        if (err) {
          switch (err.status) {
            case 401:
              let message =
                "You already have account, please use that to sign in";
              if (
                err.response &&
                err.response.body &&
                err.response.body.providerIds &&
                err.response.body.providerIds.length
              ) {
                switch (err.response.body.providerIds[0]) {
                  case "google.com":
                    message =
                      "You are already registered with a Google account, please use that to sign in";
                    break;
                  case "password":
                    message =
                      "You are already registered with an email account, please use that to sign in";
                    break;
                  default:
                    break;
                }
              }
              dispatch(signInError({ message }));
              // window.push("/sign-in");
              break;
            default:
              dispatch(onOffice365Error());
          }
        } else {
          const customToken = res.body;
          firebaseSignInWithCustomToken(firebaseAuth, customToken)
            .then(() => {
              dispatch(
                onSignIn({
                  user: firebaseAuth.currentUser,
                  accountType: "teacher",
                }),
              );
            })
            .catch(error => {
              console.error(error);
            });
        }
      });
  };
}

export function onOffice365Error(error) {
  return dispatch => {
    dispatch({
      type: OFFICE_365_ERROR,
      payload: error ? error : "Something went wrong logging you in",
    });
  };
}

export function clearOffice365Error() {
  return {
    type: OFFICE_365_ERROR,
    payload: null,
  };
}

export function setVerified(payload) {
  return {
    type: SET_VERIFIED,
    payload,
  };
}

export function setUnverified(payload) {
  return {
    type: SET_UNVERIFIED,
    payload,
  };
}
