import React from "react";
import classNames from "classnames";
import "./index.scss";
import { useEffect, useRef } from "react";
import { IndicatorPosition } from "..";

interface LibraryNavItemProps {
  active?: boolean;
  onActive: (rect: IndicatorPosition) => void;
  onClick: () => void;
  label: string;
  testid: string;
}

export default function LibraryNavItem(props: LibraryNavItemProps) {
  const { active, onActive, onClick, label, testid } = props;

  const buttonRef = useRef<HTMLButtonElement>(null);
  const activeRef = useRef<boolean | null>(null);

  const className = classNames("teacher-nav-item", {
    "teacher-nav-item--active": active,
  });

  useEffect(() => {
    if (active && buttonRef.current && !activeRef.current) {
      buttonRef.current.getBoundingClientRect();
      const {
        offsetLeft,
        offsetTop,
        offsetWidth,
        offsetHeight,
      } = buttonRef.current;
      onActive({
        left: offsetLeft,
        top: offsetTop,
        width: offsetWidth,
        height: offsetHeight,
      });
    }
    activeRef.current = !!active;
  }, [active, onActive]);

  return (
    <button
      className={className}
      ref={buttonRef}
      data-testid={testid}
      onClick={onClick}>
      {label}
    </button>
  );
}
