import React, { Component } from "react";
import { connect } from "react-redux";
import { lmsActions } from "../../../../../core/lms";
import Modal from "../../../modal";

class RemoveAdminsConfirmation extends Component {
  render() {
    const { revokeCredentials, setRevokeConfirmationVisibility } = this.props;

    return (
      <div>
        <Modal
          title="Revoke LMS Integration?"
          bodyRenderer={() => (
            <span>
              Users will no longer be able to launch Book Creator from your LMS
            </span>
          )}
          modal
          clickBackgroundToClose
          onConfirm={e => {
            revokeCredentials();
            setRevokeConfirmationVisibility(false);
          }}
          confirmText="Revoke"
          confirmButtonType="negative"
          onCancel={e => setRevokeConfirmationVisibility(false)}
          cancelText="Cancel"
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  ...lmsActions,
};

export default connect(null, mapDispatchToProps)(RemoveAdminsConfirmation);
