import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { getConfirmations, lmsActions } from "../../../../core/lms";
import RevokeCredentialsConfirmation from "./revoke-credentials";

class LMSConfirmations extends Component {
  render() {
    const { confirmations } = this.props;

    const { revoke } = confirmations;
    return <div>{revoke ? <RevokeCredentialsConfirmation /> : null}</div>;
  }
}

const mapStateToProps = createSelector(getConfirmations, confirmations => ({
  confirmations,
}));

const mapDispatchToProps = {
  ...lmsActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(LMSConfirmations);
