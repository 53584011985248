import { useEffect, useState } from "react";
import { LIBRARIES_DOMAIN } from "../../../../config";
import { addErrorMessage } from "src/core/notifications/actions";
import { getOrganisationId } from "src/core/organisation";
import { loadTeachers } from "src/core/teachers/actions";
import * as OrgsAPI from "src/core/organisation/orgs-api";
import { getUserId } from "src/core/auth";
import { useDispatch, useSelector } from "react-redux";
import useFetch, { CachePolicies } from "use-http";
import { LibProps, LibraryAssignActions, List } from "../library-menu/types";

interface ApiHookResponse {
  data: List<LibProps>;
  isLoading: boolean;
}

export const useLibraryApi = (
  teacherId: string,
  path: string = "owned"
): ApiHookResponse => {
  const [data, setData] = useState<List<LibProps>>(List());
  const [isLoading, setIsLoading] = useState(true);

  const { get, response } = useFetch(
    `${LIBRARIES_DOMAIN}/${teacherId}/${path}`,
    {
      credentials: "include",
      cachePolicy: CachePolicies.NO_CACHE,
    }
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const ownLibs = await get();
        if (response.ok) {
          setData(List(ownLibs.unarchived || ownLibs));
        } else {
          const errorMsg =
            response.status === 401
              ? "Not authorised to access the requested user"
              : "Error fetching teacher libraries";
          dispatch(addErrorMessage(errorMsg));
        }
      } catch (error) {
        dispatch(addErrorMessage("Error fetching teacher libraries"));
      }
      setIsLoading(false);
    };
    getData();
  }, [dispatch, get, teacherId, response]);

  return { data, isLoading };
};

export const useReassignLibraryApi = (
  teacherId: string,
  newOwnerUserId: string,
  targetUserRole: LibraryAssignActions,
  reassign: boolean = false
) => {
  const [success, setSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { put, response } = useFetch(
    `${LIBRARIES_DOMAIN}/${teacherId}/admins/owner/all/${targetUserRole}/${newOwnerUserId}`,
    {
      credentials: "include",
      cachePolicy: CachePolicies.NO_CACHE,
    }
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        await put();
        if (response.ok) {
          setSuccess(true);
        } else {
          const errorMsg =
            response.status === 401
              ? "Not authorised to access the user"
              : "Error reassigning teacher libraries";
          dispatch(addErrorMessage(errorMsg));
        }
      } catch (error) {
        dispatch(addErrorMessage("Error reassigning teacher libraries"));
      }
      setIsLoading(false);
    };
    if (reassign) {
      getData();
    }
  }, [dispatch, put, teacherId, response, reassign]);

  return { success, isLoading };
};

export const useArchiveLibraryApi = (
  teacherId: string,
  archive: boolean = false
) => {
  const [success, setSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { post, response } = useFetch(
    `${LIBRARIES_DOMAIN}/${teacherId}/admins/archive/all`,
    {
      credentials: "include",
      cachePolicy: CachePolicies.NO_CACHE,
    }
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        setSuccess(false);
        await post();
        if (response.ok) {
          setSuccess(true);
        } else {
          const errorMsg =
            response.status === 401
              ? "Not authorised to access the requested user"
              : "Error archiving teacher libraries";
          dispatch(addErrorMessage(errorMsg));
        }
      } catch (error) {
        dispatch(addErrorMessage("Error archiving teacher libraries"));
      }
      setIsLoading(false);
    };
    if (archive) {
      getData();
    }
  }, [dispatch, post, teacherId, response, archive]);

  return { success, isLoading };
};

export const useRevokeMembershipApi = (
  teacherId: string,
  revoke: boolean = false
) => {
  const [success, setSuccess] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const orgId: string = useSelector(getOrganisationId);
  const authUserId: string = useSelector(getUserId);

  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        setSuccess(false);
        setFailed(false);
        const response = await OrgsAPI.revokeMembership(
          orgId,
          [teacherId],
          authUserId
        );
        if (response.ok) {
          setSuccess(true);
          //refresh the teachers list
          dispatch(loadTeachers());
        } else {
          const errorMsg =
            response.message || "Error revoking teacher memberships";
          dispatch(addErrorMessage(errorMsg));
          setFailed(true);
        }
      } catch (error) {
        setFailed(true);
        dispatch(addErrorMessage("Error revoking teacher memberships"));
      }
      setIsLoading(false);
    };
    if (revoke) {
      getData();
    }
  }, [dispatch, teacherId, authUserId, orgId, revoke]);

  return { success, failed, isLoading };
};
