import React, { Component } from "react";
import Modal from "../../../../modal";
import Button from "../../../../button";
import EmailList from "./email-list";

export default class ConfirmEmail extends Component {
  get isValid() {
    return true;
  }

  handleSubmit = () => {
    if (this.isValid) {
      this.props.onSubmit();
    }
  };

  render() {
    const {
      onClose,
      emailingTeachers,
      teachersToEmailAddresses,
      onBackButtonClick,
    } = this.props;
    const teacherCount = teachersToEmailAddresses.length;

    return (
      <Modal
        title="Confirm recipients and send"
        modal
        width={527}
        notransition
        align="center"
        showX
        hideFooter
        onCancel={onClose}
        showBackButton
        onBackButtonClick={onBackButtonClick}
        loadingMessage={emailingTeachers ? "Sending" : null}
        cancelText="Cancel">
        <p className="modal__intro">
          You have selected {teacherCount}{" "}
          {teacherCount === 1 ? "teacher" : "teachers"} to receive the welcome
          email. <br />
          Please confirm the recipients before sending.
        </p>
        <EmailList maxHeight={300} emails={teachersToEmailAddresses} />
        <Button minWidth={158} type="submit" onClick={this.handleSubmit}>
          Confirm and send
        </Button>
      </Modal>
    );
  }
}
