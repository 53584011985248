export function getAnalyticsState(state) {
  return state.analytics;
}

export function getAnalyticsResourceAuthExpiry(state) {
  return getAnalyticsState(state).resourceAuthExpires;
}

export function getAnalyticsSignedURLs(state) {
  return getAnalyticsState(state).signedURLs;
}

export function getUserAnalyticsSignedURLs(state) {
  return getAnalyticsState(state).userSignedURLs;
}

export function getIsLoadingResource(state) {
  return getAnalyticsState(state).isLoading;
}
