import React from "react";
import classNames from "classnames";
import Avatar from "../../../../avatar";
import BookshelfIcon from "src/images/svg/book-shelf.svg";
import SvgIcon from "../../../../ui/svg-icon";
import LibraryProperty from "../property";
import "./index.scss";
import { LibProps } from "../../types";

interface LibraryNameProps {
  isOwner: boolean;
  className?: string;
  getAvatarUrl?: (userId: string) => {};
  children?: any;
}

function LibraryName({
  isOwner,
  owner,
  name,
  className,
  getAvatarUrl,
  children,
}: LibraryNameProps & Partial<LibProps>) {
  const cssClass = classNames("library-menu-item", {
    [`${className}`]: className,
  });
  return (
    <div className={cssClass}>
      <div className="library-menu-item__icon">
        {isOwner || !owner ? (
          <SvgIcon icon={BookshelfIcon} />
        ) : (
          <Avatar name={owner.displayName} url={getAvatarUrl(owner.userId)} size={40} />
        )}
      </div>
      {children ?? <LibraryProperty name="name">{name}</LibraryProperty>}
    </div>
  );
}

export default LibraryName;
