import React, { useState, useEffect, useRef, CSSProperties } from "react";
import { bem } from "src/utils/bem";
import SvgIcon from "../ui/svg-icon";
import SearchIcon from "src/images/svg/search.svg";
import CrossIcon from "src/images/svg/cross--circle.svg";
import "./search-box.scss";

interface IProps {
  onChange?(value: string): void;
  autoFocus?: boolean;
  value?: string;
  placeholder?: string;
  style?: CSSProperties;
}

function SearchBox({ autoFocus, onChange, value, placeholder, style }: IProps) {
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<any>();
  const isFilled = value && value.length;

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  const klass = bem("search-box");

  const containerClass = klass({
    focused: isFocused,
    filled: isFilled,
  });

  const inputClass = klass.element("input");

  return (
    <div className={containerClass} style={style}>
      <SvgIcon icon={SearchIcon} name="search" />
      <input
        className={inputClass}
        type="text"
        ref={inputRef}
        value={value}
        placeholder={placeholder}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onChange={e => onChange(e.target.value)}
      />
      <SvgIcon icon={CrossIcon} name="clear" onClick={() => onChange("")} />
    </div>
  );
}

export default SearchBox;
