import React from "react";
import PropTypes from "prop-types";
import "./index.scss";

const ChartEmptyState = props => {
  const { title } = props;

  return (
    <div className="chart-empty">
      {title ? <span className="chart-empty__title">{title}</span> : null}
      <span className="chart-empty__message">
        There is no data available for this view
      </span>
    </div>
  );
};

ChartEmptyState.propTypes = {
  title: PropTypes.string,
};

export default ChartEmptyState;
