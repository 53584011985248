import React, { useState, useEffect, useCallback, useRef } from "react";
import classNames from "classnames";
import MiniMenu from "../mini-menu";
import SvgIcon from "../../ui/svg-icon";
import ChevronIcon from "../../../../images/svg/chevron.svg";
import "./index.scss";

const DropdownMenu = ({
  width: propWidth,
  value,
  valueRenderer,
  placeholder,
  maxWidth,
  onToggleClick,
  onClose,
  children,
}) => {
  const [state, setState] = useState({
    open: false,
    timeout: null,
    width: propWidth,
  });

  const toggleTextRef = useRef();

  const setWidth = useCallback(() => {
    if (toggleTextRef.current) {
      const textWidth = toggleTextRef.current.scrollWidth;
      const paddingWidth = 38; // Account for padding and icon
      const minWidth = propWidth;
      const calculatedWidth = Math.max(textWidth + paddingWidth, minWidth);

      setState(prev => ({
        ...prev,
        width: propWidth || calculatedWidth,
      }));
    }
  }, [propWidth]);

  useEffect(() => {
    setWidth();
    // Add resize listener to handle window size changes
    window.addEventListener("resize", setWidth);
    return () => window.removeEventListener("resize", setWidth);
  }, [value, setWidth]);

  const handleToggleClick = e => {
    setState(prev => ({
      ...prev,
      open: !prev.open,
    }));

    if (onToggleClick) {
      onToggleClick(e);
    }
    e.stopPropagation();
    e.preventDefault();
  };

  const handleMouseLeave = () => {
    if (state.open && !state.timeout) {
      setState(prev => ({
        ...prev,
        timeout: window.setTimeout(closeMenu, 2000),
      }));
    }
  };

  const handleMouseEnter = () => {
    window.clearTimeout(state.timeout);
    setState(prev => ({
      ...prev,
      timeout: null,
    }));
  };

  const closeMenu = () => {
    if (onClose) {
      onClose();
    }
    setState(prev => ({
      ...prev,
      open: false,
    }));
  };

  const { open, width } = state;

  const containerClass = classNames("dropdown-menu", {
    "dropdown-menu--active": open,
  });

  const containerStyle = {
    width: state.width,
    maxWidth: maxWidth || "none",
  };

  return (
    <div
      className={containerClass}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={containerStyle}>
      <button onClick={handleToggleClick} className="dropdown-menu__toggle">
        <span>
          <span className="dropdown-menu__toggle-text" ref={toggleTextRef}>
            {value && valueRenderer ? valueRenderer() : value || placeholder}
          </span>
          <SvgIcon icon={ChevronIcon} iconName="chevron" />
        </span>
      </button>
      {open && children && (
        <MiniMenu
          position={{ top: 0, left: 0 }}
          dropdown
          width={maxWidth && width > maxWidth ? maxWidth : width}
          onClose={closeMenu}
          onItemClick={closeMenu}>
          {children}
        </MiniMenu>
      )}
    </div>
  );
};

export default DropdownMenu;
