import { addErrorMessage } from "../notifications/actions";
import { getOrganisationId } from "../organisation";
import { isBCEmail } from "../../utils/validators";
import { getUserId } from "../auth";

import {
  SET_ADMINS_LOADED,
  RECEIVE_ADMINS_DATA,
  SET_SHOW_ADMIN_IMPORT,
  SET_IMPORTING_ADMINS,
  SET_SORT_BY,
  TOGGLE_ADMIN_SELECTION,
  SELECT_ADMINS,
  DESELECT_ADMINS,
  DESELECT_ALL_ADMINS,
  RESET_ADMINS_STORE,
  SET_ADMINS_TO_REMOVE,
  SET_REMOVING_ADMINS,
  REMOVE_ADMINS,
} from "./action-types";
import * as OrgsAPI from "../organisation/orgs-api";

export function loadAdmins() {
  return async (dispatch, getState) => {
    const orgId = getOrganisationId(getState());
    const userId = getUserId(getState());

    const response = await OrgsAPI.getOrganisationAdmins(orgId, userId);
    if (response.ok) {
      dispatch({ type: RECEIVE_ADMINS_DATA, payload: response.data.admins });
    } else {
      dispatch(addErrorMessage("There was a problem loading admins"));
    }
    dispatch({ type: SET_ADMINS_LOADED, payload: true });
  };
}

export function importAdmins(payload) {
  return async (dispatch, getState) => {
    const { emails } = payload;
    const hasBCEmail = emails.some(e => isBCEmail(e));
    if (hasBCEmail) {
      return dispatch(
        addErrorMessage("Book Creator users can't be added as admins")
      );
    }
    const orgId = getOrganisationId(getState());
    const userId = getUserId(getState());

    dispatch({ type: SET_IMPORTING_ADMINS, payload: true });

    const response = await OrgsAPI.importAdmins(orgId, emails, userId);
    dispatch({ type: SET_IMPORTING_ADMINS, payload: false });
    if (response.ok) {
      dispatch({
        type: SET_SHOW_ADMIN_IMPORT,
        payload: false,
      });
      dispatch(loadAdmins());
    } else {
      dispatch(
        addErrorMessage(
          response.message ||
            "Something went wrong, the admins couldn't be imported"
        )
      );
    }
  };
}

export function removeAdmins(payload) {
  return async (dispatch, getState) => {
    const { admins } = payload;
    const orgId = getOrganisationId(getState());
    const userId = getUserId(getState());

    dispatch({ type: SET_REMOVING_ADMINS, payload: true });

    const response = await OrgsAPI.removeAdmins(orgId, admins, userId);
    dispatch({ type: SET_REMOVING_ADMINS, payload: false });

    if (response.ok) {
      dispatch({ type: REMOVE_ADMINS, payload: admins });
    } else {
      dispatch(
        addErrorMessage(
          response.message ||
            "Something went wrong, the admins couldn't be removed"
        )
      );
    }
    dispatch(deselectAllAdmins());
    dispatch(setAdminsToRemove(null));
    dispatch(loadAdmins());
  };
}

export function setShowAdminImport(payload) {
  return {
    type: SET_SHOW_ADMIN_IMPORT,
    payload,
  };
}

export function setSortBy(payload) {
  return {
    type: SET_SORT_BY,
    payload,
  };
}

export function toggleAdminSelection(adminEmails) {
  return {
    type: TOGGLE_ADMIN_SELECTION,
    payload: adminEmails,
  };
}

export function selectAdmins(adminEmails) {
  return {
    type: SELECT_ADMINS,
    payload: adminEmails,
  };
}

export function deselectAdmins(adminEmails) {
  return {
    type: DESELECT_ADMINS,
    payload: adminEmails,
  };
}

export function deselectAllAdmins() {
  return {
    type: DESELECT_ALL_ADMINS,
  };
}

export function setAdminsToRemove(payload) {
  return {
    type: SET_ADMINS_TO_REMOVE,
    payload,
  };
}

export function reset() {
  return {
    type: RESET_ADMINS_STORE,
  };
}
