import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classNames from "classnames";
import SvgIcon from "../ui/svg-icon";
import "./index.scss";

function PageControl(props) {
  const {
    active,
    activeStyle,
    alignMenuToPage,
    border,
    children,
    color,
    css,
    customChild,
    disabled,
    elevate,
    fauxdisabled,
    hardLink,
    highlight,
    icon,
    iconName,
    id,
    img,
    initials,
    isFlatIconButton,
    link,
    noPropagation,
    onClick,
    onKeyDown,
    onMouseDown,
    primary,
    style,
    text,
    title,
    wrapperCss,
  } = props;

  const handleClick = evt => {
    if (onClick && !disabled && !fauxdisabled) {
      onClick(evt);
    }
    if (noPropagation) {
      evt.stopPropagation();
    }
  };

  const handleMouseDown = evt => {
    if (onMouseDown && !disabled && !fauxdisabled) {
      onMouseDown(evt);
    }
    if (noPropagation) {
      evt.stopPropagation();
    }
  };

  const menu = children;

  const cssClass = classNames("page-control", {
    [`${css}`]: css,
    "page-control--border": border,
    "page-control--icon": icon,
    "page-control--icon-only": icon && !text,
    "page-control--disabled": disabled,
    "page-control--fauxdisabled": fauxdisabled,
    "page-control--elevate": elevate,
    "page-control--text": text,
    "page-control--primary": primary,
    "page-control--active": active,
    "page-control--highlight": highlight,
    "page-control--has-menu": menu,
    "page-control--flat-icon": isFlatIconButton,
  });

  const wrapperClass = classNames("page-control-wrapper", {
    "page-control-wrapper--passive": alignMenuToPage,
    [`${wrapperCss}`]: wrapperCss,
  });

  let styleProp = {
    ...style,
  };

  if (active && activeStyle) {
    styleProp = {
      ...style,
      ...activeStyle,
    };
  }

  const iconItem = icon ? <SvgIcon style={{ fill: color }} icon={icon} name={iconName} ariaHidden /> : null;
  const textItem = text ? <span className="page-control__label">{text}</span> : null;
  const initialsItem = initials ? <span className="page-control__initials">{initials}</span> : null;
  const imgItem = img ? <img src={img} className="page-control__img" alt="" /> : null;

  const sharedProps = {
    "aria-label": title,
    className: cssClass,
    disabled,
    id,
    onKeyDown: !menu && onKeyDown ? onKeyDown : null,
    tabIndex: disabled || fauxdisabled ? -1 : null,
    title,
    style: styleProp,
  };

  const contents = (
    <Fragment>
      {iconItem}
      {textItem}
      {initialsItem}
      {imgItem}
      {customChild}
    </Fragment>
  );

  const control = link ? (
    hardLink ? (
      <a {...sharedProps} href={link} onClick={handleClick} onMouseDown={onMouseDown}>
        {contents}
      </a>
    ) : (
      <Link {...sharedProps} to={link} onClick={handleClick} onMouseDown={onMouseDown}>
        {contents}
      </Link>
    )
  ) : (
    <button
      {...sharedProps}
      onClick={evt => {
        evt.preventDefault();
        handleClick(evt);
      }}
      onMouseDown={evt => {
        evt.preventDefault();
        handleMouseDown(evt);
      }}>
      {contents}
    </button>
  );

  if (menu) {
    return (
      <div className={wrapperClass} onKeyDown={onKeyDown}>
        {control}
        {active ? menu : null}
      </div>
    );
  } else {
    return control;
  }
}

PageControl.propTypes = {
  active: PropTypes.bool,
  activeStyle: PropTypes.object,
  alignMenuToPage: PropTypes.bool,
  border: PropTypes.bool,
  color: PropTypes.string,
  customChild: PropTypes.object,
  css: PropTypes.string,
  disabled: PropTypes.bool,
  elevate: PropTypes.bool,
  fauxdisabled: PropTypes.bool,
  hardLink: PropTypes.bool,
  highlight: PropTypes.bool,
  icon: PropTypes.object,
  iconName: PropTypes.string,
  id: PropTypes.string,
  initials: PropTypes.string,
  isFlatIconButton: PropTypes.bool,
  link: PropTypes.string,
  noPropagation: PropTypes.bool,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  onMouseDown: PropTypes.func,
  style: PropTypes.object,
  styleActive: PropTypes.object,
  text: PropTypes.string,
  title: PropTypes.string,
  wrapperCss: PropTypes.string,
};

export default PageControl;
