import React, { useEffect, useRef } from "react";
import { List } from "immutable";
import classNames from "classnames";
import SvgIcon from "src/views/components/ui/svg-icon";
import InlineError from "src/views/components/forms/inline-error";
import TickIcon from "src/images/svg/tick.svg";
import WarningCircleIcon from "src/images/svg/warning--circle.svg";
import CheckboxIcon from "src/images/svg/checkbox.svg";
import CheckboxCheckedIcon from "src/images/svg/checkbox--checked.svg";
import { IRecord } from "../../types";
import "./index.scss";

interface IProps {
  focus: boolean;
  input: IRecord;
  assignLicenses: boolean;
  hasRemainingLicenses: boolean;
  onChange(record: IRecord): void;
  onDelete(): void;
  validator(value: string): boolean;
  invalidMessage: string;
}

interface IHeaderProps {
  inputs: List<IRecord>;
  onCheckAll(): void;
  onUncheckAll(): void;
}

export default function ImportConfirmationInput(props: IProps) {
  const {
    focus,
    input,
    assignLicenses,
    hasRemainingLicenses,
    onChange,
    onDelete,
    validator,
    invalidMessage,
  } = props;

  const { email, license } = input;
  const inputRef = useRef<HTMLInputElement>();
  const focusedRef = useRef<boolean>();

  useEffect(() => {
    if (focus && inputRef.current && !focusedRef.current) {
      inputRef.current.focus();
      const length = email.length;
      inputRef.current.setSelectionRange(length, length);
      focusedRef.current = true;
    }
  }, [focus, email.length]);

  const isValid = validator(email);
  const cssClass = classNames("input-validator", {
    "input-validator--valid": isValid && !assignLicenses,
    "input-validator--invalid": !isValid,
  });

  const handleEmailChange = (email: string) => {
    onChange({ ...input, email });
  };

  const assignLicense = () => {
    if (!hasRemainingLicenses) {
      return;
    }

    onChange({ ...input, license: true });
  };

  const removeLicense = () => {
    onChange({ ...input, license: false });
  };

  return (
    <div className={cssClass}>
      <div className="input-validator__entry">
        <input
          value={email || ""}
          type="text"
          ref={inputRef}
          className="input-validator__input fs-mask"
          onChange={e => handleEmailChange(e.target.value)}
          onBlur={e => {
            if (!e.target.value.length) {
              onDelete();
            }
          }}
        />
        {!isValid ? (
          <InlineError>{invalidMessage.replace("%0 ", "")}</InlineError>
        ) : null}
      </div>
      <div className="input-validator__status">
        {(() => {
          if (!isValid) {
            return <SvgIcon icon={WarningCircleIcon} name="warning" />;
          }

          if (assignLicenses) {
            if (license) {
              return (
                <SvgIcon
                  icon={CheckboxCheckedIcon}
                  name="checkbox"
                  onClick={removeLicense}
                />
              );
            } else {
              return (
                <SvgIcon
                  icon={CheckboxIcon}
                  name="checkbox"
                  cssClass={hasRemainingLicenses ? undefined : "is-disabled"}
                  onClick={assignLicense}
                />
              );
            }
          }

          return <SvgIcon icon={TickIcon} name="tick" />;
        })()}
      </div>
    </div>
  );
}

export function ImportConfirmationHeader({
  inputs,
  onCheckAll,
  onUncheckAll,
}: IHeaderProps) {
  const allChecked = inputs.every(record => record.license);

  return (
    <div className="input-validator">
      <div className="input-validator__entry">Select all</div>
      <div className="input-validator__status">
        {allChecked ? (
          <SvgIcon
            icon={CheckboxCheckedIcon}
            name="checkbox"
            onClick={onUncheckAll}
          />
        ) : (
          <SvgIcon icon={CheckboxIcon} name="checkbox" onClick={onCheckAll} />
        )}
      </div>
    </div>
  );
}
