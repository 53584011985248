import React, { useEffect, useState } from "react";
import classNames from "classnames";
import Item from "./item";
import "./index.scss";

function MultipleDowngrade(props) {
  const { onComplete, onDowngradeSuccess, ready, selectedTeachers } = props;

  const [initialialised, setInitialised] = useState(false);
  const [succeeded, setSucceeded] = useState([]);
  const [failed, setFailed] = useState([]);
  const [usersToDowngrade, setUsersToDowngrade] = useState([]);

  useEffect(() => {
    if (!initialialised) {
      setUsersToDowngrade([...selectedTeachers]);
      setInitialised(true);
    }
  }, [initialialised, selectedTeachers]);

  const completedCount = succeeded.length + failed.length;
  const allCompleted = completedCount === usersToDowngrade.length;

  const handleSuccess = id => {
    setSucceeded([...succeeded, id]);
    onDowngradeSuccess(id);
  };

  const handleFail = id => setFailed([...failed, id]);

  useEffect(() => {
    if (allCompleted && usersToDowngrade.length) {
      onComplete();
    }
  }, [allCompleted, usersToDowngrade, onComplete]);

  const className = classNames("multiple-downgrade", {
    "multiple-downgrade--running": ready && !allCompleted,
  });

  return (
    <div className={className}>
      {usersToDowngrade.map((user, i) => {
        return (
          <Item
            key={user.id}
            ready={ready && i === completedCount}
            onSuccess={handleSuccess}
            onFail={handleFail}
            succeeded={succeeded.includes(user.id)}
            failed={failed.includes(user.id)}
            {...user}
          />
        );
      })}
    </div>
  );
}

export default MultipleDowngrade;
