import { createSelector } from "reselect";

export function getLMS(state) {
  return state.lms;
}

export function getLMSLoaded(state) {
  return getLMS(state).loaded;
}

export function getLMSGenerating(state) {
  return getLMS(state).generating;
}

export const getLMSConfiguration = createSelector([getLMS], lms => lms.configuration.toJS());

export const getConfirmations = createSelector([getLMS], lms => lms.confirmations.toJS?.() ?? lms.confirmations);
