import HomeIcon from "../../../images/svg/home.svg";
import TeacherIcon from "../../../images/svg/teacher.svg";
import SettingsIcon from "../../../images/svg/settings.svg";
import DiscoverIcon from "../../../images/svg/discover.svg";
import AnalyticsIcon from "../../../images/svg/analytics.svg";
import SignOutIcon from "../../../images/svg/sign-out.svg";
import PortfoliosIcon from "../../../images/svg/portfolios.svg";

const getRoutes = ({ signOut, organisationId, hasPortfoliosEnabled }) => {
  let routes = [];

  const analyticsRoute = {
    name: "Analytics",
    icon: AnalyticsIcon,
    iconName: "analytics",
    route: `/${organisationId}/analytics`,
  };

  routes = [
    {
      name: "Home",
      icon: HomeIcon,
      iconName: "home",
      route: `/${organisationId}`,
    },
    {
      name: "Teachers",
      icon: TeacherIcon,
      iconName: "teacher",
      route: `/${organisationId}/teachers`,
    },
    analyticsRoute,
    {
      name: "Resources",
      icon: DiscoverIcon,
      iconName: "discover",
      childRoutes: [
        {
          name: "For Admins",
          href: "https://intercom.help/bookcreator/for-administrators",
        },
        {
          name: "For Teachers",
          route: `/${organisationId}/resources`,
        },
      ],
    },
    {
      name: "Settings",
      icon: SettingsIcon,
      iconName: "settings",
      childRoutes: [
        {
          name: "Admins",
          route: `/${organisationId}/admin`,
        },
        {
          name: "LMS Integration",
          route: `/${organisationId}/lms`,
        },
        {
          name: "Manage Apps",
          route: `/${organisationId}/apps`,
        },
      ],
    },
  ];

  if (hasPortfoliosEnabled) {
    routes.splice(2, 0, {
      name: "Portfolios",
      icon: PortfoliosIcon,
      iconName: "portfolios",
      route: `/${organisationId}/portfolios`,
    });
  }

  routes.push({
    name: "Sign out",
    icon: SignOutIcon,
    iconName: "sign-out",
    onClick: signOut,
  });

  return routes;
};

export default getRoutes;
