import React from "react";
import "./index.scss";
import Avatar from "../../../../avatar";
import { getAvatarUrl } from "../../../library-menu/utils";
import SvgIcon from "../../../../ui/svg-icon";
import BookshelfIcon from "src/images/svg/book-shelf.svg";
import { LibProps, List } from "../../../library-menu/types";
import { Tooltip } from "react-tooltip";

interface UserInfoProps {
  teacher: { id: string; displayName: string; email: string };
  onClick?: () => void;
  libraries?: List<LibProps>;
}

export default function UserInfo({ libraries, onClick, teacher }: UserInfoProps) {
  const { id, displayName, email } = teacher;
  return (
    <div className="user-info">
      <div className="user-info__avatar">
        <Avatar name={displayName} url={getAvatarUrl(id)} size={36} />
      </div>
      <div className="user-info__details" onClick={onClick}>
        <h2 className="user-info__details-name">{displayName}</h2>
        <a className="user-info__details-email" href={`mailto:${email}`}>
          {email}
        </a>
      </div>
      {libraries && (
        <div
          className="libraries-badge"
          onClick={onClick}
          data-tooltip-place="top"
          data-tooltip-id={"tooltip-libcount"}>
          <SvgIcon icon={BookshelfIcon} />
          <span className="libraries-badge__label">{libraries.size} libraries</span>
          <Tooltip id="tooltip-libcount">Library count excludes private 'My Books' library</Tooltip>
        </div>
      )}
    </div>
  );
}
