import { FIREBASE_CONFIG } from "../../config";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  sendEmailVerification,
  sendPasswordResetEmail,
} from "firebase/auth";
import { getDatabase, onValue, ref } from "firebase/database";
import { getStorage } from "firebase/storage";


let firebaseApp, firebaseAuth, firebaseStorage, firebaseDb;

const initialiseFirebase = () => {
  firebaseApp = initializeApp(FIREBASE_CONFIG);
  firebaseAuth = getAuth(firebaseApp);
  firebaseDb = getDatabase(firebaseApp);
  firebaseStorage = getStorage(firebaseApp);
};
const onValueOnce = (db, path) => {
  return new Promise((resolve, reject) => {
    onValue(
      ref(db, path),
      snapshot => {
        resolve(snapshot.val());
      },
      err => {
        reject(err);
      },
      {
        onlyOnce: true,
      }
    );
  });
};

export {
  initialiseFirebase,
  firebaseApp,
  firebaseAuth,
  firebaseDb,
  firebaseStorage,
  onValueOnce,
  sendEmailVerification,
  sendPasswordResetEmail,
};
