import { firebaseAuth, initialiseFirebase } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";

import * as authActions from "./actions";

export { authActions };
export * from "./action-types";
export { authReducer } from "./reducer";

export {
  getAuth,
  getAuthToken,
  getDisplayName,
  getEmail,
  getIsBookCreatorUser,
  getUserId,
  isAuthenticated,
  getEmailVerified,
  getEmailUnverified,
} from "./selectors";

export async function initAuth(dispatch) {
  initialiseFirebase();
  return new Promise((resolve, reject) => {
    const unsub = onAuthStateChanged(
      firebaseAuth,
      user => {
        dispatch(authActions.initAuth(user));
        unsub();
        resolve();
      },
      error => reject(error)
    );
  });
}
