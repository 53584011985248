import React from "react";
import PropTypes from "prop-types";
import Cover from "./cover";
import "./index.scss";

const books = [
  {
    uid: "aWAhdfUWXPQR1UPW7fJOHnfObsb2",
    bookId: "_or2hLPmR3WlS34sPH_WKQ",
    shape: "landscape",
    title: "50 ways to use Book Creator in your classroom",
  },
  {
    uid: "FoPni3wEzCWiegwaPGH2sIkT6kr2",
    bookId: "_pZ6pdV2Tou9xhy8-QB6Zg",
    shape: "landscape",
    title: "Using Book Creator in the elementary classroom",
  },
  {
    uid: "aWAhdfUWXPQR1UPW7fJOHnfObsb2",
    bookId: "xtm3KUrZT4m__P9MwNezjA",
    shape: "landscape",
    title: "Using Book Creator to develop thinking routines",
  },
  {
    uid: "aWAhdfUWXPQR1UPW7fJOHnfObsb2",
    bookId: "k4SA4B5_SJ-L93mTB4U76w",
    shape: "square",
    title: "Supporting Language Learners with Book Creator",
  },
  {
    uid: "FoPni3wEzCWiegwaPGH2sIkT6kr2",
    bookId: "1sliYo4BSP-Nghil8S8H5g",
    shape: "portrait",
    title: "Book Creator for the high school classroom",
  },
  {
    uid: "FoPni3wEzCWiegwaPGH2sIkT6kr2",
    bookId: "BLYyvehmTnunA91hurtYzg",
    shape: "portrait",
    title: "15 ways to use Book Creator for reading responses",
  },
  {
    uid: "FoPni3wEzCWiegwaPGH2sIkT6kr2",
    bookId: "GD9ER9doTAmhUpAf8Mxz1A",
    shape: "portrait",
    title: "15 science projects for Book Creator classrooms",
  },
  {
    uid: "FoPni3wEzCWiegwaPGH2sIkT6kr2",
    bookId: "7R1zzJmfTXOMqle8HYCeaQ",
    shape: "portrait",
    title: "Using Book Creator for formative assessment",
  },
  {
    uid: "aWAhdfUWXPQR1UPW7fJOHnfObsb2",
    bookId: "fIoGbwKuTAmSA6Dp4vWxng",
    shape: "portrait",
    title: "Social studies projects for Book Creator classrooms",
  },
];

const Discover = props => {
  const { strings } = props;

  return (
    <div className="discover">
      <h2 className="discover__title">{strings.title}</h2>
      <p className="discover__intro">{strings.intro}</p>
      <div className="discover__books">
        {books.map(book => (
          <Cover {...book} libraryId="-LCESe5qTaw-qLZCBqm3" key={book.bookId} />
        ))}
      </div>
    </div>
  );
};

Discover.propTypes = {
  strings: PropTypes.shape({
    title: PropTypes.string.isRequired,
    intro: PropTypes.string.isRequired,
  }).isRequired,
};

export default Discover;
