import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import superagent from "superagent";
import { BOOKCREATOR_API_URL } from "../../../config";
import {
  getEmailError as emailErrorValidator,
  getPasswordError,
} from "../../../utils/validators";
import Form from "../../components/forms/form";
import FormButton from "../../components/forms/form-button";
import TextBox from "../../components/forms/text-box";
import Message from "../ui/message";
import SignInButton from "./sign-in-button";

function SignInWithEmail(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const { email: emailParam } = useParams();
  const [email, setEmail] = useState(emailParam ? emailParam : "");
  const [password, setPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [alreadyRegistered, setAlreadyRegistered] = useState(null);
  const [alreadyRegisteredWithGoogle, setAlreadyRegisteredWithGoogle] =
    useState(null);
  const [alreadyRegisteredWithOffice, setAlreadyRegisteredWithOffice] =
    useState(null);

  const { auth, clearAuthError, signInWithEmailAndPassword, signInWithGoogle } =
    props;

  useEffect(() => {
    if (auth.error) {
      setSubmitting(false);
    }
  }, [auth.error]);

  const updateEmail = nextEmail => {
    clearAuthError();
    setEmail(nextEmail);
  };

  const updatePassword = nextPassword => {
    clearAuthError();
    setPassword(nextPassword);
  };

  const checkIfUserExists = () => {
    return new Promise((resolve, reject) => {
      getProviders(email)
        .catch(error => {
          resolve(false);
        })
        .then(data => {
          resolve(data);
        });
    });
  };

  const getProviders = email => {
    return new Promise((resolve, reject) => {
      superagent
        .get(`${BOOKCREATOR_API_URL}/users/v2/providers/${email}`)
        .withCredentials()
        .end((err, res) => {
          if (err) {
            reject();
          } else {
            if (res && res.body && res.body.length) {
              resolve(res.body);
            } else {
              reject();
            }
          }
        });
    });
  };

  const getEmailError = email => {
    // district specific exception
    if (email.trim().endsWith(".nyc.gov")) {
      return "Your district prevents the use of email and password logins to Book Creator Admin Console, please use the preferred Single Sign On methods";
    }
    if (
      email.trim().endsWith("@lausd.net") ||
      email.trim().endsWith("@mymail.lausd.net")
    ) {
      return "Please sign in using your district SSO such as Clever or Schoology";
    }
    return emailErrorValidator(email);
  };

  const handleSubmit = e => {
    setSubmitted(true);

    e.preventDefault();
    if (!isValid()) {
      return;
    }
    // reset state to allow to navigate back and forth
    if (location.state?.loginRedirect) {
      location.state.loginRedirect = false;
    }
    if (alreadyRegistered) {
      setSubmitting(true);

      signInWithEmailAndPassword({
        email,
        password,
      });
    } else if (!alreadyRegisteredWithGoogle) {
      checkIfUserExists().then(data => {
        if (data && data.includes("password")) {
          setAlreadyRegistered(true);
          setSubmitted(false);
        } else if (data && data.includes("google.com")) {
          setAlreadyRegisteredWithGoogle(true);
        } else if (data && data.includes("office365")) {
          setAlreadyRegisteredWithOffice(true);
        } else {
          navigate(`/sign-in/register/${email}`);
        }
      });
    }
  };

  const { error } = auth;

  const isValid = () => {
    if (alreadyRegistered) {
      return !error && !getEmailError(email) && !getPasswordError(password);
    } else {
      return !error && !getEmailError(email);
    }
  };

  let emailError = submitted && getEmailError(email);
  let passwordError = submitted && getPasswordError(password);
  const hasError = error?.code || emailError;

  if (error && error.code) {
    switch (error.code) {
      case "auth/wrong-password":
        passwordError = "Incorrect password";
        break;
      case "auth/internal-error":
        emailError = error.message;
        break;
      case "auth/user-not-found":
        emailError = "That email address doesn't match an existing account";
        break;
      case "auth/invalid-email":
        emailError = "Invalid email address";
        break;
      case "auth/user-disabled":
        emailError = "This account has been disabled";
        break;
      case "auth/too-many-requests":
        passwordError =
          "You have entered an incorrect password too many times. Please try again in a few minutes.";
        break;
      default:
        passwordError = "There was a problem signing in, please try again.";
        break;
    }
  }

  const submitText = alreadyRegistered ? "Sign in" : "Next";

  const cancelLink = "/sign-in";
  const forgottenPasswordLink = "/sign-in/forgotten-password";

  return (
    <Form header="Sign in with email" onSubmit={handleSubmit}>
      <Helmet title="Sign in with an email address" />
      <div className="form__content">
        {alreadyRegisteredWithGoogle ? (
          <div>
            <p>
              That email address has already been registered with a Google
              account
            </p>
            <SignInButton
              text="Sign in with Google"
              onClick={signInWithGoogle}
              provider="google"
            />
          </div>
        ) : alreadyRegisteredWithOffice ? (
          <div>
            <p>
              That email address has already been registered with an Office365
              account
            </p>
            <SignInButton
              href="/sign-in/office-365"
              text="Sign in with Office 365"
              provider="office"
            />
          </div>
        ) : (
          <TextBox
            label="Email"
            type="email"
            name="email"
            onChange={updateEmail}
            value={email}
            autoFocus
            error={emailError}
          />
        )}
        {alreadyRegistered ? (
          <TextBox
            label="Password"
            type="password"
            name="password"
            onChange={updatePassword}
            value={password}
            autoFocus
            error={passwordError}
          />
        ) : null}
        {hasError ? (
          <Message type="error" center>
            {emailError || passwordError}
          </Message>
        ) : null}
      </div>
      <div className="form__actions">
        {alreadyRegistered ? (
          <small className="form__actions-smallprint">
            <Link to={forgottenPasswordLink}>Forgotten password?</Link>
          </small>
        ) : null}
        <FormButton flat href={cancelLink} text="Cancel" />
        {!alreadyRegisteredWithGoogle ? (
          <FormButton type="submit" text={submitText} loading={submitting} />
        ) : null}
      </div>
    </Form>
  );
}

export default SignInWithEmail;
