import React from "react";

const TruncatedText = props => {
  return (
    <span
      style={{
        overflow: "hidden",
        whiteSpace: "nowrap",
        display: "block",
        textOverflow: "ellipsis",
      }}
      className={props.className}>
      {props.children}
    </span>
  );
};

export default TruncatedText;
