import React, { useState, useEffect, Fragment } from "react";
import Table from "../../table";
import dayjs from "dayjs";
import LicenseIndicator from "../license-indicator";
import SelectionHeader from "../../selection-header";
import SelectionHeaderLabel from "../../selection-header/label";
import DropdownMenu from "../../ui/dropdown-menu";
import HeaderButton from "../../button/header";
import TruncatedText from "../../ui/truncated-text";
import RowActions from "../row-actions";
import EmptyState from "../../empty-state";
import MenuItems from "../menu-items";
import Certification from "./certification";
import CSVExport from "../../csv-export";
import Name from "./name";
import Student from "./student";
import { sortRows } from "../../table/row-sort";
import LibraryManagement from "../library-management";
import { LibraryMenu } from "../library-menu";
import { getAvatarUrl } from "../library-menu/utils";
import { OrganisationRole } from "../library-menu/const";

const TeacherTable = props => {
  const [state, setState] = useState({
    selectedTeacherLibrary: null,
    showTeacherLibraries: false,
    showManageTeacherLibraries: false,
    tableData: {
      headings: null,
      body: null,
    },
    sortBy: props.sortBy || props.defaultSortBy,
    bulkAction: null,
    menuOpen: null,
    showHeaderLicenses: false,
    tablePageSize: 0,
    currentTablePage: 0,
    tableRows: [],
  });
  const {
    hasDomainLicense,
    isApplyToAllLicense,
    isBookCreatorUser,
    readonly,
    teachers,
    selectedTeachers,
    setLicensesToAssign,
    setLicensesToRemove,
    licenseInfo,
    setEmailTemplate,
    setTeachersToRemove,
    setTeachersToEmail,
    setUsersToDowngrade,
    featureFlags,
    toggleLibraryManager,
    setSortBy,
    hasTeachers,
    adminUser,
    admins,
    toggleTeacherSelection,
    selectTeachers,
    deselectTeachers,
    deselectAllTeachers,
    showInactiveTeachers,
    grantMembership,
    setTeacherToDelete,
  } = props;

  const getTeacherRows = () => {
    return teachers.map(teacher => {
      const teacherJS = teacher.toJS();
      const { id, displayName, email, license, pending, certifying, badges, isAmbassador, isStudent, role, revokedAt } =
        teacherJS;
      let allowedBookCount,
        bookQuota,
        libraryQuota = 0;

      if (license && licenseInfo) {
        const licenseData = licenseInfo.find(item => item.id === license.id);
        if (licenseData) {
          bookQuota = licenseData.bookQuota;
          libraryQuota = licenseData.libraryQuota;
          allowedBookCount = libraryQuota === "UNLIMITED" ? bookQuota : bookQuota * libraryQuota;
        }
      }

      return {
        ...teacherJS,
        selected: selectedTeachers.indexOf(id) > -1,
        warning: isStudent,
        disabled: pending,
        sortData: {
          displayName: displayName || "",
          email,
          pending,
          license: allowedBookCount,
          revokedAt,
          certification: badges ? badges.calevel1 || badges.calevel2 : certifying ? 1 : 0,
        },
        rowId: id,
        cells: [
          <Name
            id={id}
            isAmbassador={isAmbassador}
            isLibraryManager={role === OrganisationRole.MANAGER}
            avatarUrl={getAvatarUrl(id)}
            featureFlags={featureFlags}
            hideOverlayIcon={showInactiveTeachers}
            onAvatarClick={e => handleAvatarClick(teacherJS)}>
            {displayName}
          </Name>,
          isStudent ? (
            <Student>{email}</Student>
          ) : (
            <a href={`mailto:${email}`} className="fs-mask">
              {email}
            </a>
          ),
          ...(showInactiveTeachers
            ? [<TruncatedText>{dayjs(revokedAt).format("Do MMMM YYYY")}</TruncatedText>]
            : [
                <TruncatedText>{pending ? "No" : "Yes"}</TruncatedText>,
                <LicenseIndicator bookQuota={bookQuota} libraryQuota={libraryQuota} />,
              ]),
          <Certification certifying={certifying} badges={badges} />,
          !readonly ? (
            <RowActions
              hasDomainLicense={hasDomainLicense}
              isApplyToAllLicense={isApplyToAllLicense}
              isBookCreatorUser={isBookCreatorUser}
              menuOpen={state.menuOpen}
              teacher={teacherJS}
              featureFlags={featureFlags}
              licenseInfo={licenseInfo}
              role={role}
              showViewProfile={!!featureFlags?.visitLibraries}
              setViewProfile={showLibraryMenu}
              setViewManageLibraries={showManageLibraryMenu}
              setEmailTemplate={setEmailTemplate}
              setLicensesToAssign={setLicensesToAssign}
              setLicensesToRemove={setLicensesToRemove}
              setTeachersToRemove={setTeachersToRemove}
              setTeachersToEmail={setTeachersToEmail}
              setUsersToDowngrade={handleSetUsersToDowngrade}
              toggleLibraryManager={toggleLibraryManager}
              showManageLibraryMenu={true}
              showInactiveTeachers={showInactiveTeachers}
              markAsActive={grantMembership}
              setTeacherToDelete={setTeacherToDelete}
            />
          ) : null,
        ],
      };
    });
  };

  const getTableHeaders = () => {
    return [
      {
        name: "Name",
        sortable: "displayName",
      },
      {
        name: "Email",
        sortable: "email",
      },
      ...(showInactiveTeachers
        ? [
            {
              name: "Removed",
              sortable: "revokedAt",
            },
          ]
        : [
            {
              name: "Signed up",
              sortable: "pending",
              width: 170,
            },
            {
              name: "License",
              sortable: "license",
            },
          ]),
      {
        name: "Certification",
        sortable: "certification",
        tooltip:
          'The Book Creator Certified Author course teaches the basics of using Book Creator in 1 hour. Your teachers can access this PD through their Dashboard. Find out more at <a href="https://app.bookcreator.com/certification" target="_blank">app.bookcreator.com/certification</a>',
      },
      {
        name: "",
        width: 100,
      },
    ];
  };

  useEffect(() => {
    setState(prev => ({
      ...prev,
      tableData: {
        ...prev.tableData,
        headings: getTableHeaders(),
        body: getTeacherRows(),
      },
      sortBy: props.sortBy || props.defaultSortBy,
    }));
    // eslint-disable-next-line
  }, [props]);

  const showManageLibraryMenu = teacher => {
    setState(prev => ({
      ...prev,
      showTeacherLibraries: false,
      showManageTeacherLibraries: true,
      selectedTeacherLibrary: teacher,
    }));
  };

  const handleAvatarClick = teacher => {
    if (!showInactiveTeachers) {
      showLibraryMenu(teacher);
    }
  };

  const showLibraryMenu = teacher => {
    const isFeatureEnabled = featureFlags.visitLibraries;
    if (isFeatureEnabled) {
      setState(prev => ({
        ...prev,
        showTeacherLibraries: true,
        selectedTeacherLibrary: teacher,
      }));
    }
  };

  const handleSetUsersToDowngrade = users => {
    props.setUsersToDowngrade(users);
    handleDeselectAllTeachers();
    handleRowSelect(users);
  };

  const handleRowSelect = ids => {
    toggleTeacherSelection(ids);
  };

  const handleSelectAll = ids => {
    selectTeachers(ids);
  };

  const handleDeselectTeachers = ids => {
    deselectTeachers(ids);
  };

  const handleDeselectAllTeachers = () => {
    deselectAllTeachers();
  };

  const handleBulkAction = () => {
    if (state.bulkAction?.license) {
      const { license } = state.bulkAction;
      setLicensesToAssign({ license, userIds: selectedTeachers.toJS() });
    } else {
      switch (state.bulkAction) {
        case "Remove teacher":
          setTeachersToRemove(selectedTeachers.toJS());
          break;
        case "Remove license":
          setLicensesToRemove(selectedTeachers.toJS());
          break;
        case "Send welcome email":
          setTeachersToEmail(selectedTeachers.toJS());
          break;
        case "Request Certification":
          setTeachersToEmail(selectedTeachers.toJS());
          setEmailTemplate("CERTIFICATION");
          break;
        case "Switch to student":
          setUsersToDowngrade(selectedTeachers.toJS());
          break;
        default:
          return;
      }
    }
  };

  const getHeaderMenuItems = () => {
    return (
      <MenuItems
        featureFlags={featureFlags}
        hasDomainLicense={hasDomainLicense}
        isBookCreatorUser={isBookCreatorUser}
        licenseInfo={licenseInfo}
        showLicenseSubMenu={state.showHeaderLicenses}
        onLicenseParentClick={() => setState(prev => ({ ...prev, showHeaderLicenses: true }))}
        onLicenseItemClick={license => setState(prev => ({ ...prev, bulkAction: { license } }))}
        onSendWelcomeEmailClick={() => setState(prev => ({ ...prev, bulkAction: "Send welcome email" }))}
        onRequestCertificationClick={() => setState(prev => ({ ...prev, bulkAction: "Request Certification" }))}
        onRemoveLicenseClick={() => setState(prev => ({ ...prev, bulkAction: "Remove license" }))}
        onRemoveTeacherClick={() => setState(prev => ({ ...prev, bulkAction: "Remove teacher" }))}
        onSetUsersToDowngrade={() => setState(prev => ({ ...prev, bulkAction: "Switch to student" }))}
        showManageLibraryMenu={false}
      />
    );
  };

  const getDropDownValue = () => {
    if (state.bulkAction?.license) {
      const { license } = state.bulkAction;
      return (
        <LicenseIndicator
          bookQuota={license.bookQuota}
          libraryQuota={license.libraryQuota}
          available={license.quantity - license.allocated}
        />
      );
    }
    return state.bulkAction;
  };

  const {
    selectedTeacherLibrary,
    showTeacherLibraries,
    showManageTeacherLibraries,
    tableData,
    bulkAction,
    sortBy,
    tableRows,
    tablePageSize,
  } = state;

  return (
    <div className="teacher-table-page">
      {selectedTeachers.size ? (
        <SelectionHeader onCancel={handleDeselectAllTeachers}>
          <SelectionHeaderLabel>
            <strong>{selectedTeachers.size}</strong> {selectedTeachers.size === 1 ? "teacher" : "teachers"} selected
          </SelectionHeaderLabel>
          <DropdownMenu
            placeholder="Bulk Actions"
            value={bulkAction}
            valueRenderer={getDropDownValue}
            width={168}
            maxWidth={250}
            onClose={() => setState(prev => ({ ...prev, showHeaderLicenses: false }))}
            onChange={value => setState(prev => ({ ...prev, bulkAction: value }))}>
            {getHeaderMenuItems()}
          </DropdownMenu>
          <HeaderButton onClick={handleBulkAction} disabled={bulkAction === null}>
            {bulkAction === "Send welcome email" || bulkAction === "Request Certification" ? "Preview" : "Apply"}
          </HeaderButton>
        </SelectionHeader>
      ) : null}

      {(() => {
        if (!hasTeachers) {
          return (
            <EmptyState
              title="You don't have any teachers in your organisation"
              body="When you import teachers, they'll show up here. Once imported, you'll be able to assign and manage their Book Creator licenses."
            />
          );
        }

        if (tableData.body?.size) {
          return (
            <Fragment>
              <Table
                data={tableData}
                sortBy={sortBy}
                selectable={!showInactiveTeachers}
                rowHeight={58}
                onRowSelect={handleRowSelect}
                onSelectAll={handleSelectAll}
                onDeselectAll={handleDeselectTeachers}
                onDeselectRows={handleDeselectTeachers}
                resetSelection={handleDeselectAllTeachers}
                onHeadingClick={setSortBy}
                selectedRows={selectedTeachers}
                onPageSizeChange={tablePageSize => setState(prev => ({ ...prev, tablePageSize }))}
                onRowsChange={tableRows => setState(prev => ({ ...prev, tableRows }))}
              />
              <CSVExport
                allRows={sortRows(tableData.body, sortBy).toJS()}
                currentRows={tableRows}
                selectedTeachers={selectedTeachers}
                tablePageSize={tablePageSize}
                licenseInfo={licenseInfo ? licenseInfo.toJS() : null}
              />
              {showTeacherLibraries && (
                <LibraryMenu
                  admins={admins}
                  adminUser={adminUser}
                  featureFlags={featureFlags}
                  onManageLibrariesClick={showManageLibraryMenu}
                  teacher={selectedTeacherLibrary}
                  onClose={() => setState(prev => ({ ...prev, showTeacherLibraries: false }))}
                />
              )}
              {showManageTeacherLibraries ? (
                <LibraryManagement
                  admins={admins.toJS()}
                  adminUser={adminUser}
                  teachers={teachers.toJS()}
                  selectedTeacher={selectedTeacherLibrary}
                  onClose={() => setState(prev => ({ ...prev, showManageTeacherLibraries: false }))}
                />
              ) : null}
            </Fragment>
          );
        }

        return <EmptyState title="No teachers found" body="Please review your search criteria." />;
      })()}
    </div>
  );
};

export default TeacherTable;
