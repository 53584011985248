import React from "react";
import MiniMenuItem from "../../ui/mini-menu/item";

const MiniMenuItems = props => {
  const { onRemoveAdminClick } = props;

  return (
    <div>
      <MiniMenuItem onClick={onRemoveAdminClick} type="negative">
        Remove admin
      </MiniMenuItem>
    </div>
  );
};

export default MiniMenuItems;
