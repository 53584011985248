import React from "react";
import Item from "./item";
import "./index.scss";

const Toggle = props => {
  const { options, value, onChange } = props;

  return (
    <div className="toggle">
      {options.map(option => (
        <Item
          {...option}
          key={option.value}
          selected={option.value === value}
          onChange={onChange}
        />
      ))}
    </div>
  );
};

export default Toggle;
