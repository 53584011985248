import Bowser from "bowser";
const browser = Bowser.getParser(window.navigator.userAgent);
const browserName = browser.getBrowserName();
const browserVesion = browser.getBrowserVersion();
const browserMajorVesion = parseInt(browserVesion, 10);
const osName = browser.getOSName();
const platformModel = browser.getPlatform() && browser.getPlatform().model;
export const isFirefox = browser.isBrowser("firefox");
export const isChrome = browser.isBrowser("chrome");
export const isIE = browserName === "Internet Explorer";
export const isEdge = browserName === "Microsoft Edge";
export const isSafari = browserName === "Safari";
export const isIOS = osName === "iOS";
export const isIPad = platformModel === "iPad";
export const isAndroid = osName === "Android";
export const isMac = osName === "macOS";

export const browserVersion = browserMajorVesion || 0;

function _cutTheMustard() {
  return (
    isChrome ||
    isFirefox ||
    isEdge ||
    isAndroid ||
    (isIOS && isSafari) ||
    (isSafari && browserVersion > 7) ||
    (isIE && browserVersion > 10)
  );
}

export const cutTheMustard = _cutTheMustard();
