import React, { Component } from "react";
import classNames from "classnames";
import "./index.scss";

export default class Panel extends Component {
  render() {
    const { title, children, className } = this.props;

    const classes = classNames("panel", {
      [className]: className,
    });

    return (
      <div className={classes}>
        {title ? <h2 className="panel__title">{title}</h2> : null}
        {children}
      </div>
    );
  }
}
