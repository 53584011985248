import React, { useState, useEffect } from "react";
import Modal from "src/common/components/Modal";
import "./index.scss";
import UserInfo from "../preview-dropdown/user-info";
import { useRevokeMembershipApi } from "../api-hooks";

interface ManageLicenseProps {
  teacher: any;
  onClose: () => void;
}

const ManageLicense = ({ onClose, teacher }: ManageLicenseProps) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isRevoked, setIsRevoked] = useState(false);

  const {
    success: revokeSuccess,
    failed: revokeFailed,
    isLoading: isLoadingRevokeMembership,
  } = useRevokeMembershipApi(teacher.id, isRevoked);

  useEffect(() => {
    if (isRevoked && !isLoadingRevokeMembership) {
      if (revokeSuccess) {
        setShowConfirmation(true);
      } else if (revokeFailed) {
        onClose();
      }
    }
  }, [
    isRevoked,
    revokeSuccess,
    isLoadingRevokeMembership,
    onClose,
    revokeFailed,
  ]);

  const handleKeepLicense = () => {
    setIsRevoked(false);
    setShowConfirmation(true);
  };

  const handleRevokeLicense = () => {
    setIsRevoked(true);
  };

  if (showConfirmation) {
    return (
      <Modal
        width={525}
        onClose={onClose}
        onSubmit={onClose}
        submitLabel="Done">
        <div className="manage-license">
          <h4 className="manage-license__confirmation-title">
            Premium license {isRevoked ? "revoked" : "active"}
          </h4>
          <p className="manage-license__confirmation-body">
            {isRevoked
              ? "This teacher account has been downgraded to the free plan of Book Creator."
              : `This teacher's premium license is active and they will remain in your domain.`}
          </p>
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      title="Manage License"
      width={540}
      onClose={handleKeepLicense}
      closeLabel="Keep License"
      onSubmit={handleRevokeLicense}
      submitLabel="Revoke premium license"
      submitDisabled={isRevoked && isLoadingRevokeMembership}
      hideX>
      <div className="manage-license">
        <p>
          Would you like to keep premium access for this teacher or revoke their
          license and downgrade to free account?
        </p>
        <UserInfo teacher={teacher} />
      </div>
    </Modal>
  );
};

export default ManageLicense;
