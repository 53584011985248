import React, { forwardRef, Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classNames from "classnames";
import SvgIcon from "../SvgIcon";
import Loader from "../Loader";
import { Tooltip } from "react-tooltip";
import "./index.scss";

const Button = forwardRef((props, ref) => {
  const {
    children,
    compact,
    css,
    className,
    dataReadId,
    disabled,
    fauxdisabled,
    fauxfocused,
    fauxhovered,
    fontSize,
    fullWidth,
    href,
    htmlType,
    icon,
    iconName,
    iconPosition,
    id,
    imageIcon,
    link,
    loading,
    minWidth,
    negative,
    pretext,
    onClick,
    size,
    softDisabledStyle,
    style,
    tabIndex,
    target,
    to,
    title,
    tooltip,
    type,
    wrappable,
  } = props;

  const iconOnly = !children;
  const isPrimary = type && type === "primary";

  const cssClass = classNames("button-v2", {
    "button-v2--compact": compact,
    "button-v2--full-width": fullWidth,
    "button-v2--large": size && size === "large",
    "button-v2--small": size && size === "small",
    "button-v2--text": type && type === "text",
    "button-v2--secondary-text": type && type === "secondary-text",
    "button-v2--transparent": type && type === "transparent",
    "button-v2--transparent-inverse": type && type === "transparent-inverse",
    "button-v2--negative": (type && type === "negative") || negative,
    "button-v2--active": type && type === "active",
    "button-v2--primary": isPrimary,
    "button-v2--white": type && type === "white",
    "button-v2--secondary": type && type === "secondary",
    "button-v2--tertiary": type && type === "tertiary",
    "button-v2--ghost": type && type === "ghost",
    "button-v2--lozenge": type && type === "lozenge",
    "button-v2--icon-right": iconPosition && iconPosition === "right",
    "button-v2--no-icon": !icon,
    "button-v2--icon-only": iconOnly,
    "button-v2--min-width": minWidth,
    "button-v2--loading": loading,
    "button-v2--pretext": !!pretext,
    "button-v2--faux-disabled": fauxdisabled,
    "button-v2--soft-disabled": disabled && softDisabledStyle,
    "button-v2--wrappable": wrappable,
    "button-v2--focused": fauxfocused,
    "button-v2--hovered": fauxhovered,
    [`${css}`]: css,
    [`${className}`]: className,
  });

  const loader = (
    <Loader
      tiny
      center
      colour={disabled ? (isPrimary ? "dark-grey" : "blue") : isPrimary ? "white" : null}
      scale={0.75}
    />
  );

  const sharedProps = {
    className: cssClass,
    "data-read-id": dataReadId,
    id,
    onClick,
    title,
    ref,
    style: {
      ...style,
      fontSize,
      minWidth: typeof minWidth === "number" ? minWidth : null,
    },
    tabIndex,
    disabled,
    target,
    "data-tip": tooltip,
    "data-for": tooltip ? `btn-${id}` : null,
  };
  const content = (
    <Fragment>
      {pretext ? <span className="button-v2__pretext">{pretext}</span> : null}
      {icon ? <SvgIcon icon={icon} name={iconName} /> : null}
      {imageIcon ? <img src={imageIcon} className="button-v2__image-icon" alt="" /> : null}
      {children ? <span className="button-v2__text">{children}</span> : null}
      {loading ? loader : null}
      {tooltip ? (
        <Tooltip id={`btn-${id}`} effect="solid">
          {tooltip}
        </Tooltip>
      ) : null}
    </Fragment>
  );

  if (href) {
    return (
      <a href={href} {...sharedProps}>
        {content}
      </a>
    );
  } else if (link || to) {
    return (
      <Link to={link || to} {...sharedProps}>
        {content}
      </Link>
    );
  } else {
    return (
      <button type={htmlType} {...sharedProps}>
        {content}
      </button>
    );
  }
});

Button.propTypes = {
  className: PropTypes.string,
  css: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  htmlType: PropTypes.string,
  icon: PropTypes.string,
  iconName: PropTypes.string,
  iconPosition: PropTypes.string,
  id: PropTypes.string,
  imageIcon: PropTypes.string,
  link: PropTypes.string,
  loading: PropTypes.bool,
  minWidth: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  onClick: PropTypes.func,
  pretext: PropTypes.string,
  size: PropTypes.string,
  softDisabledStyle: PropTypes.bool,
  style: PropTypes.object,
  tabIndex: PropTypes.number,
  title: PropTypes.string,
  tooltip: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.string,
  wrappable: PropTypes.bool,
};

export default Button;
