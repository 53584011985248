import React, { useEffect, useState } from "react";
import superagent from "superagent";
import { BOOKCREATOR_API_URL } from "../../../../../../../config";
import Loader from "../../../../../ui/loader";
import SvgIcon from "../../../../../ui/svg-icon";
import TickIcon from "../../../../../../../images/svg/tick.svg";
import CrossIcon from "../../../../../../../images/svg/cross.svg";
import "./index.scss";

function MultipleDowngradeItem(props) {
  const { email, id, onSuccess, onFail, ready, succeeded, failed } = props;

  const [downgrading, setDowngrading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const downgrade = () => {
      setDowngrading(true);
      superagent
        .post(`${BOOKCREATOR_API_URL}/users/v2/${id}/convert-to-student`)
        .send({ myBooksLibraryName: "My books" })
        .withCredentials()
        .end((err, res) => {
          if (err) {
            const msg = res && res.body ? res.body.message : err.message;
            onFail(id);
            setError(msg);
          } else {
            onSuccess(id);
          }
        });
    };

    if (ready && !downgrading) {
      downgrade();
    }
  }, [ready, downgrading, id, onFail, onSuccess, setError]);

  return (
    <div className="multiple-downgrade-user">
      <div className="multiple-downgrade-user__status">
        {downgrading && !succeeded && !failed ? <Loader tiny /> : null}
        {succeeded ? <SvgIcon icon={TickIcon} name="tick" /> : null}
        {failed ? <SvgIcon icon={CrossIcon} name="cross" /> : null}
        {!downgrading && !succeeded && !failed ? <span>-</span> : null}
      </div>
      <span className="multiple-downgrade-user__email fs-mask">{email}</span>
      {error ? (
        <span className="multiple-downgrade-user__error">{error}</span>
      ) : null}
    </div>
  );
}

export default MultipleDowngradeItem;
