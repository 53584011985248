import React from "react";
import { connect } from "react-redux";
import { organisationActions } from "../../../../../core/organisation";
import OrganisationPickerModal from "./";

function OrganisationPickerModalContainer({ setShowOrgPicker }) {
  return <OrganisationPickerModal onClose={() => setShowOrgPicker(false)} />;
}

const mapDispatchToProps = {
  ...organisationActions,
};

export default connect(
  null,
  mapDispatchToProps
)(OrganisationPickerModalContainer);
