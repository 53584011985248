import { createSelector } from "reselect";
import { getLicenseInfoJS } from "../organisation";

export const getTeachers = state => state.teachers;

export const getTeachersLoaded = state => getTeachers(state).loaded;

export const getInactiveTeachersLoaded = state => getTeachers(state).inactiveLoaded;

export const getAllTeachers = state => getTeachers(state).teachers;

export const getInactiveTeachers = state => getTeachers(state).inactiveTeachers;

export const getTeacherCount = createSelector([getAllTeachers], teachers => teachers.size);

export const getSortBy = state => getTeachers(state).sortBy;

export const getSelectedTeachers = state => getTeachers(state).selectedTeachers;

export const getSelectedTeachersWithData = createSelector(
  [getAllTeachers, getSelectedTeachers],
  (allTeachers, selectedTeachers) => {
    if (!selectedTeachers || !allTeachers) return [];
    // Create a Set for O(1) lookup
    const selectedIds = new Set(selectedTeachers);
    return allTeachers.reduce((acc, teacher) => {
      if (selectedIds.has(teacher.get("id"))) {
        acc.push(teacher.toJS());
      }
      return acc;
    }, []);
  },
);

export const getShowTeacherImport = state => getTeachers(state).showTeacherImport;

export const getImportingTeachers = state => getTeachers(state).importingTeachers;

export const getTeachersToEmail = state => getTeachers(state).teachersToEmail;

export const getAllTeachersToEmailHave1000Books = createSelector(
  [getAllTeachers, getTeachersToEmail, getLicenseInfoJS],
  (teachers, teachersToEmail, licenseInfo) => {
    if (!teachersToEmail || !licenseInfo?.length) return false;

    const license1000 = licenseInfo.find(license => license.bookQuota === 1000);
    if (!license1000) return false;

    // Create a Set for O(1) lookup
    const emailIds = new Set(teachersToEmail);
    const teachersWithData = teachers.reduce((acc, teacher) => {
      if (emailIds.has(teacher.get("id"))) {
        acc.push(teacher.toJS());
      }
      return acc;
    }, []);

    return teachersWithData.length > 0 && teachersWithData.every(teacher => teacher.license === license1000.id);
  },
);

export const getLicensesToAssign = state => getTeachers(state).licensesToAssign;

export const getTeachersToEmailAddresses = createSelector(
  [getAllTeachers, getTeachersToEmail],
  (allTeachers, teachersToEmail) => {
    if (!teachersToEmail) return [];

    // Create a Map for O(1) lookup
    const teacherMap = allTeachers.reduce((map, teacher) => {
      map.set(teacher.get("id"), teacher.get("email"));
      return map;
    }, new Map());

    return teachersToEmail.reduce((acc, teacherId) => {
      const email = teacherMap.get(teacherId);
      if (email) acc.push(email);
      return acc;
    }, []);
  },
);

export const getTeachersToAssignCurrentLicenses = createSelector(
  [getAllTeachers, getLicensesToAssign],
  (allTeachers, licensesToAssign) => {
    if (!licensesToAssign) return [];

    const userIds = licensesToAssign.get("userIds");
    // Create a Map for O(1) lookup
    const teacherMap = allTeachers.reduce((map, teacher) => {
      map.set(teacher.get("id"), teacher.get("license"));
      return map;
    }, new Map());

    return userIds.reduce((acc, teacherId) => {
      const license = teacherMap.get(teacherId);
      if (license) acc[teacherId] = license;
      return acc;
    }, {});
  },
);

export const getConfirmations = createSelector([getTeachers], teachers => ({
  licensesToAssign: teachers.licensesToAssign,
  assigningLicenses: teachers.assigningLicenses,
  licensesToRemove: teachers.licensesToRemove,
  removingLicenses: teachers.removingLicenses,
  teachersToRemove: teachers.teachersToRemove,
  removingTeachers: teachers.removingTeachers,
  teachersToEmail: teachers.teachersToEmail,
  emailingTeachers: teachers.emailingTeachers,
  emailSent: teachers.emailSent,
  emailTemplate: teachers.emailTemplate,
  usersToDowngrade: teachers.usersToDowngrade,
  downgradingUsers: teachers.downgradingUsers,
  teacherToDelete: teachers.teacherToDelete,
  deletingTeacher: teachers.deletingTeacher,
}));
