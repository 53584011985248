import React from "react";
import classNames from "classnames";
import "./index.scss";
import { LibraryActions } from "../../library-menu/const";

const tableData = [
  {
    option: LibraryActions.ASSIGN_TO_TEACHER,
    title: "Keep and assign to a teacher",
    body:
      "Change this teacher's library ownership to another teacher. <br/> All content and student access will remain active.",
  },
  {
    option: LibraryActions.ASSIGN_TO_ADMIN,
    title: "Keep and assign to an admin",
    body:
      "Change this teacher's library ownership to an administrator. <br/>All content and student access will remain active.",
  },
  {
    option: LibraryActions.ARCHIVE,
    title: "Archive and close libraries",
    body:
      "Archive the teacher's libraries and revoke student access. <br/>Published books will stay active but uneditable.",
  },
];

const AssignActions = ({ onChange, selectedAction }) => {
  return (
    <table className="actions-table">
      <thead>
        <tr>
          <th>Select one:</th>
        </tr>
      </thead>
      <tbody>
        {tableData.map((row, i) => {
          const cssClass = classNames("actions-table__row", {
            selected: selectedAction === row.option,
          });
          return (
            <tr key={i}>
              <td className={cssClass} onClick={() => onChange(row.option)}>
                <div className="actions-table__row-item">
                  <h4 className="actions-table__row-item-title">{row.title}</h4>
                  <div
                    dangerouslySetInnerHTML={{ __html: row.body }}
                    className="actions-table__row-item-body"></div>
                </div>
                <input
                  type="radio"
                  checked={selectedAction === row.option}
                  onChange={() => onChange(row.option)}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default AssignActions;
