import { createSelector } from "reselect";

export function getAuth(state) {
  return state.auth;
}

export function getUserId(state) {
  return getAuth(state).id;
}

export function getAuthToken(state) {
  return getAuth(state).authToken;
}

export function getEmail(state) {
  return getAuth(state).email;
}

export function getDisplayName(state) {
  return getAuth(state).displayName;
}

export function getEmailVerified(state) {
  return getAuth(state).verified;
}

export function getEmailUnverified(state) {
  return getAuth(state).unverified;
}

export const isAuthenticated = createSelector([getAuth], auth => Boolean(auth.authenticated));

export const getIsBookCreatorUser = createSelector([getEmail], email =>
  Boolean(email && email.indexOf("@bookcreator.com") > -1),
);
