import React, { Component } from "react";
import Modal from "../../../../modal";
import SvgIcon from "../../../../ui/svg-icon";
import EmailSentIcon from "../../../../../../images/svg/email-sent.svg";
import Button from "../../../../button";
import "./index.scss";

export default class EmailSuccess extends Component {
  render() {
    const { onClose } = this.props;

    return (
      <Modal
        title="Your message has been sent!"
        modal
        width={527}
        notransition
        align="center"
        showX
        clickBackgroundToClose
        hideFooter
        onCancel={onClose}
        cancelText="Cancel">
        <div className="email-success">
          <SvgIcon icon={EmailSentIcon} name="email-sent" />
        </div>
        <Button minWidth={158} type="submit" onClick={onClose}>
          OK
        </Button>
      </Modal>
    );
  }
}
