import React, { Fragment, useState, useEffect } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { createSelector } from "reselect";
import { getAuthToken, isAuthenticated } from "../../../../core/auth";
import {
  getOrganisationList,
  organisationActions,
} from "../../../../core/organisation";
import Form from "../../forms/form";
import FormButton from "../../forms/form-button";
import SearchableDropdown from "../../forms/searchable-dropdown";
import Loader from "../../ui/loader";
import "./index.scss";

const OrganisationPicker = props => {
  const [selectedOrg, setSelectedOrg] = useState(null);
  const location = useLocation();

  const {
    getOrganisations,
    loadOrgsOnMount,
    authToken,
    organisations,
    authenticated,
  } = props;

  useEffect(() => {
    if (loadOrgsOnMount && authToken) {
      getOrganisations();
    }
  }, [loadOrgsOnMount, authToken, getOrganisations]);

  useEffect(() => {
    if (authToken && loadOrgsOnMount) {
      const orgId = location.state?.from?.pathname?.split("/")[1];
      getOrganisations(orgId);
    }
  }, [authToken, loadOrgsOnMount, getOrganisations, location.state]);

  const handleSubmit = e => {
    const { setOrganisation, onSubmit } = props;
    setOrganisation(selectedOrg.value);
    if (onSubmit) {
      onSubmit();
    }
    e.preventDefault();
  };

  const handleOrgChange = selectedOrg => {
    setSelectedOrg(selectedOrg);
  };

  if (!authenticated) {
    return <Navigate to="/sign-in" />;
  }

  const options = organisations.map(org => {
    return {
      label: org.name,
      value: org,
    };
  });

  const isLoading = !organisations.length;

  return (
    <Form
      header="Select Organization"
      onSubmit={handleSubmit}
      className="organisation-picker"
      autocomplete={false}>
      {isLoading ? (
        <div style={{ height: 100 }}>
          <Loader />
        </div>
      ) : (
        <Fragment>
          <Helmet title="Select Organization" />
          <div className="form__content">
            <SearchableDropdown
              value={selectedOrg}
              onChange={handleOrgChange}
              placeholder="Select Organization"
              options={options}
            />
          </div>
          <div className="form__actions">
            <FormButton type="submit" text="Submit" disabled={!selectedOrg} />
          </div>
        </Fragment>
      )}
    </Form>
  );
};

const mapStateToProps = createSelector(
  getOrganisationList,
  getAuthToken,
  isAuthenticated,
  (organisations, authToken, authenticated) => ({
    organisations,
    authToken,
    authenticated,
  }),
);

const mapDispatchToProps = {
  ...organisationActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationPicker);
