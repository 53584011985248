import React from "react";
import { connect } from "react-redux";
import Header from "../../components/header";
import Content from "../../components/content";
import Stats from "../../components/analytics/stats";
import Charts from "../../components/analytics/charts";
import {
  analyticsActions,
  getAnalyticsResourceAuthExpiry,
  getAnalyticsSignedURLs,
  getUserAnalyticsSignedURLs,
} from "../../../core/analytics";
import { getOrganisationId } from "../../../core/organisation";
import "./index.scss";

function AnalyticsPage(props) {
  const { organisationId, analyticsResourceAuthExpiry, setResourceAuth, analyticsSignedURLs, userAnalyticsSignedURLs } =
    props;

  return (
    <div>
      <Header title="Analytics" />
      <Content>
        <div className="analytics">
          <Stats
            auth={{
              expiresAt: analyticsResourceAuthExpiry,
              getAuth: setResourceAuth,
              userUrls: userAnalyticsSignedURLs,
            }}
          />
          <Charts
            auth={{
              expiresAt: analyticsResourceAuthExpiry,
              getAuth: setResourceAuth,
              urls: analyticsSignedURLs,
              userUrls: userAnalyticsSignedURLs,
            }}
            organisationId={organisationId}
          />
        </div>
      </Content>
    </div>
  );
}

const mapStateToProps = state => ({
  organisationId: getOrganisationId(state),
  analyticsResourceAuthExpiry: getAnalyticsResourceAuthExpiry(state),
  analyticsSignedURLs: getAnalyticsSignedURLs(state),
  userAnalyticsSignedURLs: getUserAnalyticsSignedURLs(state),
});

const mapDispatchToProps = {
  ...analyticsActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsPage);
