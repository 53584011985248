import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { getLMSConfiguration, getLMSLoaded, getLMSGenerating, lmsActions } from "../../../core/lms";
import Header from "../../components/header";
import Content from "../../components/content";
import Loader from "../../components/ui/loader";
import LMSConfiguration from "../../components/lms/configuration";
import Confirmations from "../../components/lms/confirmations";
import "./index.scss";

const LMS = ({
  lmsLoaded,
  lmsConfiguration,
  lmsGenerating,
  loadCredentials,
  generateCredentials,
  setRevokeConfirmationVisibility,
}) => {
  useEffect(() => {
    loadCredentials();
  }, [loadCredentials]);

  return (
    <div className="lms">
      <Helmet title="LMS Integration" />
      <Header title="LMS Integration" />
      <Content className="lms__content">
        <p className="lms__intro">
          Book Creator can be integrated with your LMS using the Learning Tools Interoperability (LTI) protocol. Once
          configured teachers and students can launch Book Creator from your LMS without needing to create an account or
          sign in.
        </p>
        <div className="lms__sections">
          <div className="lms__section lms__section--config">
            <h2 className="lms__section-title">LTI Configuration</h2>
            {lmsLoaded ? (
              <LMSConfiguration
                {...lmsConfiguration}
                generateCredentials={generateCredentials}
                lmsGenerating={lmsGenerating}
                setRevokeConfirmationVisibility={setRevokeConfirmationVisibility}
              />
            ) : (
              <Loader colour="white" center />
            )}
          </div>
        </div>
        <Confirmations />
      </Content>
    </div>
  );
};

const mapStateToProps = state => ({
  lmsLoaded: getLMSLoaded(state),
  lmsConfiguration: getLMSConfiguration(state),
  lmsGenerating: getLMSGenerating(state),
});

const mapDispatchToProps = {
  ...lmsActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(LMS);
