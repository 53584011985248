import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { getTeacherCount, getTeachersLoaded, teacherActions } from "../../../../core/teachers";
import { getOrganisationId } from "../../../../core/organisation";
import Stat from "./stat";
import TeacherIcon from "../../../../images/svg/teacher.svg";
import StudentIcon from "../../../../images/svg/student.svg";
import BookSpreadIcon from "../../../../images/svg/book-spread.svg";
import LibraryIcon from "../../../../images/svg/library.svg";
import "./index.scss";

const Stats = props => {
  const { loadTeachers, organisationId, teacherCount, teachersLoaded, auth } = props;

  useEffect(() => {
    loadTeachers();
  }, [loadTeachers]);

  return (
    <div className="stats">
      <Fragment>
        <Stat
          loading={!teachersLoaded}
          title="Teachers"
          icon={TeacherIcon}
          iconName="teacher"
          value={teacherCount}
          auth={auth}
        />
        <Stat
          title="Students"
          icon={StudentIcon}
          iconName="student"
          endpoint="students/count"
          organisationId={organisationId}
          auth={auth}
        />
        <Stat
          title="Libraries Created"
          icon={LibraryIcon}
          iconName="library"
          endpoint="libraries/count"
          organisationId={organisationId}
          auth={auth}
        />
      </Fragment>
      <Stat
        title="Books Created"
        icon={BookSpreadIcon}
        iconName="books"
        endpoint="books/count"
        organisationId={organisationId}
        auth={auth}
      />
    </div>
  );
};

Stats.propTypes = {
  loadTeachers: PropTypes.func.isRequired,
  organisationId: PropTypes.string,
  teacherCount: PropTypes.number,
  teachersLoaded: PropTypes.bool.isRequired,
  auth: PropTypes.shape({
    expiresAt: PropTypes.number,
    getAuth: PropTypes.func,
    userUrls: PropTypes.string,
  }),
};

const mapDispatchToProps = {
  ...teacherActions,
};

const mapStateToProps = createSelector(
  getOrganisationId,
  getTeacherCount,
  getTeachersLoaded,
  (organisationId, teacherCount, teachersLoaded) => ({
    organisationId,
    teacherCount,
    teachersLoaded,
  }),
);

export default connect(mapStateToProps, mapDispatchToProps)(Stats);
