import React, { Component } from "react";
import { LTI_DOMAIN } from "../../../../config";
import TableList from "../../table-list";
import Loader from "../../ui/loader";
// import './index.scss';

export default class LMSConfiguration extends Component {
  handleGenerateClick = e => {
    this.props.generateCredentials();
    e.preventDefault();
  };

  handleRevokeClick = e => {
    this.props.setRevokeConfirmationVisibility(true);
  };

  render() {
    const { consumerKey, consumerSecret, lmsGenerating } = this.props;

    const generateButton = (
      <button
        type="button"
        className="table-list__th-action"
        onClick={this.handleGenerateClick}>
        generate
      </button>
    );
    const revokeButton = (
      <button
        type="button"
        className="table-list__th-action"
        onClick={this.handleRevokeClick}>
        revoke
      </button>
    );
    const buttonToUse = lmsGenerating
      ? null
      : consumerKey
      ? revokeButton
      : generateButton;

    const tableData = [
      {
        heading: <span>Key {buttonToUse}</span>,
        body: lmsGenerating ? (
          <Loader tiny center />
        ) : consumerKey ? (
          <span className="fs-mask">{consumerKey}</span>
        ) : (
          <i>Not generated</i>
        ),
      },
      {
        heading: "Secret",
        body: lmsGenerating ? (
          <Loader tiny center />
        ) : consumerSecret ? (
          <span className="fs-mask">{consumerSecret}</span>
        ) : (
          <i>Not generated</i>
        ),
      },
      {
        heading: "Launch url",
        body: `https://${LTI_DOMAIN}/v1/bc-web-v1/launch`,
      },
      {
        heading: "Domain",
        body: "bookcreator.com",
      },
      {
        heading: "Privacy",
        body: "Public - send name and email",
      },
      {
        heading: "Open in a new tab",
        body: "Yes (Book creating works best with a full screen)",
      },
    ];

    return (
      <div className="lms-configuration">
        <TableList data={tableData} />
        <p className="lms-configuration__additional">
          We also support XML configuration (with an LTI cartridge) at https://
          {LTI_DOMAIN}/v1/bc-web-v1
        </p>
      </div>
    );
  }
}
