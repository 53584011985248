import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { TERMS_OF_SERVICE_URL } from "../../../constants";
import Form from "../forms/form";
import Loader from "../ui/loader";
import Message from "../ui/message";
import SignInButton from "./sign-in-button";

function SignIn(props) {
  const { clearAuthError, signInWithGoogle, auth } = props;

  useEffect(() => {
    return () => {
      clearAuthError();
    };
  }, [clearAuthError]);

  const { error } = auth.toJS ? auth.toJS() : auth;

  const title = "Sign in";

  let errorMessage;
  // Show friendlier message
  if (error) {
    switch (error.message) {
      case "The popup has been closed by the user before finalizing the operation.":
      case "User did not grant your application the permissions it requested.":
        errorMessage = "You need to allow Book Creator access to your Google account to sign in with Google";
        break;
      default:
        errorMessage = error.message;
    }
  }

  if (!auth.initialised) {
    return <Loader colour="white" />;
  }

  return (
    <Form header={title}>
      <Helmet title={title} />
      <p className="form__disclaimer">
        By signing up you agree to the
        <a
          href={TERMS_OF_SERVICE_URL}
          // don't set rel=noreferrer as we own the target and the referrer will be useful in analytics
          // eslint-disable-next-line
          target="_blank">
          Terms of Service
        </a>
      </p>
      <div className="form__content">
        <SignInButton
          text="Sign in with Google"
          provider="google"
          onClick={e => {
            signInWithGoogle();
            e.preventDefault();
          }}
        />
        <SignInButton href="/sign-in/office-365" text="Sign in with Office 365" provider="office" />
        <SignInButton href="/sign-in/email" text="Sign in with email" provider="email" />
        {error ? (
          <Message type="error" center>
            {errorMessage}
          </Message>
        ) : null}
      </div>
    </Form>
  );
}

export default SignIn;
