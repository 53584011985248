import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { NotificationStack } from "react-notification";
import { connect } from "react-redux";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { createSelector } from "reselect";
import "../../common/styles/colors.scss";
import { getAuth, getAuthToken, getIsBookCreatorUser, isAuthenticated } from "../../core/auth";
import { getNotifications, notificationsActions } from "../../core/notifications";
import {
  getOrganisationId,
  getOrganisationLoaded,
  getShowOrgPicker,
  organisationActions,
} from "../../core/organisation";
import Intercom from "../components/intercom";
import Nav from "../components/nav";
import OrganisationPickerModal from "../components/sign-in/organisation-picker/modal/container";
import "./index.scss";
import Main from "./main";

const OrgRouter = ({ auth, routeKey }) => {
  return (
    <>
      <Nav auth={auth} routeKey={routeKey} />
      <Main />
    </>
  );
};

function App(props) {
  const {
    allNotifications,
    auth,
    authenticated,
    authToken,
    getOrganisations,
    isBookCreatorUser,
    organisationId,
    organisationLoaded,
    removeNotification,
    showOrgPicker,
  } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const organisationRef = useRef(organisationId);

  useEffect(() => {
    if (authToken) {
      getOrganisations();
    }
  }, [authToken, getOrganisations]);

  useEffect(() => {
    if (!organisationId) return;

    const currentPath = location.pathname;

    organisationRef.current = organisationId;

    if (!currentPath.startsWith(`/${organisationId}`)) {
      const returnUrl = location.state?.from || "/";
      const targetPath = returnUrl === "/" ? `/${organisationId}` : returnUrl;

      if (currentPath !== targetPath) {
        navigate(targetPath, { replace: true });
      }
    }
  }, [organisationId, location.pathname, location.state, navigate]);

  if (!authenticated) {
    return <Navigate to="/sign-in" />;
  }

  const notifications = allNotifications.map(notification => {
    notification.onClick = removeNotification.bind(this, notification.key);
    return notification;
  });

  return (
    <div className="book-creator-admin" data-id={auth.id}>
      <Routes>
        <Route path="/:orgId/*" element={<OrgRouter auth={auth} routeKey={location.key} />} />
      </Routes>
      <NotificationStack notifications={notifications.toArray()} onDismiss={notification => {}} />
      {auth.initialised && organisationLoaded && !isBookCreatorUser ? <Intercom /> : null}
      {showOrgPicker ? <OrganisationPickerModal /> : null}
    </div>
  );
}

const mapStateToProps = createSelector(
  isAuthenticated,
  getAuth,
  getAuthToken,
  getNotifications,
  getOrganisationLoaded,
  getIsBookCreatorUser,
  getShowOrgPicker,
  getOrganisationId,
  (
    authenticated,
    auth,
    authToken,
    allNotifications,
    organisationLoaded,
    isBookCreatorUser,
    showOrgPicker,
    organisationId,
  ) => ({
    authenticated,
    auth,
    authToken,
    allNotifications,
    organisationLoaded,
    isBookCreatorUser,
    showOrgPicker,
    organisationId,
  }),
);

const mapDispatchToProps = {
  ...notificationsActions,
  ...organisationActions,
};

App.propTypes = {
  allNotifications: PropTypes.object.isRequired,
  auth: PropTypes.shape({
    id: PropTypes.string,
    initialised: PropTypes.bool.isRequired,
  }).isRequired,
  authenticated: PropTypes.bool.isRequired,
  authToken: PropTypes.string,
  getOrganisations: PropTypes.func.isRequired,
  isBookCreatorUser: PropTypes.bool.isRequired,
  organisationLoaded: PropTypes.bool.isRequired,
  removeNotification: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
