import React, { useState, useEffect } from "react";
import Modal from "src/common/components/Modal";
import Select, { components } from "react-select";
import Avatar from "../../../avatar";
import { getAvatarUrl } from "../../library-menu/utils";
import { LibraryActions } from "../../library-menu/const";
import { isBCEmail } from "src/utils/validators";
interface AssignModalProps {
  teachers: any[];
  admins: any[];
  selectedAction: string;
  containerClassName?: string;
  onClose: () => void;
  onAssignToUser?: (userId: string) => void;
  onArchiveLibs?: () => void;
}

const AssignModal = ({
  onClose,
  onAssignToUser,
  onArchiveLibs,
  selectedAction,
  teachers,
  admins,
  containerClassName,
}: AssignModalProps) => {
  const [assignToUser, setAssignToUser] = useState(null);
  const [modalProps, setModalProps] = useState({
    title: "",
    content: "",
    submitLabel: "",
    placeholder: "",
    selectOptions: [],
  });

  useEffect(() => {
    const props = {
      title: "",
      content: "",
      submitLabel: "",
      placeholder: "",
      selectOptions: [],
    };
    if (selectedAction === LibraryActions.ARCHIVE) {
      props.title = "ACHIVE LIBRARIES";
      props.content =
        "This teacher's libraries will be archived and student access removed. Published books will stay active but uneditable.";
      props.submitLabel = "Continue";
    } else if (selectedAction === LibraryActions.ASSIGN_TO_TEACHER) {
      props.title = "ASSIGN TO TEACHER";
      props.content =
        "Select a Teacher that you would like to assign all libraries and student ownership to:";
      props.placeholder = "Select Teacher";
      props.submitLabel = "Assign";
      props.selectOptions = teachers.reduce((acc, teacher) => {
        // TODO: check if we need to filter by role to exclude lib admins or != LibraryRole.TEACHER
        if (!teacher.pending) {
          acc.push({
            value: teacher.id,
            label: teacher.displayName,
            userId: teacher.id,
          });
        }
        return acc;
      }, []);
    } else if (selectedAction === LibraryActions.ASSIGN_TO_ADMIN) {
      props.title = "ASSIGN TO ADMIN";
      props.content =
        "Select an Admin that you would like to assign all libraries and student ownership to:";
      props.placeholder = "Select Admin";
      props.submitLabel = "Assign";

      const adminOptions = admins.reduce((acc, admin) => {
        if (!isBCEmail(admin.email)) {
          const teacher = teachers.find(
            teacher => teacher.email === admin.email
          );
          acc.push({
            value: admin.email,
            label: teacher ? teacher.displayName : admin.email,
            userId: teacher ? teacher.id : admin.email,
          });
        }
        return acc;
      }, []);

      props.selectOptions = adminOptions;
    }
    setModalProps(props);
  }, [teachers, admins, selectedAction]);

  const showUserSelect = selectedAction !== LibraryActions.ARCHIVE;

  const UserItem = ({ userName, userId }) => (
    <div className="teacher-info">
      <Avatar name={userName} url={getAvatarUrl(userId)} size={36} />
      <span className="teacher-info__label">{userName}</span>
    </div>
  );

  const WrapperComponent = (componentName, { data, ...props }) => {
    const Component = components[componentName];
    return (
      <Component {...props}>
        <UserItem userName={data.label} userId={data.userId} />
      </Component>
    );
  };

  const handleClose = () => {
    setAssignToUser(null);
    onClose();
  };

  const handleSubmit = () => {
    if (selectedAction === LibraryActions.ARCHIVE) {
      onArchiveLibs();
    } else {
      onAssignToUser(assignToUser);
    }
  };

  return (
    <Modal
      title={modalProps.title}
      onClose={handleClose}
      closeLabel="Cancel"
      submitDisabled={showUserSelect && !assignToUser}
      onSubmit={handleSubmit}
      submitLabel={modalProps.submitLabel}
      showX
      width={540}>
      <div className={containerClassName}>
        <p>{modalProps.content}</p>
        {showUserSelect && (
          <Select
            classNamePrefix="user-select"
            placeholder={modalProps.placeholder}
            isSearchable={false}
            onChange={e => setAssignToUser(e.value)}
            components={{
              Option: props => WrapperComponent("Option", props),
              SingleValue: props => WrapperComponent("SingleValue", props),
              IndicatorSeparator: () => null,
            }}
            name="teachers"
            options={modalProps.selectOptions}
          />
        )}
      </div>
    </Modal>
  );
};

export default AssignModal;
