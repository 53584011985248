import React, { Component } from "react";
import Table from "../../table";
import EmptyState from "../../empty-state";
import LicenseIndicator from "../../teachers/license-indicator";

export default class LicenseTable extends Component {
  get tableData() {
    const { licenses, hasDomainLicense, isApplyToAllLicense } = this.props;
    // we may not need hasDomainLicense here anymore
    const body = licenses
      .filter(license => {
        const { applyToAll } = license;
        if (hasDomainLicense && !applyToAll) {
          return false;
        } else {
          return true;
        }
      })
      .map(license => {
        const { id, bookQuota, libraryQuota, allocated, quantity } = license;
        // if an org has domain license but the list of domains is empty, hasDomainLicense resolves to false
        const available = isApplyToAllLicense
          ? "Unlimited"
          : quantity - allocated;
        return {
          rowId: id,
          cells: [
            <LicenseIndicator
              bookQuota={bookQuota}
              libraryQuota={libraryQuota}
              available={available}
            />,
            <span>{allocated}</span>,
            <span>{available}</span>,
            <span>{isApplyToAllLicense ? "Entire District" : quantity}</span>,
          ],
        };
      });

    return {
      headings: [
        {
          name: "License",
        },
        {
          name: "Assigned",
          align: "center",
        },
        {
          name: "Available",
          align: "center",
        },
        {
          name: "Total purchased",
          align: "center",
        },
      ],
      body,
    };
  }

  render() {
    const { tableData } = this;
    return tableData.body && tableData.body.size ? (
      <Table data={tableData} rowHeight={58} />
    ) : (
      <EmptyState
        title="You don’t have any licenses available"
        body="Contact sales to get a quote or purchase licenses for your organisation. Once licenses are available, you'll be able to assign them to teachers within your organisation."
      />
    );
  }
}
