import React, { useState } from "react";
import { connect } from "react-redux";
import { teacherActions } from "../../../../../core/teachers";
import Modal from "../../../../../common/components/Modal";
import Loader from "../../../ui/loader";
import "./index.scss";
import UserInfo from "../../library-management/preview-dropdown/user-info";
import Checkbox from "src/views/components/checkbox";

function DeleteTeachersConfirmation(props) {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [checkDisclaimer, setCheckDisclaimer] = useState(false);
  const {
    teacherToDelete,
    setTeacherToDelete,
    deleteTeachers,
    deletingTeacher,
  } = props;

  if (deletingTeacher) {
    return (
      <Modal title={"Deleting account"}>
        <div style={{ height: 100, position: "relative" }}>
          <Loader center />
        </div>
      </Modal>
    );
  }

  if (showConfirmation) {
    return (
      <Modal
        width={530}
        onClose={() => setShowConfirmation(false)}
        closeLabel="Cancel"
        submitDisabled={!checkDisclaimer}
        onSubmit={() => deleteTeachers({ teacher: teacherToDelete.toJS() })}
        submitLabel="Permanently delete"
        submitType="negative">
        <div className="delete-account-confirmation">
          <h3> Permanently delete this account? </h3>
          <p>
            Deleted accounts and libraries cannot be recovered. Are you sure you
            want to proceed?
          </p>
          <Checkbox
            onClick={() => setCheckDisclaimer(!checkDisclaimer)}
            checked={checkDisclaimer}
            className="delete-account-confirmation__checkbox">
            I understand deleted accounts are not recoverable
          </Checkbox>
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      title="DELETE ACCOUNT"
      onClose={e => setTeacherToDelete(null)}
      closeLabel="Cancel"
      onSubmit={() => setShowConfirmation(true)}
      submitLabel="Delete account"
      width={530}>
      <div className="delete-account">
        <p>
          Libraries and books owned by this Teacher will be{" "}
          <b>permanently deleted</b> from the system. This may included
          published books in archived libraries.
        </p>
        <UserInfo teacher={teacherToDelete.toJS()} />
      </div>
    </Modal>
  );
}

const mapDispatchToProps = {
  ...teacherActions,
};

export default connect(null, mapDispatchToProps)(DeleteTeachersConfirmation);
