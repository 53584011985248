import React, { useState, useEffect } from "react";
import Modal from "src/common/components/Modal";
import "./index.scss";
import DropdownMenu from "../../ui/dropdown-menu";
import MiniMenuItem from "../../ui/mini-menu/item";
import Loader from "../../ui/loader";
import Table from "../../table";
import EmptyState from "../../empty-state";
import {
  useLibraryApi,
  useJoinLibraryApi,
  useAddAdmintoOrgApi,
  useAddErrorMessage,
  useUserBookCountApi,
} from "./api-hooks";
import LibraryHeader from "./header";
import LibraryName from "./table-item/name";
import LibraryProperty from "./table-item/property";
import ReasonsForVisitModal from "./reasons-modal";
import { Props, LibResponseProps, TableProps, Tab, LibCount } from "./types";
import Button from "src/common/components/Button";

import { Tabs, AllLibsColumns, ArchivedLibsColumns, LibraryRole } from "./const";
import {
  getRoleLabel,
  getBookCount,
  getAvatarUrl,
  getJoinedLibs,
  getTabLabel,
  getVisitLibraryUrl,
  isVisitEnabled,
} from "./utils";

export function LibraryMenu({ featureFlags, teacher, adminUser, admins, onClose, onManageLibrariesClick }: Props) {
  const { id } = teacher;
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedTab, setSelectedTab] = useState<Tab>(Tabs.ALL);
  //library selection
  const [selectedLibraryId, setSelectedLibraryId] = useState<string>("");
  const [showReasonsModal, setShowReasonsModal] = useState<boolean>(false);
  const [reasonForVisit, setReasonForVisit] = useState<string>("");
  // join library
  const [adminLibs, setAdminLibs] = useState([]);
  const [addAdminToOrg, setAddAdminToOrg] = useState<boolean>(false);
  const [joinLibrary, setJoinLibrary] = useState<boolean>(false);
  const [loadingExternalUrl, setLoadingExternalUrl] = useState<boolean>(false);
  // table and header data
  const [tableData, setTableData] = useState<TableProps>({
    headings: [],
    body: null,
  });
  const [sortBy, setSortBy] = useState<string>("id.ASC");
  const [totalLibCount, setTotalLibCount] = useState<LibCount>({
    [Tabs.ALL]: 0,
    [Tabs.ARCHIVED]: 0,
  });
  const [totalBookCount, setTotalBookCount] = useState<LibCount>({
    [Tabs.ALL]: 0,
    [Tabs.ARCHIVED]: 0,
  });

  // custom API hooks
  const { data: userBookCount } = useUserBookCountApi(id, adminUser.orgId);
  const { data: libData, isLoading } = useLibraryApi(id);
  const { data: adminData } = useLibraryApi(adminUser.userId);
  const { data: joinLibData, error: joinLibError } = useJoinLibraryApi(
    id,
    selectedLibraryId,
    reasonForVisit,
    joinLibrary,
  );
  const { isLoading: isLoadingAdminJoinedOrg } = useAddAdmintoOrgApi(adminUser.email, adminUser.orgId, addAdminToOrg);
  const [showPermissionError, setShowPermissionError] = useState<boolean>(false);
  const { isDispatched } = useAddErrorMessage(showPermissionError);

  // teacher total book count
  useEffect(() => {
    setLoading(isLoading);
    if (libData) {
      const privateBooks = userBookCount?.private ?? 0;

      // get lib and total book count for each tab
      setTotalLibCount({
        [Tabs.ALL]: libData.all.size + 1, // MyBooks private lib
        [Tabs.ARCHIVED]: libData.archived.size,
      });
      const allBookCounts = getBookCount(libData.all);
      const archivedBookCounts = getBookCount(libData.archived);

      setTotalBookCount({
        [Tabs.ALL]: allBookCounts + privateBooks,
        [Tabs.ARCHIVED]: archivedBookCounts,
      });
    }
  }, [libData, isLoading, teacher, userBookCount]);

  // admin joined libs
  useEffect(() => {
    if (adminData) {
      const adminLibs = getJoinedLibs(adminData.all);
      setAdminLibs(adminLibs);
    }
  }, [adminData]);

  //app token for visiting lib
  useEffect(() => {
    if (joinLibData.token) {
      // after joining the lib, add admin to the organisation
      setAddAdminToOrg(true);
    }
    if (joinLibError) {
      setLoadingExternalUrl(false);
    }
    setJoinLibrary(false);
  }, [joinLibData, joinLibError]);

  useEffect(() => {
    if (isDispatched) {
      setShowPermissionError(false);
    }
  }, [isDispatched]);

  // add admin to org and open link
  useEffect(() => {
    const openVisitLibraryLink = () => {
      const url = getVisitLibraryUrl(joinLibData.token, selectedLibraryId);
      window.open(url, "_blank");
    };

    if (!isLoadingAdminJoinedOrg && joinLibData.token && selectedLibraryId && addAdminToOrg) {
      openVisitLibraryLink();

      setLoadingExternalUrl(false);
      setAddAdminToOrg(false);

      //add the new lib to avoid being prompted the reasons more than once
      const isLibMember = adminLibs.find(l => l === selectedLibraryId);
      if (!isLibMember) {
        adminLibs.push(selectedLibraryId);
        setAdminLibs(adminLibs);
      }
    }
  }, [isLoadingAdminJoinedOrg, joinLibData.token, selectedLibraryId, addAdminToOrg, adminLibs]);

  const onReasonModalClose = () => {
    setShowReasonsModal(false);
    setLoadingExternalUrl(false);
  };

  const onReasonModalSubmit = (reason: string) => {
    setReasonForVisit(reason);
    setShowReasonsModal(false);
    setJoinLibrary(true);
  };

  // render library rows and handlers
  useEffect(() => {
    const onVisitLibraryClick = (libId: string) => {
      const parsedAdmin = admins.toJS();
      const isAdmin = parsedAdmin.some(a => a.email.toLowerCase() === adminUser.email.toLowerCase());
      if (!isAdmin) {
        return setShowPermissionError(true);
      }
      setSelectedLibraryId(libId);

      const isLibMember = adminLibs.find(l => l === libId);
      if (isLibMember) {
        setJoinLibrary(true);
      } else {
        setShowReasonsModal(true);
      }
      setLoadingExternalUrl(true);
    };

    // render data on tab change
    const renderTableData = (tab: Tab, allData: LibResponseProps): TableProps => {
      // add private books into the list
      const myBooksLib = {
        id: "-1",
        role: LibraryRole.OWNER,
        name: "My Books",
        bookCount: userBookCount?.private,
        isPrivate: true,
      };
      const { libraries, headings } =
        tab === Tabs.ALL
          ? {
              libraries: allData.all.push(myBooksLib),
              headings: AllLibsColumns,
            }
          : { libraries: allData.archived, headings: ArchivedLibsColumns };

      const body = libraries.map(library => {
        const { id, bookCount, name, userCount, role: userRole, isPrivate } = library;
        const role = getRoleLabel(userRole);
        const visitEnabled = isVisitEnabled(userRole);

        if (tab !== Tabs.ARCHIVED) {
          return {
            sortData: {
              id,
              name,
              role,
              author: userCount,
              books: bookCount,
            },
            rowId: id,
            cells: [
              <LibraryName {...library} isOwner={userRole === LibraryRole.OWNER} getAvatarUrl={getAvatarUrl} />,
              <LibraryProperty name="role">{role}</LibraryProperty>,
              <LibraryProperty name="count">{bookCount}</LibraryProperty>,
              <LibraryProperty name="count">{userCount}</LibraryProperty>,
              userRole < LibraryRole.TEACHER ? (
                <LibraryProperty
                  name="visit"
                  isPrivate={isPrivate}
                  disabled={!visitEnabled}
                  onClickHandler={() => onVisitLibraryClick(id)}
                />
              ) : null,
            ],
          };
        }
        return {
          sortData: {
            name,
          },
          rowId: id,
          cells: [<LibraryName {...library} isOwner={userRole === LibraryRole.OWNER} />, <div></div>],
        };
      });

      return {
        headings,
        body,
      };
    };
    setTableData(renderTableData(selectedTab, libData));
  }, [selectedTab, libData, teacher, adminLibs, adminUser.email, admins, userBookCount]);

  return (
    <Modal onClose={onClose} showX width={760}>
      {showReasonsModal ? <ReasonsForVisitModal onClose={onReasonModalClose} onSubmit={onReasonModalSubmit} /> : null}
      {loadingExternalUrl || loading ? <Loader center large /> : null}
      <div className="library-menu">
        <LibraryHeader
          {...teacher}
          featureFlags={featureFlags}
          totalLibCount={totalLibCount[selectedTab]}
          totalBookCount={totalBookCount[selectedTab]}
          getAvatarUrl={getAvatarUrl}
        />
        <div className="library-menu__tabs">
          <DropdownMenu value={getTabLabel(selectedTab, totalLibCount)} width={215} maxWidth={500}>
            {Object.values(Tabs).map((tab: Tab, idx: number) => {
              return selectedTab !== tab ? (
                <MiniMenuItem key={idx} onClick={() => setSelectedTab(tab)}>
                  {getTabLabel(tab, totalLibCount)}
                </MiniMenuItem>
              ) : null;
            })}
          </DropdownMenu>
          <Button minWidth={158} type="secondary" onClick={() => onManageLibrariesClick(teacher)}>
            Manage libraries
          </Button>
        </div>
        <div className="library-menu__content">
          {tableData.body?.size ? (
            <Table data={tableData} sortBy={sortBy} onHeadingClick={setSortBy} rowHeight={58} />
          ) : (
            <EmptyState title="No libraries found" />
          )}
        </div>
      </div>
    </Modal>
  );
}
