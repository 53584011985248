import React from "react";
import { connect } from "react-redux";
import { getOrganisationId } from "../../../core/organisation";
import { getUserId } from "../../../core/auth";
import AppsPage from "./";

function AppsPageContainer(props) {
  return <AppsPage {...props} />;
}

const mapStateToProps = state => ({
  organisationId: getOrganisationId(state),
  userId: getUserId(state),
});

export default connect(mapStateToProps, null)(AppsPageContainer);
