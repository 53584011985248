type IModifiers =
  | string[]
  | {
      [key: string]: any;
    };

export interface IBem extends String {
  (...modifierSets: IModifiers[]): string;
  toString(): string;
  element(el: string, ...modifierSets: IModifiers[]): string;
}

function bemClassNames(block: string, ...modifierSets: IModifiers[]) {
  let classNames = [block];
  for (const modifieres of modifierSets) {
    if (Array.isArray(modifieres)) {
      for (const modifier of modifieres) {
        classNames.push(`${block}--${modifier}`);
      }
    } else {
      for (let modifier in modifieres) {
        if (modifieres[modifier]) {
          classNames.push(`${block}--${modifier}`);
        }
      }
    }
  }

  return classNames.join(" ");
}

export function bem(block: string): IBem;
export function bem(block: string, ...modifierSets: IModifiers[]): string;
export function bem(
  block: string,
  ...modifierSets: IModifiers[]
): IBem | string {
  if (modifierSets.length > 0) {
    return bemClassNames(block, ...modifierSets);
  }

  function root(...modifierSets: IModifiers[]) {
    return bemClassNames(block, ...modifierSets);
  }

  root.element = (el: string, ...modifierSets: IModifiers[]) => {
    return bemClassNames(`${block}__${el}`, ...modifierSets);
  };

  return root as IBem;
}
