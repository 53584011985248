import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import {
  getAdminsLoaded,
  getAdmins,
  getShowAdminImport,
  getImportingAdmins,
  getSelectedAdmins,
  getSortBy,
  adminsActions,
} from "../../../core/admins";
import Header, { HeaderActions } from "../../components/header";
import HeaderButton from "../../components/button/header";
import Content from "../../components/content";
import AdminsTable from "../../components/admins/admins-table";
import EmailImport from "../../components/email-import";
import Loader from "../../components/ui/loader";
import AdminConfirmations from "../../components/admins/confirmations";

const Admin = ({
  adminsLoaded,
  showAdminImport,
  setShowAdminImport,
  importAdmins,
  importingAdmins,
  admins,
  selectedAdmins,
  sortBy,
  setSortBy,
  selectAdmins,
  deselectAdmins,
  deselectAllAdmins,
  toggleAdminSelection,
  setAdminsToRemove,
  loadAdmins,
}) => {
  useEffect(() => {
    loadAdmins();
  }, [loadAdmins]);

  return (
    <div>
      <Helmet title="Admin dashboard" />
      <Header title="Admin">
        <HeaderActions right>
          <HeaderButton onClick={() => setShowAdminImport(true)}>Add admins</HeaderButton>
        </HeaderActions>
      </Header>
      <Content>
        {adminsLoaded ? (
          <AdminsTable
            admins={admins}
            selectedAdmins={selectedAdmins}
            sortBy={sortBy}
            setSortBy={setSortBy}
            selectAdmins={selectAdmins}
            deselectAdmins={deselectAdmins}
            deselectAllAdmins={deselectAllAdmins}
            toggleAdminSelection={toggleAdminSelection}
            setAdminsToRemove={setAdminsToRemove}
          />
        ) : (
          <Loader colour="white" center />
        )}
      </Content>
      <AdminConfirmations />
      {showAdminImport && (
        <EmailImport
          title="Add Admins"
          onSubmit={emails => importAdmins({ emails })}
          importing={importingAdmins}
          userType="admins"
          onClose={() => setShowAdminImport(false)}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  adminsLoaded: getAdminsLoaded(state),
  admins: getAdmins(state),
  showAdminImport: getShowAdminImport(state),
  importingAdmins: getImportingAdmins(state),
  selectedAdmins: getSelectedAdmins(state),
  sortBy: getSortBy(state),
});

const mapDispatchToProps = {
  ...adminsActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
