import React from "react";
import "./index.scss";

const GroupTitle = props => {
  const { children } = props;

  return <h2 className="group-title">{children}</h2>;
};

export default GroupTitle;
