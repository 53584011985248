export const SET_ADMINS_LOADED = "SET_ADMINS_LOADED";
export const RECEIVE_ADMINS_DATA = "RECEIVE_ADMINS_DATA";
export const SET_SHOW_ADMIN_IMPORT = "SET_SHOW_ADMIN_IMPORT";
export const SET_IMPORTING_ADMINS = "SET_IMPORTING_ADMINS";
export const ADD_ADMINS = "ADD_ADMINS";
export const REMOVE_ADMINS = "REMOVE_ADMINS";
export const SET_SORT_BY = "SET_SORT_BY";
export const TOGGLE_ADMIN_SELECTION = "TOGGLE_ADMIN_SELECTION";
export const SELECT_ADMINS = "SELECT_ADMINS";
export const DESELECT_ADMINS = "DESELECT_ADMINS";
export const DESELECT_ALL_ADMINS = "DESELECT_ALL_ADMINS";
export const RESET_ADMINS_STORE = "RESET_ADMINS_STORE";
export const SET_ADMINS_TO_REMOVE = "SET_ADMINS_TO_REMOVE";
export const SET_REMOVING_ADMINS = "SET_REMOVING_ADMINS";
