import React from "react";
import classNames from "classnames";
import "./index.scss";

const LicenseIndicator = props => {
  const { bookQuota, libraryQuota, available } = props;

  const bookCount =
    libraryQuota === "UNLIMITED" ? bookQuota : libraryQuota * bookQuota;

  const countClass = bookCount ? bookCount : "none";

  const cssClass = classNames(
    "license-indicator",
    `license-indicator--${countClass}`
  );

  const label = bookCount ? `${bookCount} books` : "None";

  return (
    <div className={cssClass}>
      <span className="license-indicator__indicator" />
      <span className="license-indicator__label">{label}</span>
      {available !== undefined ? (
        <span className="license-indicator__available">({available})</span>
      ) : null}
    </div>
  );
};

export default LicenseIndicator;
