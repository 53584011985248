import React, { useState, useEffect } from "react";
import Modal from "src/common/components/Modal";
import "./index.scss";
import AssignActions from "./assign-actions";
import TeacherLibraryDropdown from "./preview-dropdown";
import AssignModal from "./assign-modal";
import Loader from "../../ui/loader";

import {
  useLibraryApi,
  useArchiveLibraryApi,
  useReassignLibraryApi,
} from "./api-hooks";
import {
  useJoinLibraryApi,
  useAddAdmintoOrgApi,
  useAddErrorMessage,
} from "../library-menu/api-hooks";
import { getVisitLibraryUrl, getJoinedLibs } from "../library-menu/utils";
import ManageLicense from "./manage-license";
import ReasonsForVisitModal from "../library-menu/reasons-modal";

export default function LibraryManagement({
  selectedTeacher,
  teachers,
  onClose,
  admins,
  adminUser,
}) {
  const [selectedAction, setSelectedAction] = useState(null);
  const [showPermissionError, setShowPermissionError] = useState<boolean>(
    false
  );
  const [showManageLicenseModal, setShowManageLicenseModal] = useState<boolean>(
    false
  );
  const [showReasonsModal, setShowReasonsModal] = useState<boolean>(false);
  const [reasonForVisit, setReasonForVisit] = useState<string>("");
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [selectedLibraryId, setSelectedLibraryId] = useState<string>("");
  const [adminLibs, setAdminLibs] = useState([]);
  const [joinLibrary, setJoinLibrary] = useState<boolean>(false);
  const [addAdminToOrg, setAddAdminToOrg] = useState<boolean>(false);
  const [archive, setArchive] = useState(false);
  const [reassign, setReassign] = useState(false);
  const [newOwnerUserId, setNewOwnerUserId] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const {
    data: teacherLibraries,
    isLoading: isLoadingTeacherLibs,
  } = useLibraryApi(selectedTeacher.id);

  const { data: adminData } = useLibraryApi(selectedTeacher.id, "joined");

  const { data: joinLibData } = useJoinLibraryApi(
    selectedTeacher.id,
    selectedLibraryId,
    reasonForVisit,
    joinLibrary
  );
  const { isLoading: isLoadingAdminJoinedOrg } = useAddAdmintoOrgApi(
    adminUser.email,
    adminUser.orgId,
    addAdminToOrg
  );
  const { isDispatched } = useAddErrorMessage(showPermissionError);
  const {
    success: archiveSuccess,
    isLoading: isLoadingArchiveLibs,
  } = useArchiveLibraryApi(selectedTeacher.id, archive);

  const {
    success: reassignSuccess,
    isLoading: isLoadingReassignLibs,
  } = useReassignLibraryApi(
    selectedTeacher.id,
    newOwnerUserId,
    selectedAction,
    reassign
  );

  // admin joined libs
  useEffect(() => {
    if (adminData) {
      const adminLibs = getJoinedLibs(adminData);
      setAdminLibs(adminLibs);
    }
  }, [adminData]);

  //app token for visiting lib
  useEffect(() => {
    if (joinLibData.token) {
      // after joining the lib, add admin to the organisation
      setAddAdminToOrg(true);
    }
    setJoinLibrary(false);
  }, [joinLibData]);

  useEffect(() => {
    if (isDispatched) {
      setShowPermissionError(false);
    }
  }, [isDispatched]);

  // add admin to org and open link
  useEffect(() => {
    const openVisitLibraryLink = () => {
      const url = getVisitLibraryUrl(joinLibData.token, selectedLibraryId);
      window.open(url, "_blank");
    };

    if (
      !isLoadingAdminJoinedOrg &&
      joinLibData.token &&
      selectedLibraryId &&
      addAdminToOrg
    ) {
      openVisitLibraryLink();
      setAddAdminToOrg(false);
      //add the new lib to avoid being prompted the reasons more than once
      const isLibMember = adminLibs.find(l => l === selectedLibraryId);
      if (!isLibMember) {
        adminLibs.push(selectedLibraryId);
        setAdminLibs(adminLibs);
      }
    }
  }, [
    isLoadingAdminJoinedOrg,
    joinLibData.token,
    selectedLibraryId,
    addAdminToOrg,
    adminLibs,
  ]);

  // handle archive response
  useEffect(() => {
    if (archive && archiveSuccess && !isLoadingArchiveLibs && isProcessing) {
      setArchive(false);
      setIsProcessing(false);
      if (selectedTeacher.id !== adminUser.userId) {
        setShowManageLicenseModal(true);
      } else {
        onClose();
      }
    }
  }, [
    archive,
    archiveSuccess,
    isLoadingArchiveLibs,
    isProcessing,
    selectedTeacher,
    adminUser,
    onClose,
  ]);

  // handle reassign response
  useEffect(() => {
    if (reassign && reassignSuccess && !isLoadingReassignLibs && isProcessing) {
      setReassign(false);
      setIsProcessing(false);
      setNewOwnerUserId(null);
      if (selectedTeacher.id !== adminUser.userId) {
        setShowManageLicenseModal(true);
      } else {
        onClose();
      }
    }
  }, [
    reassign,
    reassignSuccess,
    isLoadingReassignLibs,
    isProcessing,
    selectedTeacher,
    adminUser,
    onClose,
  ]);

  const handleLibraryAction = () => {
    setShowAssignModal(true);
  };

  const handleCloseAssignModal = () => {
    setShowAssignModal(false);
  };

  const handleAssignToUser = userId => {
    setShowAssignModal(false);
    setIsProcessing(true);
    setNewOwnerUserId(userId);
    setReassign(true);
  };

  const handleArchiveLibs = () => {
    setShowAssignModal(false);
    setIsProcessing(true);
    setArchive(true);
  };

  const handleVisitLibrary = (libId: string) => {
    const parsedAdmin = admins;
    const isAdmin = parsedAdmin.some(
      a => a.email.toLowerCase() === adminUser.email.toLowerCase()
    );
    if (!isAdmin) {
      return setShowPermissionError(true);
    }
    setSelectedLibraryId(libId);
    const isLibMember = adminLibs.find(l => l === libId);
    if (isLibMember) {
      setJoinLibrary(true);
    } else {
      setShowReasonsModal(true);
    }
  };

  const onReasonModalClose = () => {
    setShowReasonsModal(false);
  };

  const onReasonModalSubmit = (reason: string) => {
    setReasonForVisit(reason);
    setShowReasonsModal(false);
    setJoinLibrary(true);
  };

  return (
    <>
      {(() => {
        if (isProcessing || isLoadingTeacherLibs) {
          return (
            <Modal
              title={isProcessing ? "Transfer in progress" : "Loading..."}
              width={540}
              hideX>
              <div style={{ height: "100px" }}>
                <Loader center small />
              </div>
            </Modal>
          );
        }
        if (showManageLicenseModal) {
          return <ManageLicense onClose={onClose} teacher={selectedTeacher} />;
        }
        if (showAssignModal) {
          const filteredTeachers = teachers.filter(
            t => t.id !== selectedTeacher.id
          );
          return (
            <AssignModal
              onClose={handleCloseAssignModal}
              onAssignToUser={handleAssignToUser}
              onArchiveLibs={handleArchiveLibs}
              selectedAction={selectedAction}
              teachers={filteredTeachers}
              admins={admins}
              containerClassName="library-management"
            />
          );
        }
        if (showReasonsModal) {
          return (
            <ReasonsForVisitModal
              onClose={onReasonModalClose}
              onSubmit={onReasonModalSubmit}
            />
          );
        }
        return (
          <Modal
            title="MANAGE TEACHER LIBRARIES"
            onClose={onClose}
            closeLabel="Cancel"
            submitDisabled={!selectedAction}
            onSubmit={handleLibraryAction}
            submitLabel="Next"
            width={540}>
            <div className="library-management">
              <p>
                You can keep the libraries intact and transfer books and
                students to a new library owner, or you can archive to close
                this Teacher's libraries and remove student access.
              </p>
              <TeacherLibraryDropdown
                teacher={selectedTeacher}
                libraries={teacherLibraries}
                onVisitLibraryClick={handleVisitLibrary}
              />
              <AssignActions
                onChange={setSelectedAction}
                selectedAction={selectedAction}
              />
            </div>
          </Modal>
        );
      })()}
    </>
  );
}
