import React, { Component } from "react";
import "./index.scss";

export default class BrandBar extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return <div className="brand-bar" />;
  }
}
