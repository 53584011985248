import {
  ADD_NOTIFICATION,
  ADD_ERROR_MESSAGE,
  REMOVE_NOTIFICATION,
} from "./action-types";

export function addErrorMessage(message) {
  return {
    type: ADD_ERROR_MESSAGE,
    payload: message,
  };
}

export function addNotification(notification) {
  return {
    type: ADD_NOTIFICATION,
    payload: notification,
  };
}

export function removeNotification(key) {
  return {
    type: REMOVE_NOTIFICATION,
    payload: key,
  };
}
