import React, { Component } from "react";
import Modal from "../../../modal";

export default class NotEnoughLicenses extends Component {
  render() {
    const { onCancel, bookCount, licenseCount } = this.props;

    return (
      <div>
        <Modal
          title={`Not enough ${bookCount} book licenses available`}
          body={`You have ${licenseCount} available, please purchase more licenses or select fewer teachers to assign.`}
          modal
          clickBackgroundToClose
          onCancel={onCancel}
          cancelText="Cancel"
        />
      </div>
    );
  }
}
