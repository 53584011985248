import React from "react";
import SvgIcon from "../../../ui/svg-icon";
import CertificationIcon from "../../../../../images/svg/certification.svg";
import CertificationOutlineIcon from "../../../../../images/svg/certification--outline.svg";
import dayjs from "dayjs";
import "./index.scss";

function TeacherCertification(props) {
  const { badges, certifying } = props;

  if (!certifying && !badges) {
    return null;
  }

  return (
    <div className="teacher-certification fs-exclude">
      <div className="teacher-certification__icon">
        {certifying && !badges ? (
          <SvgIcon icon={CertificationOutlineIcon} name="certifying" />
        ) : null}
        {badges ? (
          <span>
            {" "}
            <SvgIcon icon={CertificationIcon} name="certified" />
          </span>
        ) : null}
      </div>
      <div className="teacher-certification__content">
        <span className="teacher-certification__title">
          {badges ? (
            <span style={{ display: "block", fontSize: 12, color: "#6a7390" }}>
              Completed{" "}
              {dayjs(badges.calevel2 || badges.calevel1).format("MMMM Do YYYY")}
            </span>
          ) : null}
        </span>
        {certifying && !badges ? (
          <span className="teacher-certification__in-progress">
            (In progress)
          </span>
        ) : null}
        {certifying?.progress && !badges ? (
          <span className="teacher-certification__item-progress">
            {Math.floor(certifying.progress * 100)}%
          </span>
        ) : null}
      </div>
    </div>
  );
}

export default TeacherCertification;
