export const TIMES = {
  DAY: 0,
  WEEK: 1,
  MONTH: 2,
};

export const USER_TYPES = {
  ALL: 0,
  STUDENT: 1,
  TEACHER: 2,
};

export const TIME_FILTERS = {
  [TIMES.DAY]: "daily",
  [TIMES.WEEK]: "weekly",
  [TIMES.MONTH]: "monthly",
};

export const USER_FILTERS = {
  [USER_TYPES.ALL]: "total",
  [USER_TYPES.STUDENT]: "students",
  [USER_TYPES.TEACHER]: "teachers",
};

export const API_TIME_FILTERS = {
  [TIMES.DAY]: "d",
  [TIMES.WEEK]: "w",
  [TIMES.MONTH]: "m",
};

export const TIME_TOGGLE_OPTIONS = [
  {
    value: TIMES.DAY,
    label: "Last 30 days",
  },
  {
    value: TIMES.WEEK,
    label: "Weekly",
  },
  {
    value: TIMES.MONTH,
    label: "Monthly",
  },
];

export const USER_TOGGLE_OPTIONS = [
  {
    value: USER_TYPES.ALL,
    label: "All",
  },
  {
    value: USER_TYPES.STUDENT,
    label: "Students",
  },
  {
    value: USER_TYPES.TEACHER,
    label: "Teachers",
  },
];
