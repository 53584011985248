import { useEffect, useState } from "react";
import { ANALYTICS_DOMAIN } from "../../../../config";
import { API_TIME_FILTERS, TIMES, TIME_FILTERS } from "../../../pages/analytics/constants";
import { addErrorMessage } from "../../../../core/notifications/actions";
import { useDispatch } from "react-redux";
import useFetch from "use-http";
import dayjs from "dayjs";

interface CustomAnalyticsProps {
  organisationId: string;
  isCustomAnalytics: boolean;
  auth: {
    expiresAt: number;
    getAuth: (org: string) => null;
    urls: Map<string, any>;
  };
  timeFilter?: boolean;
  timeDimension: (typeof TIMES)[keyof typeof TIMES];
}

interface ApiHookResponse {
  data: string[];
  isLoading: boolean;
}

export const useCustomAnalyticsApi = (endpoint: string, props: CustomAnalyticsProps): ApiHookResponse => {
  const { isCustomAnalytics, auth, timeFilter, timeDimension, organisationId } = props;

  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const { get: getOrg, response: orgResponse } = useFetch(
    `${ANALYTICS_DOMAIN}/data/app/organisations/${organisationId}`,
  );

  const { get: getDomain } = useFetch(`${ANALYTICS_DOMAIN}/data/app/email-domains`);

  const dispatch = useDispatch();

  const pieChartDataMapper = (currentData, newData) => {
    const mappedValues = currentData.map(val => {
      const newValues = Object.keys(val).reduce((values, valKey) => {
        // name key doesnt need to be sumed
        if (valKey === "name") return { ...values, [valKey]: val.name };
        // find matching prop in item (could not exist)
        const currentProp = newData.find(k => k.name === val.name);
        const value = val[valKey] + (currentProp ? currentProp[valKey] : 0);
        return { ...values, [valKey]: value };
      }, {});
      return newValues;
    });
    return mappedValues;
  };

  useEffect(() => {
    if (isCustomAnalytics) {
      const { expiresAt, urls, getAuth } = auth;
      const orgUrl = urls?.get("organisation");
      const domainsUrl = urls?.get("domains");

      const resourceAuthExpired = expiresAt <= Date.now();
      const isAuthenticated = expiresAt && !resourceAuthExpired;

      const getData = async () => {
        try {
          setIsLoading(true);
          const timeParam = timeFilter ? `/${TIME_FILTERS[timeDimension]}` : "";
          let allData;
          if (domainsUrl) {
            allData = { data: [] };
            // get data for each domain
            const domainPromises = [];
            domainsUrl.forEach(async (queryParams, domain) => {
              domainPromises.push(getDomain(`${domain}/${endpoint}${timeParam}.json?${queryParams}`));
            });
            const domainData = await Promise.all(domainPromises);
            // filter out any 404 error. useFetch response is not reliable with parallel requests
            // errors response comes in a string xml format
            const filteredResult = domainData.filter(i => typeof i !== "string");

            if (filteredResult?.length) {
              //create map with all available dates to collate
              const dateMap = new Map();
              filteredResult.forEach(d =>
                d.data.forEach(item => {
                  if (dateMap.has(item.date)) {
                    const existingData = dateMap.get(item.date);
                    const newObj = Object.keys(existingData).reduce((total, key) => {
                      // date key doesn't need to be sumed
                      if (key === "date") return { ...total, [key]: item.date };
                      // map values for piechart
                      if (key === "values") {
                        const values = existingData[key];
                        const mappedValues = pieChartDataMapper(values, item.values);
                        return { ...total, [key]: mappedValues };
                      }
                      //sum data
                      const sumProp = existingData[key] + item[key];
                      return { ...total, [key]: sumProp };
                    }, {});
                    return dateMap.set(item.date, newObj);
                  }
                  return dateMap.set(item.date, item);
                }),
              );
              const collatedData = [];
              dateMap.forEach(val => collatedData.push(val));
              // extract and keep other matching props
              allData = { ...filteredResult[0], data: collatedData };
            }
            setData(allData);
          } else {
            allData = await getOrg(`${endpoint}${timeParam}.json?${orgUrl}`);
            if (orgResponse.ok) {
              setData(allData);
            }
          }
        } catch (error) {
          dispatch(addErrorMessage("Error fetching custom analytics"));
        }
        setIsLoading(false);
      };
      if (!isAuthenticated) {
        getAuth(organisationId);
      } else {
        getData();
      }
    }
  }, [
    dispatch,
    getOrg,
    orgResponse,
    getDomain,
    organisationId,
    endpoint,
    isCustomAnalytics,
    auth,
    timeDimension,
    timeFilter,
  ]);

  return { data, isLoading };
};

interface DbAnalyticsProps {
  organisationId: string;
  isDbAnalytics: boolean;
  auth: {
    expiresAt: number;
    getAuth: (org: string) => null;
    userUrls: Map<string, any>;
  };
  timeDimension: (typeof TIMES)[keyof typeof TIMES];
}

const thirtyDaysAgo = dayjs().subtract(30, "days");
const eighteenMonthsAgo = dayjs().subtract(18, "months");

export const useDbAnalyticsApi = (endpoint: string, props: DbAnalyticsProps): ApiHookResponse => {
  const { isDbAnalytics, timeDimension, organisationId, auth } = props;
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [requested, setRequested] = useState(false);

  const { get, response } = useFetch(`${ANALYTICS_DOMAIN}/users/organisations/${encodeURIComponent(organisationId)}`, {
    credentials: "include",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    setRequested(false);
  }, [timeDimension, setRequested]);

  useEffect(() => {
    if (isDbAnalytics) {
      const { expiresAt, userUrls, getAuth } = auth;

      const resourceAuthExpired = expiresAt <= Date.now();
      const isAuthenticated = expiresAt && !resourceAuthExpired;

      const getData = async () => {
        try {
          setIsLoading(true);
          setRequested(true);
          const startDate =
            timeDimension === TIMES.DAY ? thirtyDaysAgo.format("YYYYMMDD") : eighteenMonthsAgo.format("YYYYMMDD");
          const yesterday = dayjs().subtract(1, "days").format("YYYYMMDD");
          const data = await get(
            `${endpoint}?period=${API_TIME_FILTERS[timeDimension]}&from=${startDate}&to=${yesterday}&${userUrls}`,
          );
          if (response.ok) {
            setData(data);
          }
        } catch (error) {
          dispatch(addErrorMessage("Error fetching DB analytics"));
        }
        setIsLoading(false);
      };
      if (!isAuthenticated) {
        getAuth(organisationId);
      }
      if (!requested && isAuthenticated) {
        getData();
      }
    }
  }, [dispatch, get, organisationId, endpoint, isDbAnalytics, timeDimension, response, requested, auth]);

  return { data, isLoading };
};
