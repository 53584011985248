import {
  SET_RESOURCE_AUTH_EXPIRES,
  RESET_RESOURCE_AUTH_EXPIRES,
  SET_IS_LOADING_RESOURCE_AUTH_EXPIRES,
} from "./action-types";
import { ANALYTICS_DOMAIN } from "../../config";
import { getIsLoadingResource } from "./selectors";

export function setResourceAuth(orgId) {
  return async (dispatch, getState) => {
    if (!orgId) return;
    // each chart needs to check the expiry time on each request
    // avoid sending duplicate requests
    const isLoading = getIsLoadingResource(getState());
    if (isLoading) return;
    try {
      dispatch({
        type: SET_IS_LOADING_RESOURCE_AUTH_EXPIRES,
        payload: true,
      });
      // API sets access cookie and returns time to expiry in seconds
      let response = await fetch(
        `${ANALYTICS_DOMAIN}/sessions/app/organisations/${orgId}`,
        {
          credentials: "include",
        }
      ).then(res => res.json());
      const { refreshIn, usersOrganisation, ...signedURLs } = response;
      const expiryTime = Date.now() + refreshIn * 1000;
      dispatch({
        type: SET_RESOURCE_AUTH_EXPIRES,
        payload: { expiryTime, userSignedUrls: usersOrganisation, signedURLs },
      });
    } catch (err) {
      console.error(err);
    }
    dispatch({
      type: SET_IS_LOADING_RESOURCE_AUTH_EXPIRES,
      payload: false,
    });
  };
}

export function resetResourceAuth() {
  return {
    type: RESET_RESOURCE_AUTH_EXPIRES,
  };
}
