import React from "react";
import "./index.scss";

const EmptyState = props => {
  const { title, body } = props;
  return (
    <div className="empty-state">
      <h1 className="empty-state__title">{title}</h1>
      <p
        className="empty-state__body"
        dangerouslySetInnerHTML={{ __html: body }}
      />
    </div>
  );
};

export default EmptyState;
