import React, { Fragment } from "react";
import PropTypes from "prop-types";
import GroupTitle from "../group-title";
import CardGroup from "../../card-group";
import ColorCard from "../../card-group/color-card";

const ByGrade = props => {
  const { strings } = props;

  const cards = [
    {
      title: strings.kindergarten,
      link: "https://bookcreator.com/resources-for-teachers/kindergarten/",
      color: "red",
    },
    {
      title: strings.elementary,
      link: "https://bookcreator.com/resources-for-teachers/elementary/",
      color: "orange",
    },
    {
      title: strings.middleSchool,
      link: "https://bookcreator.com/resources-for-teachers/middle-school/",
      color: "yellow",
    },
    {
      title: strings.highSchool,
      link: "https://bookcreator.com/resources-for-teachers/high-school/",
      color: "green",
    },
  ];

  return (
    <Fragment>
      <GroupTitle>{strings.title}</GroupTitle>
      <CardGroup>
        {cards.map(card => (
          <ColorCard {...card} key={card.title} />
        ))}
      </CardGroup>
    </Fragment>
  );
};

ByGrade.propTypes = {
  strings: PropTypes.shape({
    elementary: PropTypes.string.isRequired,
    highSchool: PropTypes.string.isRequired,
    kindergarten: PropTypes.string.isRequired,
    middleSchool: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default ByGrade;
