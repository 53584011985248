import { createSelector } from "reselect";

export function getAdminsState(state) {
  return state.admins;
}

export function getAdmins(state) {
  const orgName = state.organisation.toJS().organisation?.name;
  return getAdminsState(state).admins.map(admin => admin.set("organisation", orgName));
}

export function getAdminsLoaded(state) {
  return getAdminsState(state).loaded;
}

export function getShowAdminImport(state) {
  return getAdminsState(state).showAdminImport;
}

export function getImportingAdmins(state) {
  return getAdminsState(state).importing;
}

export function getSortBy(state) {
  return getAdminsState(state).sortBy;
}

export function getSelectedAdmins(state) {
  return getAdminsState(state).selectedAdmins;
}

export const getConfirmations = createSelector([getAdminsState], adminsState => ({
  adminsToRemove: adminsState.adminsToRemove,
  removingAdmins: adminsState.removingAdmins,
}));
