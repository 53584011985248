import * as React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { createSelector } from "reselect";
import Loader from "src/views/components/ui/loader";
import Message from "src/views/components/ui/message";
import Modal from "src/views/components/modal";
import Header from "src/views/components/header";
import Content from "src/views/components/content";
import Switch from "src/views/components/forms/switch";
import { getOrganisationId } from "src/core/organisation";
import { getUserId } from "src/core/auth";
import { notificationsActions } from "src/core/notifications";
import "./index.scss";
import * as OrgsApi from "../../../core/organisation/orgs-api";

type ImageSearchType = "pixabay" | null;

interface Props {
  organisationId?: string;
  userId?: string;
  addErrorMessage(error: string): any;
}

interface State {
  loading?: boolean;
  error?: string;
  value?: ImageSearchType;
}

async function getSettings(organisationId: string, userId: string) {
  const response = await OrgsApi.getOrganisationSettings(
    organisationId,
    userId
  );
  if (!response.ok) {
    throw new Error("failed to load settings");
  }
  return response.data.settings;
}

async function setSettings(
  organisationId: string,
  settings: any,
  userId: string
) {
  const response = await OrgsApi.updateSettings(
    organisationId,
    settings,
    userId
  );
  if (!response.ok) {
    throw new Error("failed to update settings");
  }
}

function ImageSearchPage({ organisationId, userId, addErrorMessage }: Props) {
  const [state, setState] = React.useState<State>({ loading: true });
  const [showModal, setShowModal] = React.useState(false);
  const { loading, error, value: imageSearch } = state;

  React.useEffect(() => {
    const loadSettings = async () => {
      try {
        const settings = await getSettings(organisationId, userId);
        setState({
          loading: false,
          value: settings.imageSearch === "pixabay" ? "pixabay" : null,
        });
      } catch (err) {
        setState({
          error: "Settings could not be loaded, please refresh and try again",
        });
      }
    };
    if (organisationId) {
      loadSettings();
    }
  }, [organisationId, userId]);

  const setImageSearch = async (value: boolean) => {
    const oldValue = imageSearch;
    const newValue: ImageSearchType = value ? null : "pixabay";
    setState({ value: newValue });

    try {
      // ensure we have the latest value, in case our data is stale
      const settings = await getSettings(organisationId, userId);
      settings.imageSearch = newValue;
      await setSettings(organisationId, settings, userId);
    } catch (err) {
      addErrorMessage(
        "failed to update image search setting, please refresh and try again"
      );
      setState({ value: oldValue });
    }
  };

  const handleChange = async (value: boolean) => {
    if (value) {
      await setImageSearch(true);
    } else {
      setShowModal(true);
    }
  };

  // 'google search': on = null, off = 'pixabay'
  const imageSearchBool = imageSearch !== "pixabay";

  return (
    <div>
      <Header title="Image Search Settings" />
      <Helmet title="Image Search Settings" />
      <Content>
        <div className="image-search">
          {(() => {
            if (loading) {
              return <Loader center />;
            }

            if (error) {
              return <Message type="error">{error}</Message>;
            }

            return (
              <div className="table">
                <table className="image-search__settings">
                  <thead>
                    <tr>
                      <th colSpan={2}>Settings</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td width="99%">
                        <p>
                          <strong className="image-search__subtitle">
                            Allow Google Image Search
                          </strong>
                        </p>
                        <p>
                          Book Creator uses Google's Safe Search technology and
                          only queries a limited set of websites.{" "}
                          <a
                            href="https://intercom.help/bookcreator/en/articles/7873169-safe-image-searching"
                            rel="noreferrer"
                            target="_blank">
                            Learn more about our image search
                          </a>
                          .
                        </p>
                      </td>
                      <td>
                        <Switch
                          value={imageSearchBool}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            );
          })()}
        </div>
        {showModal && (
          <Modal
            title="Disable Google Image Search?"
            modal
            clickBackgroundToClose
            onConfirm={() => {
              setShowModal(false);
              setImageSearch(false);
            }}
            onCancel={() => setShowModal(false)}
            confirmText="Confirm"
            confirmButtonType="negative"
            cancelText="Cancel">
            This will restrict the image search to results from Pixabay - this
            is a moderated catalogue of safe images but with little coverage of
            historic or contemporary persons or events.
          </Modal>
        )}
      </Content>
    </div>
  );
}

const mapStateToProps = createSelector(
  getOrganisationId,
  getUserId,
  (organisationId, userId) => ({
    organisationId,
    userId,
  })
);

const mapDispatchToProps = {
  ...notificationsActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageSearchPage);
