import { useEffect, useState } from "react";
import { LIBRARIES_DOMAIN, PAYMENTS_DOMAIN } from "../../../../config";
import { addErrorMessage } from "src/core/notifications/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/core/store";
import useFetch, { CachePolicies } from "use-http";
import {
  LibResponseProps,
  List,
  LibProps,
  JoinLibResponseProps,
  AddAdminToOrgProps,
  BookCountResponseProps,
} from "./types";

interface ApiHookResponse {
  data: LibResponseProps;
  isLoading: boolean;
}

export const useLibraryApi = (teacherId: string): ApiHookResponse => {
  const [data, setData] = useState<LibResponseProps>({
    all: List<LibProps>(),
    archived: List<LibProps>(),
  });
  const [isLoading, setIsLoading] = useState(true);

  const { get, response } = useFetch(`${LIBRARIES_DOMAIN}/${teacherId}`, {
    credentials: "include",
    cachePolicy: CachePolicies.NO_CACHE,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const ownLibs = await get();
        if (response.ok) {
          const all = List(ownLibs.unarchived) as List<LibProps>;
          const archived = List(ownLibs.archived) as List<LibProps>;
          setData({ all, archived });
        } else {
          const errorMsg =
            response.status === 401
              ? "Not authorised to access the requested user"
              : "Error fetching teacher libraries";
          dispatch(addErrorMessage(errorMsg));
        }
      } catch (error) {
        dispatch(addErrorMessage("Error fetching teacher libraries"));
      }
      setIsLoading(false);
    };
    getData();
  }, [dispatch, get, teacherId, response]);

  return { data, isLoading };
};

interface BookCountHookResponse {
  data: BookCountResponseProps;
  isLoading: boolean;
}

export const useUserBookCountApi = (
  teacherId: string,
  orgId: string
): BookCountHookResponse => {
  const [data, setData] = useState<BookCountResponseProps>({
    owned: 0,
    private: 0,
    total: 0,
  });
  const [isLoading, setIsLoading] = useState(true);

  const { get, response } = useFetch(
    `${PAYMENTS_DOMAIN}/v1/admin/organisations/${orgId}/members/${teacherId}/book-counts`,
    {
      credentials: "include",
    }
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const ownLibs = await get();
        if (response.ok) {
          setData({ ...ownLibs });
        } else {
          const errorMsg =
            response.status === 401
              ? "Not authorised to access the requested user"
              : "Error fetching teacher book count";
          dispatch(addErrorMessage(errorMsg));
        }
      } catch (error) {
        dispatch(addErrorMessage("Error fetching teacher book count"));
      }
      setIsLoading(false);
    };
    getData();
  }, [dispatch, get, teacherId, orgId, response]);

  return { data, isLoading };
};

interface JoinLibApiHookResponse {
  data: JoinLibResponseProps;
  isLoading: boolean;
  error: boolean;
}

export const useJoinLibraryApi = (
  teacherId: string,
  libraryId: string,
  reason: string,
  joinLibrary: boolean
): JoinLibApiHookResponse => {
  const [data, setData] = useState<JoinLibResponseProps>({
    token: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  const { post, response } = useFetch(
    `${LIBRARIES_DOMAIN}/${teacherId}/admins/${libraryId}/join`,
    {
      credentials: "include",
      cachePolicy: CachePolicies.NO_CACHE,
    }
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        setError(false);
        const joinLib = await post({ reason });
        if (response.ok) {
          const { token } = joinLib;
          setData({ token });
        } else {
          const errorMsg =
            response.status === 403
              ? `You don't have permissions to visit this library`
              : "Error joining teacher libraries";
          dispatch(addErrorMessage(errorMsg));
          setError(true);
        }
      } catch (error) {
        dispatch(addErrorMessage("Error joining teacher libraries"));
        setError(true);
      }
      setIsLoading(false);
    };
    if (libraryId && joinLibrary) {
      getData();
    }
  }, [dispatch, post, teacherId, libraryId, reason, joinLibrary, response]);

  return { data, isLoading, error };
};

interface AddAdminToOrgHookResponse {
  data: AddAdminToOrgProps;
  isLoading: boolean;
}

export const useAddAdmintoOrgApi = (
  adminEmail: string,
  orgId: string,
  addAdminToOrg: boolean
): AddAdminToOrgHookResponse => {
  const organisation = useSelector(
    (state: RootState) => state.organisation.organisation
  );
  const orgLicenses = organisation?.get("licenses");
  const licenses = orgLicenses?.toJS() || [];
  // TODO: update this check if needed for domain ones
  const isDomainLicense =
    licenses?.length && licenses.every(license => license.applyToAll);
  const currentLicense = licenses[0];

  const [data, setData] = useState<AddAdminToOrgProps>({
    allMembers: [],
  });
  const [isLoading, setIsLoading] = useState(true);

  const { post, response } = useFetch(
    `${PAYMENTS_DOMAIN}/v1/admin/organisations/${orgId}/members/import/teachers`,
    {
      credentials: "include",
    }
  );
  const { put, response: licenseResponse } = useFetch(
    `${PAYMENTS_DOMAIN}/v1/admin/organisations/${orgId}/licenses/${currentLicense?.id}`,
    {
      credentials: "include",
    }
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const addedAdminToOrg = await post([adminEmail]);
        if (response.ok) {
          const { newMembers, existingMembers } = addedAdminToOrg;
          const teachersToLicense = [...newMembers, ...existingMembers].map(
            m => m.id
          );
          setData({ allMembers: teachersToLicense });
          await put(teachersToLicense);
        }
      } catch (error) {
        dispatch(addErrorMessage("Error adding admin to org"));
      }
      setIsLoading(false);
    };

    if (orgId && adminEmail && addAdminToOrg) {
      if (isDomainLicense) {
        getData();
      } else {
        setIsLoading(false);
      }
    }
  }, [
    dispatch,
    post,
    orgId,
    adminEmail,
    addAdminToOrg,
    put,
    licenseResponse,
    isDomainLicense,
    response,
  ]);

  return { data, isLoading };
};

export const useAddErrorMessage = show => {
  const dispatch = useDispatch();
  const [isDispatched, setIsDispatched] = useState(false);

  useEffect(() => {
    setIsDispatched(false);

    if (show) {
      dispatch(
        addErrorMessage(
          "For security reasons, you do not have access to visit teacher libraries"
        )
      );
      setIsDispatched(true);
    }
  }, [show, dispatch]);

  return { isDispatched };
};
