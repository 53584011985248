import React, { Component } from "react";
import TextInput from "../../../../forms/text-box";
import Modal from "../../../../modal";
import Button from "../../../../button";
import "./index.scss";

export default class ComposeEmail extends Component {
  get isValid() {
    const { subject, message } = this.props;
    return subject.length && message.length;
  }

  handleSubmit = e => {
    this.props.onSubmit();
    e.preventDefault();
  };

  render() {
    const {
      onClose,
      subject,
      message,
      onMessageChange,
      transition,
    } = this.props;

    const subjectError = !subject.length ? "Please include a subject" : null;
    const messageError = !message.length ? "Please include a message" : null;

    return (
      <div>
        <Modal
          title="Review message details"
          modal
          width={646}
          align="center"
          showX
          hideFooter
          notransition={!transition}
          onCancel={onClose}
          cancelText="Cancel">
          <form onSubmit={this.handleSubmit}>
            <TextInput
              label="Subject line"
              value={subject}
              shaded
              error={subjectError}
              disabled
            />
            <TextInput
              label="Message"
              value={message}
              shaded
              multiline
              error={messageError}
              onChange={onMessageChange}
            />
            <Button disabled={!this.isValid} minWidth={158} type="submit">
              Next
            </Button>
          </form>
        </Modal>
      </div>
    );
  }
}
