import React, { useState } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import {
  teacherActions,
  getSelectedTeachersWithData,
} from "../../../../../core/teachers";
import Modal from "../../../modal";
import Loader from "../../../ui/loader";
import MultipleDowngrade from "./multiple-downgrade";

function DowngradeUserConfirmation(props) {
  const [downgradingMultiple, setDowngradingMultiple] = useState(false);
  const [
    downgradingMultipleComplete,
    setDowngradingMultipleComplete,
  ] = useState(false);

  const {
    usersToDowngrade,
    setUsersToDowngrade,
    downgradingUsers,
    onDowngradeSuccess,
    selectedTeachers,
  } = props;

  const multipleDowngrade = usersToDowngrade.size > 1;
  const title = multipleDowngrade
    ? `Change ${usersToDowngrade.size} accounts to students?`
    : "Change to a student account";

  const handleDowngradeMultipleComplete = () => {
    setDowngradingMultipleComplete(true);
  };

  return (
    <div>
      {downgradingUsers ? (
        <Modal
          title={`Changing to student accounts`}
          align="center"
          bodyRenderer={() => {
            return (
              <div style={{ height: 100, position: "relative" }}>
                <Loader center />
              </div>
            );
          }}
          modal
          hideFooter
        />
      ) : (
        <Modal
          title={title}
          modal
          clickBackgroundToClose={
            !downgradingMultiple || downgradingMultipleComplete
          }
          hideFooter={downgradingMultiple && !downgradingMultipleComplete}
          onConfirm={
            downgradingMultipleComplete
              ? null
              : () => setDowngradingMultiple(true)
          }
          confirmText="Confirm"
          confirmButtonType="negative"
          onCancel={
            !downgradingMultiple || downgradingMultipleComplete
              ? e => setUsersToDowngrade(null)
              : null
          }
          cancelText={downgradingMultipleComplete ? "Done" : "Cancel"}>
          <MultipleDowngrade
            selectedTeachers={selectedTeachers}
            onComplete={handleDowngradeMultipleComplete}
            onDowngradeSuccess={onDowngradeSuccess}
            ready={downgradingMultiple}
          />
        </Modal>
      )}
      }
    </div>
  );
}

const mapStateToProps = createSelector(
  getSelectedTeachersWithData,
  selectedTeachers => ({
    selectedTeachers,
  })
);

const mapDispatchToProps = {
  ...teacherActions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DowngradeUserConfirmation);
