import React, { Component } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import {
  getHasDomainLicense,
  getIsApplyToAllLicense,
  getOrganisationLoaded,
  getLicenseInfo,
  organisationActions,
  getOrganisation,
  getOrganisationCount,
  getLicensesLoading,
  getLicenseError,
  getShowOrgPicker,
} from "../../../core/organisation";
import Header, { HeaderActions } from "../../components/header";
import HeaderButton from "../../components/button/header";
import ButtonPrompt from "../../components/button/prompt";
import Content from "../../components/content";
import LicenseTable from "../../components/licenses/table";
import OrganisationName from "../../components/licenses/organisation-name";
import { Link } from "react-router-dom";
import Loader from "../../components/ui/loader";
import OrgPickerModal from "../../components/sign-in/organisation-picker/modal/container";
import Message from "../../components/ui/message";
import "./index.scss";

class Licenses extends Component {
  render() {
    const {
      hasDomainLicense,
      isApplyToAllLicense,
      organisationLoaded,
      licenseInfo,
      organisation,
      updateOrganisationName,
      organisationCount,
      licensesLoading,
      licenseError,
      setShowOrgPicker,
      showOrgPicker,
    } = this.props;

    return (
      <div>
        <Helmet title="License dashboard" />
        <Header title="Licenses">
          <HeaderActions right>
            <ButtonPrompt>
              <strong>Need more licenses?</strong>
              <br />
              Contact us for a quote
            </ButtonPrompt>
            <HeaderButton href="mailto:sales@bookcreator.com">Contact us</HeaderButton>
          </HeaderActions>
        </Header>
        <Content>
          {organisationLoaded && organisation ? (
            <OrganisationName
              name={organisation.get("name")}
              setShowOrgPicker={setShowOrgPicker}
              organisationCount={organisationCount}
              onBlur={updateOrganisationName}
            />
          ) : null}
          {organisationLoaded && licenseInfo ? (
            <div className="licenses">
              <LicenseTable
                licenses={licenseInfo}
                hasDomainLicense={hasDomainLicense}
                isApplyToAllLicense={isApplyToAllLicense}
              />
              {licenseInfo.size ? (
                <p className="licenses__footer">
                  You can assign and manage licenses on the{" "}
                  <Link to={`/${organisation.get("id")}/teachers`}>Teachers</Link> page, as well as add new teachers to
                  your organisation.
                </p>
              ) : null}
            </div>
          ) : null}
          {licensesLoading ? <Loader colour="white" center /> : null}
          {licenseError ? (
            <Message type="error" center>
              Something went wrong loading the licenses
            </Message>
          ) : null}
        </Content>
        {showOrgPicker ? <OrgPickerModal /> : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  hasDomainLicense: getHasDomainLicense(state),
  isApplyToAllLicense: getIsApplyToAllLicense(state),
  organisationLoaded: getOrganisationLoaded(state),
  licenseInfo: getLicenseInfo(state),
  organisation: getOrganisation(state),
  organisationCount: getOrganisationCount(state),
  licensesLoading: getLicensesLoading(state),
  licenseError: getLicenseError(state),
  showOrgPicker: getShowOrgPicker(state),
});

const mapDispatchToProps = {
  ...organisationActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Licenses);
