import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";
import {
  getEmailError as emailErrorValidator,
  getPasswordError,
  getFullNameError,
} from "../../../utils/validators";
import { TERMS_OF_SERVICE_URL } from "../../../constants";
import Form from "../forms/form";
import FormButton from "../forms/form-button";
import TextBox from "../forms/text-box";
import Message from "../ui/message";

const Register = props => {
  const { email } = useParams();
  const [emailAddress, setEmailAddress] = useState(email ? email : "");
  const [fullName, setFullName] = useState("");
  const [password, setPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const { error, clearAuthError, createUserWithEmailAndPassword } = props;

  useEffect(() => {
    return () => {
      clearAuthError();
    };
  }, [clearAuthError]);

  const getEmailError = email => {
    if (
      email.trim().endsWith("@lausd.net") ||
      email.trim().endsWith("@mymail.lausd.net")
    ) {
      return "Please sign in using your district SSO such as Clever or Schoology";
    }
    return emailErrorValidator(email);
  };

  const isValid = () => {
    return (
      !getEmailError(emailAddress) &&
      !getPasswordError(password) &&
      !getFullNameError(fullName)
    );
  };

  const handleSubmit = e => {
    setSubmitted(true);

    if (isValid()) {
      setSubmitting(true);

      createUserWithEmailAndPassword({
        email: emailAddress,
        displayName: fullName,
        password: password,
      });
    }
    e.preventDefault();
  };

  const emailError = submitted && getEmailError(emailAddress);
  const fullNameError = submitted && getFullNameError(fullName);
  const passwordError = submitted && getPasswordError(password);

  const accountExists = error && error.code === "auth/email-already-in-use";

  return (
    <Form header="Create account" onSubmit={handleSubmit} autocomplete={false}>
      <Helmet title="Register an account to use Book Creator" />
      <div className="form__content">
        <TextBox
          label="Email"
          type="email"
          onChange={setEmailAddress}
          value={emailAddress}
          autoFocus={!email}
          error={emailError}
        />

        <TextBox
          label="First and last name"
          onChange={setFullName}
          value={fullName}
          autoFocus={email}
          error={fullNameError}
        />

        <TextBox
          label="Password"
          onChange={setPassword}
          type="password"
          value={password}
          error={passwordError}
        />
      </div>
      <div className="form__actions">
        <small className="form__actions-smallprint">
          <span>
            By submitting this form, you are indicating that you agree to the{" "}
          </span>
          <a
            href={TERMS_OF_SERVICE_URL}
            // don't set rel=noreferrer as we own the target and the referrer will be useful in analytics
            // eslint-disable-next-line
            target="_blank">
            Terms of Service
          </a>
          .
        </small>
        {error ? (
          <Message type="error" center>
            {error.message}
          </Message>
        ) : null}
        <FormButton flat href="/sign-in" text="Cancel" />
        {accountExists ? (
          <FormButton flat href="/sign-in/email" text="Sign in" />
        ) : null}
        <FormButton
          type="submit"
          text="Submit"
          loading={submitting && !error}
        />
      </div>
    </Form>
  );
};

export default Register;
