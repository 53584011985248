import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classNames from "classnames";
import "./index.scss";

export default function NavChildItem(props) {
  const { active, children, href, to, disabled } = props;

  const content = <span className="nav__child-item-text">{children}</span>;

  const cssClass = classNames("nav__child-item", {
    "nav__child-item--active": active,
  });

  const sharedProps = {
    className: cssClass,
    disabled,
  };

  if (to) {
    return (
      <Link to={to} {...sharedProps}>
        {content}
      </Link>
    );
  } else {
    return (
      <a href={href} rel="noreferrer" target="_blank" {...sharedProps}>
        {content}
      </a>
    );
  }
}

NavChildItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.string,
  href: PropTypes.string,
  to: PropTypes.string,
  disabled: PropTypes.bool,
};
