import React, { Component } from "react";

export default class DiscoverCover extends Component {
  state = {
    retries: 0,
    imageFailed: false,
    imageLoaded: false,
  };

  get url() {
    const { uid, bookId } = this.props;
    const { retries } = this.state;
    return `https://assets.api.bookcreator.com/${uid}/books/${bookId}/cover?width=250${
      retries ? `&r=${retries}` : ""
    }`;
  }

  handleError = () => {
    const { retries } = this.state;
    if (retries < 1) {
      setTimeout(this.retry, 2000);
    } else {
      this.setState({
        imageFailed: true,
      });
    }
  };

  handleLoad = () => {
    this.setState({
      imageFailed: false,
      imageLoaded: true,
    });
  };

  retry = () => {
    const { retries } = this.state;
    this.setState({
      retries: retries + 1,
    });
  };

  render() {
    const { bookId, libraryId, shape, title } = this.props;

    const { imageFailed, imageLoaded } = this.state;

    return (
      <a
        href={`https://read.bookcreator.com/library/${libraryId}/book/${bookId}`}
        className={`discover__book discover__book--${shape}`}
        onError={this.handleError}
        onLoad={this.handleLoad}
        title={`${title} (opens in a new window)`}
        // don't set rel=noreferrer as we own the target and the referrer will be useful in analytics
        // eslint-disable-next-line
        target="_blank">
        {imageFailed ? (
          <div className="discover__book__fallback">{title}</div>
        ) : (
          <img
            src={this.url}
            alt={title}
            style={{ opacity: imageLoaded ? 1 : 0 }}
          />
        )}
      </a>
    );
  }
}
