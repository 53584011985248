import React, { Component } from "react";
import classNames from "classnames";
import Menu from "../../../../common/components/Menu";
import SvgIcon from "../../ui/svg-icon";
import MoreOptionsIcon from "../../../../images/svg/more-options.svg";
import "./index.scss";

export default class MoreOptionsMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      timeout: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleToggleClick = evt => {
    const { onToggleClick } = this.props;
    this.setState({
      open: !this.state.open,
    });
    if (onToggleClick) {
      onToggleClick(evt);
    }
    evt.stopPropagation();
    evt.preventDefault();
  };

  handleMouseLeave = () => {
    if (this.state.open && !this.timeout) {
      this.setState({
        timeout: window.setTimeout(this.closeMenu, 2000),
      });
    }
  };

  handleMouseEnter = () => {
    window.clearTimeout(this.state.timeout);
    this.setState({
      timeout: null,
    });
  };

  closeMenu = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
    if (this._isMounted) {
      this.setState({
        open: false,
      });
    }
  };

  getPosition = () => {
    return {
      x: this._container.offsetLeft,
      y: this._container.offsetTop,
    };
  };

  render() {
    const { children, width, compact, textonly } = this.props;

    const { open } = this.state;

    const containerClass = classNames("more-options", "more-options--parent", {
      "more-options--active": open,
    });

    return (
      <div
        className={containerClass}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        ref={c => (this._container = c)}>
        <button
          onClick={this.handleToggleClick}
          className="more-options__toggle">
          <SvgIcon icon={MoreOptionsIcon} name="more-options" />
        </button>
        {open && children ? (
          <Menu
            position="sw"
            inside
            compact={compact}
            textonly={textonly}
            width={width || 150}
            onClose={this.closeMenu}
            onItemClick={this.closeMenu}>
            {children}
          </Menu>
        ) : null}
      </div>
    );
  }
}
