import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { getEmailError } from "../../../utils/validators";
import { firebaseAuth, sendPasswordResetEmail } from "../../../core/firebase";
import { useParams } from "react-router-dom";
import Form from "../../components/forms/form";
import FormButton from "../../components/forms/form-button";
import TextBox from "../../components/forms/text-box";

const ForgottenPassword = props => {
  const { email } = useParams();
  const [emailAddress, setEmailAddress] = useState(email ? email : "");
  const [emailSent, setEmailSent] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    return () => {
      props.clearAuthError();
    };
  }, [props]);

  const isValid = () => {
    return !getEmailError(emailAddress);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setSubmitted(true);
    if (isValid()) {
      setSubmitting(true);
      sendResetEmail();
    }
  };

  const sendResetEmail = () => {
    sendPasswordResetEmail(firebaseAuth, emailAddress)
      .then(() => {
        setEmailSent(true);
        setSubmitting(false);
      })
      .catch(error => {
        setSubmitting(false);
        console.log(error);
      });
  };

  const emailError = submitted && getEmailError(emailAddress);

  return (
    <Form header="Recover password" onSubmit={handleSubmit}>
      <Helmet title="Recover password" />
      {emailSent ? (
        <div className="form__content">
          <p>{`We have sent an email to ${emailAddress} with instructions to reset your password.`}</p>
        </div>
      ) : (
        <div className="form__content">
          <p>
            Get instructions sent to this email that explain how to reset your
            password
          </p>
          <TextBox
            label="Email"
            type="email"
            onChange={setEmailAddress}
            value={emailAddress}
            autoFocus
            error={emailError}
          />
        </div>
      )}
      {emailSent ? (
        <div className="form__actions">
          <FormButton href="/sign-in" text="Sign in" />
        </div>
      ) : (
        <div className="form__actions">
          <FormButton flat href="/sign-in" text="Cancel" />
          <FormButton type="submit" text="Send" loading={submitting} />
        </div>
      )}
    </Form>
  );
};

export default ForgottenPassword;
