import { Record, fromJS } from "immutable";
import { SIGN_OUT_SUCCESS } from "../auth";

import {
  RECEIVE_LMS_DATA,
  SET_LMS_LOADED,
  SET_LMS_GENERATING,
  SET_REVOKE_CONFIRMATION_VISIBILITY,
} from "./action-types";

export const LMSState = new Record({
  loaded: false,
  generating: false,
  confirmations: {
    revoke: false,
  },
  configuration: fromJS({
    consumerKey: null,
    consumerSecret: null,
  }),
});

export function lmsReducer(state = new LMSState(), { payload, type }) {
  switch (type) {
    case SET_LMS_LOADED:
      return state.merge({
        loaded: payload,
      });
    case RECEIVE_LMS_DATA:
      return state.merge({
        configuration: payload && payload.toJS ? payload : fromJS(payload),
      });
    case SET_LMS_GENERATING:
      return state.merge({
        generating: payload,
      });
    case SET_REVOKE_CONFIRMATION_VISIBILITY:
      return state.merge({
        confirmations: {
          ...state.confirmations,
          revoke: payload,
        },
      });
    case SIGN_OUT_SUCCESS:
      let nextState = new LMSState();
      return nextState;
    default:
      return state;
  }
}
