import React, { Component } from "react";
import ByGrade from "./by-grade";
import BySubject from "./by-subject";
import Discover from "./discover";
import Promos from "./promos";

const strings = {
  resourcesByGrade: {
    elementary: "Elementary / Primary",
    highSchool: "High School / Secondary",
    kindergarten: "Pre-Kindergarten / Kindergarten",
    middleSchool: "Middle School",
    title: "Resources by grade",
  },
  resourcesBySubject: {
    creativeArts: "Creative Arts",
    english: "English Language / Arts",
    languages: "Languages",
    math: "Math",
    pe: "P.E / Health",
    science: "Science",
    socialStudies: "Social Studies",
    specialEducation: "Special Education",
    title: "Resources by subject",
  },
  resourceBooks: {
    title: "Supercharge your Book Creator lessons",
    intro:
      "Experienced educators share their tips, ideas and resources to help you make the most of using Book Creator with your students.",
  },
  promos: {
    promo1: {
      title: "Join the Facebook group",
      body:
        "We have a dedicated community of teachers using Book Creator in our Facebook Teachers group. Join in and ask questions, give answers and get inspiration from each other.",
      buttonText: "Join the discussion",
    },
    promo2: {
      title: "PD presentation template",
      body:
        "We've put together a modular presentation with speaker notes that you can adapt for your needs. The presentation is available as a Google Slides template that you can customize.",
      buttonText: "Get your copy here",
    },
    promo3: {
      title: "Getting started with Chrome",
      body:
        "We've put together a dedicated guide for newcomers to our web app. Use this if you are a new teacher, administrator or tech coach using Book Creator for the first time.",
      buttonText: "Learn more",
    },
  },
};

export default class TeacherResources extends Component {
  render() {
    return (
      <div className="teacher-resources">
        <ByGrade strings={strings.resourcesByGrade} />
        <BySubject strings={strings.resourcesBySubject} />
        <Discover strings={strings.resourceBooks} />
        <Promos strings={strings.promos} />
      </div>
    );
  }
}
