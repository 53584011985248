const apps = [
  {
    id: "animatedemojis",
    name: "Animated Emojis",
    premium: true,
    goodToKnow: 3,
    strings: {
      summary: "Bring your books to life with animated emojis.",
      detail:
        "All of your favourite emojis brought to life. Great for student feedback or just for fun!",
      goodtoknow1: "No age limit.",
      goodtoknow2: "No separate account required.",
      goodtoknow3: "All images and search terms stay on Book Creator servers.",
    },
  },
  {
    id: "bitmoji",
    name: "Bitmoji",
    teacherOnly: true,
    termsURL: "https://snap.com/terms",
    privacyPolicyURL: "https://snap.com/privacy",
    goodToKnow: 3,
    beAware: 3,
    strings: {
      goodtoknow1: "Bitmoji is not available for student accounts.",
      goodtoknow2:
        "Book Creator anonymises the loading of images from Bitmoji servers in order to protect student and teacher privacy.",
      goodtoknow3:
        "Once a Bitmoji avatar has been added to a book the avatars are always loaded from Book Creator servers.",
      beaware1: "No under 13s.",
      beaware2:
        "A separate Snap account is required to use Bitmoji avatars. This requires the user to accept Snap’s terms and conditions.",
      beaware3:
        "Search terms entered into the Bitmoji search box are visible to Bitmoji so be careful not to enter any personal information.",
    },
  },

  {
    id: "canva",
    name: "Canva",
    termsURL: "https://www.canva.com/policies/terms-of-use/",
    privacyPolicyURL: "https://www.canva.com/policies/privacy-policy/",
    goodToKnow: 2,
    beAware: 3,
    strings: {
      goodtoknow1: "No age limit.",
      goodtoknow2:
        "Once a Canva design has been added to a book the design is always loaded from Book Creator servers.",
      beaware1: "Under 13s only with consent from parent/guardian.",
      beaware2:
        "A separate Canva account is required to use the Canva app. This requires the user to accept the Canva terms and conditions.",
      beaware3:
        "All information entered in the Canva screen is visible to Canva and subject to the Canva terms and conditions.",
    },
  },
  {
    id: "giphy",
    name: "Giphy",
    termsURL: "http://giphy.com/terms",
    privacyPolicyURL: "http://giphy.com/privacy",
    goodToKnow: 2,
    beAware: 2,
    strings: {
      goodtoknow1: "No separate account required.",
      goodtoknow2:
        "Book Creator anonymises the loading of images from Giphy servers in order to protect student and teacher privacy.",
      beaware1:
        "No under 13s. No under 16s who are citizens of the European Union, United Kingdom, Lichtenstein, Norway, or Iceland.",
      beaware2:
        "Search terms entered into the Giphy search box are visible to Giphy so be careful not to enter any personal information.",
    },
  },
  {
    id: "googledrive",
    name: "Google Drive",
    termsURL: "https://www.google.com/drive/terms-of-service/",
    privacyPolicyURL: "https://www.google.com/policies/privacy/",
    goodToKnow: 1,
    beAware: 2,
    strings: {
      goodtoknow1: "No age limit.",
      beaware1:
        "A separate Google account is required to use Google Drive. This requires the user to accept the Google terms and conditions.",
      beaware2:
        "All information entered in the Google Drive screen is visible to Google and subject to the Google terms and conditions.",
    },
  },
  {
    id: "googlemaps",
    name: "Google Maps",
    premium: true,
    termsURL: "https://maps.google.com/help/terms_maps/",
    privacyPolicyURL: "https://www.google.com/policies/privacy/",
    goodToKnow: 3,
    strings: {
      goodtoknow1: "No age limit.",
      goodtoknow2: "No separate account is required.",
      goodtoknow3:
        "Book Creator anonymises the loading of maps from Google servers in order to protect student and teacher privacy.",
    },
  },
  {
    id: "sketchfab",
    name: "3D Models",
    premium: true,
    termsURL: "https://sketchfab.com/terms",
    privacyPolicyURL: "https://sketchfab.com/privacy",
    goodToKnow: 2,
    beAware: 3,
    strings: {
      goodtoknow1: "No separate account required.",
      goodtoknow2:
        "Book Creator anonymises the loading of thumbnails from Sketchfab servers in order to protect student and teacher privacy.",
      beaware1:
        "No under 16s. Under 18s only with consent from parent/guardian.",
      beaware2:
        "Search terms entered into the Sketchfab search box are visible to Sketchfab so be careful not to enter any personal information.",
      beaware3:
        "After a user clicks on a thumbnail to load the 3D model, all interaction is via Sketchfab servers and subject to usage tracking.",
    },
  },
  {
    id: "teacherstickers",
    name: "Teacher Stickers",
    premium: true,
    goodToKnow: 3,
    strings: {
      summary:
        "Give feedback and encouragement with these fun digital stickers.",
      detail:
        "Give your students feedback and encouragement with these fun digital stickers created by <a href='https://www.teacherspayteachers.com/Store/Kim-Miller-24' target='_blank' rel='noopener'>Kim Miller from A Love of Teaching</a>. The sticker pack also includes seasonal and holiday collections.",
      goodtoknow1: "No age limit.",
      goodtoknow2: "No separate account required.",
      goodtoknow3: "All images and search terms stay on Book Creator servers.",
    },
  },
  {
    id: "graphicorganizers",
    name: "Graphic Organizers",
    premium: true,
    goodToKnow: 3,
    strings: {
      summary:
        "Help your students organize their thoughts with over 100 templates.",
      goodtoknow1: "No age limit.",
      goodtoknow2: "No separate account required.",
      goodtoknow3: "All images and search terms stay on Book Creator servers.",
    },
  },
];

export default apps;
