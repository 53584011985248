import React from "react";
import PropTypes from "prop-types";
import Promo from "./promo";
import FacebookImage from "../../../../images/teacher-resources/promos/facebook.png";
import PresentationImage from "../../../../images/teacher-resources/promos/presentation.png";
import ChromeImage from "../../../../images/teacher-resources/promos/chrome-blue.png";
import "./index.scss";

const Promos = props => {
  const { promo1, promo2, promo3 } = props.strings;

  const promos = [
    {
      title: promo1.title,
      body: promo1.body,
      link: "https://www.facebook.com/groups/bookcreator/",
      image: FacebookImage,
      imageClass: "facebook",
      buttonText: promo1.buttonText,
    },
    {
      title: promo2.title,
      body: promo2.body,
      link:
        "https://docs.google.com/presentation/d/1yoJ4ng3EGLOZxfXo3nVGyQd5HG7ERjvannCcvnuMTRo/edit?usp=sharing",
      image: PresentationImage,
      imageClass: "presentation",
      buttonText: promo2.buttonText,
    },
    {
      title: promo3.title,
      body: promo3.body,
      link: "https://bookcreator.com/chrome/getting-started/",
      image: ChromeImage,
      imageClass: "chrome",
      buttonText: promo3.buttonText,
    },
  ];

  return (
    <div className="promos">
      {promos.map(promo => (
        <Promo {...promo} key={promo.title} />
      ))}
    </div>
  );
};

Promos.propTypes = {
  strings: PropTypes.shape({
    promo1: PropTypes.shape({
      body: PropTypes.string.isRequired,
      buttonText: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
    promo2: PropTypes.shape({
      body: PropTypes.string.isRequired,
      buttonText: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
    promo3: PropTypes.shape({
      body: PropTypes.string.isRequired,
      buttonText: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Promos;
