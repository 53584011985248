import React, { useEffect, useState, useRef, useCallback } from "react";
import Button from "../../../../common/components/Button";
import "./index.scss";

export default function MultilineImportInput(props) {
  const { initialValue, intro, onSubmit } = props;

  const [value, setValue] = useState(initialValue);

  const inputRef = useRef();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  const handleSubmit = useCallback(
    evt => {
      onSubmit(value);
      evt?.preventDefault();
    },
    [onSubmit, value]
  );

  const handleChange = evt => {
    setValue(evt.target.value.toLowerCase());
  };

  useEffect(() => {
    const handleKeyDown = evt => {
      const { key, metaKey, ctrlKey } = evt;
      if (key === "Enter" && (metaKey || ctrlKey)) {
        handleSubmit();
      }
    };
    if (inputRef.current) {
      inputRef.current.addEventListener("keydown", handleKeyDown);
    }
  }, [inputRef, handleSubmit]);

  return (
    <form className="multiline-import-input" onSubmit={handleSubmit}>
      {intro ? <p className="multiline-import-input__intro">{intro}</p> : null}
      <textarea
        className="multiline-import-input__input fs-mask"
        ref={inputRef}
        onChange={handleChange}
        value={value}
      />
      <Button
        minWidth={158}
        htmlType="submit"
        type="tertiary"
        disabled={!value || value.trim().length === 0}>
        Next
      </Button>
    </form>
  );
}
