import React, { Component } from "react";
import "./inline-error.scss";

class InlineError extends Component {
  render() {
    const { message, children } = this.props;

    return (
      <span className="inline-error">
        {message}
        {children}
      </span>
    );
  }
}

export default InlineError;
