import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import Content from "../../components/content";
import { Helmet } from "react-helmet";
import Table from "../../components/table";
import apps from "./apps";
import { List } from "immutable";
import DropDown from "../../components/forms/dropdown";
import Loader from "../../components/ui/loader";
import AppName from "../../components/apps/app-name";
import AppPrivacy from "../../components/apps/privacy";
import * as OrgsApi from "../../../core/organisation/orgs-api";

import "./index.scss";

const Permissions = ({ teacherOnly, value = null, onChange, loading }) => {
  const options = [
    { value: teacherOnly ? null : 1, label: "Teachers only" },
    { value: -1, label: "Disabled for everyone" },
  ];
  if (!teacherOnly) {
    options.unshift({ value: null, label: "Teachers and students" });
  }
  const valueProp = options.find(o => o.value === value);
  return (
    <div style={{ position: "relative" }}>
      {loading ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 5,
            background: "rgba(255,255,255,0.75)",
          }}>
          <Loader tiny center />
        </div>
      ) : null}
      <DropDown
        options={options}
        value={valueProp}
        onChange={onChange}
        noborder
      />
    </div>
  );
};

function AppManagementPage(props) {
  const { organisationId, userId } = props;
  const [appSettings, setAppSettings] = useState(null);
  const [appSaving, setAppSaving] = useState({});

  useEffect(() => {
    const getSettings = async () => {
      const response = await OrgsApi.getOrganisationSettings(
        organisationId,
        userId
      );
      if (response.ok) {
        setAppSettings(response.data.settings.apps || {});
      }
    };

    if (!appSettings) {
      getSettings();
    }
  }, [organisationId, userId, appSettings]);

  if (!appSettings) {
    return null;
  }

  const tableData = {
    headings: [
      {
        name: "App",
      },
      {
        name: "Privacy",
      },
      {
        name: "Permissions",
      },
    ],
    body: new List(
      apps.map(app => {
        const handleChange = async change => {
          setAppSaving({
            ...appSaving,
            [app.id]: true,
          });

          const newSettings = {
            apps: { ...appSettings, [app.id]: change.value },
          };
          const response = await OrgsApi.updateSettings(
            organisationId,
            newSettings,
            userId
          );
          if (response.ok) {
            setAppSettings(response.data.settings.apps || {});
          }

          setAppSaving({
            ...appSaving,
            [app.id]: false,
          });
        };
        return {
          rowId: app.id,
          selected: false,
          cells: [
            <AppName
              name={app.name}
              id={app.id}
              disabled={appSettings[app.id] === -1}
            />,
            <AppPrivacy id={app.id} />,
            <Permissions
              teacherOnly={app.teacherOnly}
              value={appSettings[app.id]}
              onChange={handleChange}
              loading={appSaving[app.id]}
            />,
          ],
          sortData: {},
        };
      })
    ),
  };

  return (
    <div>
      <Header title="Manage Apps" />
      <Helmet title="Manage Apps" />
      <Content>
        <div className="manage-apps">
          <p className="manage-apps__intro">
            The Book Creator App Store allows teachers to enable additional Apps
            for their own use and optionally their students. Control which Apps
            your teachers can enable here.
          </p>
          <Table
            data={tableData}
            rowHeight={58}
            maxWidth={650}
            className="manage-apps__table"
          />
        </div>
      </Content>
    </div>
  );
}

export default AppManagementPage;
