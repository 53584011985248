import React, { Component } from "react";
import classNames from "classnames";
import "./form.scss";

class Form extends Component {
  render() {
    const {
      header,
      children,
      className,
      onSubmit,
      autocomplete,
      nofooter,
    } = this.props;

    const cssClass = classNames("form", {
      "form--no-header": !header,
      "form--no-footer": nofooter,
      [className]: className,
    });

    return (
      <form
        className={cssClass}
        noValidate
        autoComplete={autocomplete === false ? "off" : null}
        onSubmit={onSubmit}>
        {header ? <h1 className="form__header">{header}</h1> : null}
        {children}
      </form>
    );
  }
}

export default Form;
