import superagent from "superagent";
import { PAYMENTS_DOMAIN } from "../../config";

export function importTeachers(orgId: string, teachers: string[]) {
  return superagent
    .post(
      `${PAYMENTS_DOMAIN}/v1/admin/organisations/${orgId}/members/import/teachers`
    )
    .send(teachers)
    .withCredentials();
}
