export function isValidEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function isBCEmail(email) {
  const BOOKCREATOR_EMAIL = /@bookcreator\.com$/i;
  return BOOKCREATOR_EMAIL.test(email);
}

export function isValidUrl(url) {
  let urlRegEx = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g;
  return urlRegEx.test(url.toLowerCase());
}

export function isValidDomain(domain, allowLocalhost) {
  let domainRegEx = /^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/;
  if (domain === "localhost" && allowLocalhost) {
    return true;
  }
  return domainRegEx.test(domain.toLowerCase());
}

export function getEmailError(email, lang = "en") {
  return !email.length
    ? "Please enter your email address"
    : !isValidEmail(email)
    ? "Invalid email address"
    : null;
}

export function getPasswordError(password) {
  return password
    ? password.length > 5
      ? null
      : "Password must be at least 6 characters"
    : "Please enter a password";
}

export function getFullNameError(fullName) {
  return !fullName || !fullName.length ? "Please enter your name" : null;
}
