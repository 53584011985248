import CanvaIconAlt from "../../../images/apps/icons/canva--alt.png";
import BitmojiIcon from "../../../images/apps/icons/bitmoji.svg";
import GiphyIcon from "../../../images/apps/icons/giphy.svg";
import AnimatedEmojisThumbIcon from "../../../images/apps/icons/animatedemojis--thumb.png";
import GoogleMapsIcon from "../../../images/apps/icons/google-maps.svg";
import GoogleDriveIcon from "../../../images/apps/icons/google-drive.svg";
import TeacherStickersIconAlt from "../../../images/apps/icons/teacher-stickers--alt.png";
import SketchfabIconAlt from "../../../images/apps/icons/sketchfab--alt.svg";
import GraphicOrganizersIcon from "../../../images/apps/icons/graphicorganizers--thumb.svg";

export default function getIcon(id) {
  let icon;
  switch (id) {
    case "canva":
      icon = CanvaIconAlt;
      break;
    case "bitmoji":
      icon = BitmojiIcon;
      break;
    case "giphy":
      icon = GiphyIcon;
      break;
    case "animatedemojis":
      icon = AnimatedEmojisThumbIcon;
      break;
    case "googlemaps":
      icon = GoogleMapsIcon;
      break;
    case "googledrive":
      icon = GoogleDriveIcon;
      break;
    case "teacherstickers":
      icon = TeacherStickersIconAlt;
      break;
    case "sketchfab":
      icon = SketchfabIconAlt;
      break;
    case "graphicorganizers":
      icon = GraphicOrganizersIcon;
      break;
    default:
      break;
  }
  return icon;
}
