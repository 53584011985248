import React from "react";
import Item from "./item";
import { useState } from "react";
import "./index.scss";

interface TeacherNavProps {
  onChange: (showInactive: boolean) => void;
}

export interface IndicatorPosition {
  left: number;
  top: number;
  width: number;
  height: number;
}

export default function TeacherNav(props: TeacherNavProps) {
  const { onChange } = props;

  const [
    indicatorPosition,
    setIndicatorPosition,
  ] = useState<IndicatorPosition | null>(null);

  const activeView = "activeView";
  const inactiveView = "inactiveView";

  const [view, setView] = useState<string>(activeView);

  const handlePathChange = (view: string) => {
    setView(view);
    onChange(view === inactiveView);
  };

  return (
    <div className="teacher-nav" data-testid="teacher-nav">
      {indicatorPosition && (
        <span
          className="teacher-nav__indicator"
          style={{
            top: indicatorPosition.top,
            left: indicatorPosition.left,
            width: indicatorPosition.width,
            height: indicatorPosition.height,
          }}
        />
      )}
      <Item
        label="Active"
        testid="teacher-nav__active"
        active={view === activeView}
        onActive={(rect: IndicatorPosition) => setIndicatorPosition(rect)}
        onClick={() => handlePathChange(activeView)}
      />
      <Item
        label="Inactive"
        testid="teacher-nav__inactive"
        active={view === inactiveView}
        onActive={(rect: IndicatorPosition) => setIndicatorPosition(rect)}
        onClick={() => handlePathChange(inactiveView)}
      />
    </div>
  );
}
