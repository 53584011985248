import React from "react";
import SvgIcon from "../svg-icon";
import classNames from "classnames";
import StarIcon from "../../../../images/svg/star-badge.svg";
import "./index.scss";

export interface StatusBadgeProps {
  status: "Library Manager";
}

export default function StatusBadge({ status }: StatusBadgeProps) {
  const className = classNames("status-badge", {
    [`status-badge--${status.replace(/ /g, "-").toLowerCase()}`]: status,
  });

  return (
    <div className={className}>
      <SvgIcon icon={StarIcon} name="star" />
      <span className="status-badge__label">{status}</span>
    </div>
  );
}
