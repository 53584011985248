export function initialise(app_id) {
  const intercomSettings = {
    app_id,
  };
  var w = window as any;
  var ic = w.Intercom;
  if (typeof ic === "function") {
    ic("reattach_activator");
    ic("update", intercomSettings);
  } else {
    var d = document;
    var i: any = function () {
      i.c(arguments);
    };

    i.q = [];
    i.c = args => {
      i.q.push(args);
    };
    w.Intercom = i;
    var s = d.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.src = "https://widget.intercom.io/widget/" + app_id;
    var x = d.getElementsByTagName("body")[0];
    x.appendChild(s);
  }
}

interface IBootProps {
  app_id: string;
  user_id: string;
  user_hash: string;
  email: string;
  company?: {
    id: string;
    name: string;
  };
}

export function boot({
  app_id,
  user_id,
  user_hash,
  email,
  company,
}: IBootProps) {
  let payload = {
    app_id,
    user_id,
    user_hash,
    email,
    hide_default_launcher: true,
    custom_launcher_selector: "#intercom-toggle",
    company,
  };

  (window as any).Intercom("boot", payload);
}

export function shutdown() {
  (window as any).Intercom("shutdown");
}
