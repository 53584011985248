import React from "react";
import "./index.scss";

const Promo = props => {
  const {
    body,
    buttonText,
    disabled,
    image,
    imageClass,
    link,
    onClick,
    title,
  } = props;

  return (
    <div className="promo">
      <div className="promo__image">
        <img
          src={image}
          alt={title}
          className={`promo__img promo__img--${imageClass}`}
        />
      </div>
      <h2 className="promo__title">{title}</h2>
      <p className="promo__body">{body}</p>
      <a
        href={link}
        className="promo__button"
        disabled={disabled}
        onClick={onClick}
        // don't set rel=noreferrer as we own the target and the referrer will be useful in analytics
        // eslint-disable-next-line
        target="_blank">
        {buttonText}
      </a>
    </div>
  );
};

export default Promo;
