import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { authActions, getAuth, getEmailUnverified, isAuthenticated } from "../../../core/auth";
import { cutTheMustard } from "../../../utils/browser";

import { getOrganisationLoaded } from "../../../core/organisation";
import BookCreatorLogoColour from "../../../images/book-creator-logo-colour.png";
import ForgottenPassword from "../../components/sign-in/forgotten-password";
import Office365 from "../../components/sign-in/office-365";
import OrganisationPicker from "../../components/sign-in/organisation-picker";
import Register from "../../components/sign-in/register";
import SignIn from "../../components/sign-in/sign-in";
import SignInEmail from "../../components/sign-in/sign-in-with-email";
import VerifyEmail from "../../components/sign-in/verify";
import "./index.scss";

function SignInPage(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const { auth, authenticated, organisationLoaded, unverified } = props;

  useEffect(() => {
    if (!cutTheMustard) {
      navigate("/unsupported");
    }
  }, [navigate]);

  useEffect(() => {
    const returnUrl = () => {
      return location.state?.from || "/";
    };
    if (authenticated && organisationLoaded) {
      navigate("/", { state: { from: returnUrl() } });
    } else if (authenticated && !organisationLoaded && location.pathname !== "/sign-in/organisation") {
      navigate("/sign-in/organisation", { state: { from: returnUrl() } });
    } else if (unverified && location.pathname !== "/sign-in/verify-email" && !location.state?.loginRedirect) {
      navigate("/sign-in/verify-email");
    } else if (auth.error) {
      navigate("/sign-in");
    }
  }, [auth.error, authenticated, organisationLoaded, location, unverified, navigate]);

  return (
    <div className="sign-in">
      <div className="sign-in__content">
        <h1 className="sign-in__logo">
          <img src={BookCreatorLogoColour} alt="Book Creator" />
        </h1>
        <Routes>
          <Route path="/" element={<SignIn {...props} />} />
          <Route path="/office-365" element={<Office365 {...props} />} />
          <Route path="/register" element={<Register {...props} />} />
          <Route path="/register/:email" element={<Register {...props} />} />
          <Route path="/verify-email" element={<VerifyEmail {...props} />} />
          <Route path="/email" element={<SignInEmail {...props} />} />
          <Route path="/email/:email" element={<SignInEmail {...props} />} />
          <Route path="/forgotten-password" element={<ForgottenPassword {...props} />} />
          <Route path="/forgotten-password/:email" element={<ForgottenPassword {...props} />} />
          <Route path="/organisation" element={<OrganisationPicker {...props} loadOrgsOnMount />} />
        </Routes>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  authenticated: isAuthenticated(state),
  auth: getAuth(state),
  organisationLoaded: getOrganisationLoaded(state),
  unverified: getEmailUnverified(state),
});

const mapDispatchToProps = {
  ...authActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignInPage);
