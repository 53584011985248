import { useEffect } from "react";

export function useKeyDown(
  key,
  fn,
  el = document,
  preventDefault,
  stopPropagation
) {
  useEffect(() => {
    const handleKeyDown = evt => {
      if (evt.key === key) {
        fn(evt);
      }
      if (preventDefault) evt.preventDefault();
      if (stopPropagation) evt.stopPropagation();
    };
    el.addEventListener("keydown", handleKeyDown);
    return () => {
      el.removeEventListener("keydown", handleKeyDown);
    };
  }, [key, fn, el, preventDefault, stopPropagation]);
}
