import React, { Component } from "react";
import SvgIcon from "../../ui/svg-icon";
import ChatIcon from "../../../../images/svg/chat.svg";
import "./index.scss";

export default class IntercomToggle extends Component {
  render() {
    return (
      <button id="intercom-toggle" className="intercom-toggle">
        <SvgIcon icon={ChatIcon} name="chat" />
      </button>
    );
  }
}
