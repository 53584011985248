import { LibraryRole, LibraryRoleLabel, TabsLabels } from "./const";
import {
  BOOKCREATOR_ASSETS_URL,
  BOOKCREATOR_APP_URL,
} from "../../../../config";
import { Tab, List, LibProps, LibCount, Role } from "./types";

export const getRoleLabel = (role: Role) => {
  if (role === LibraryRole.OWNER) return LibraryRoleLabel.OWNER;
  else if (role <= LibraryRole.ADMIN) return LibraryRoleLabel.ADMIN;
  else if (role <= LibraryRole.TEACHER) return LibraryRoleLabel.TEACHER;
  else if (role <= LibraryRole.STUDENT) return LibraryRoleLabel.STUDENT;
  else return LibraryRoleLabel.READ_ONLY;
};

export const isVisitEnabled = (role: Role) => {
  return role <= LibraryRole.TEACHER;
};

export const getTabLabel = (tab: Tab, totalLibCount: LibCount): string =>
  `${TabsLabels[tab]} (${totalLibCount[tab]})`;

export const getAvatarUrl = (userId: string) =>
  `${BOOKCREATOR_ASSETS_URL}/${userId}/avatar`;

export const getVisitLibraryUrl = (token: string, libraryId: string) =>
  `${BOOKCREATOR_APP_URL}/sign-in-with-token/${token}?returnUrl=/library/${libraryId}`;

export const getBookCount = (lib: List<LibProps>) =>
  lib.reduce((total, lib) => (total += lib.bookCount || 0), 0);

export const getJoinedLibs = (lib: List<LibProps>) =>
  lib.reduce((total, lib) => [...total, lib.id], []);
