import React from "react";
import "./label.scss";

const SelectionHeader = props => {
  const { children } = props;

  return <span className="selection-header__label">{children}</span>;
};

export default SelectionHeader;
