import React, { Component } from "react";
import Helmet from "react-helmet";
import Header from "../../components/header";
import Content from "../../components/content";
import Resources from "../../components/resources";
import "./index.scss";

export default class TeacherResources extends Component {
  render() {
    return (
      <div className="teacher-resources-page">
        <Helmet title="Teacher Resources" />
        <Header title="Teacher Resources"></Header>
        <Content className="teacher-resources-page__content">
          <Resources />
        </Content>
      </div>
    );
  }
}
