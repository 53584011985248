import { Record, List } from "immutable";
import uuid from "node-uuid";
import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  ADD_ERROR_MESSAGE,
} from "./action-types";
import { SIGN_OUT_SUCCESS } from "../auth";

export const NotificationState = new Record({
  notifications: new List(),
});

export function notificationsReducer(
  state = new NotificationState(),
  { payload, type }
) {
  switch (type) {
    case ADD_NOTIFICATION:
      let notification;
      if (typeof payload === "object") {
        notification = payload;
      } else {
        notification = {
          message: payload,
          dismissAfter: 2000,
          key: uuid.v4(),
          barStyle: { background: "#455A63", zIndex: 999999 },
          actionStyle: { color: "#fff" },
        };
      }
      return state.merge({
        notifications: state.notifications.unshift(notification),
      });

    case REMOVE_NOTIFICATION:
      return state.merge({
        notifications: state.notifications.filter(
          notification => notification.key !== payload
        ),
      });

    case ADD_ERROR_MESSAGE:
      const errorMessage = {
        message: payload,
        key: uuid.v4(),
        action: "Dismiss",
        dismissAfter: 5000,
        barStyle: { background: "#da4453", zIndex: 999999 },
        actionStyle: { color: "#fff" },
      };
      return state.merge({
        notifications: state.notifications.unshift(errorMessage),
      });
    case SIGN_OUT_SUCCESS:
      return new NotificationState();
    default:
      return state;
  }
}
