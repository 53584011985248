import React from "react";
import classNames from "classnames";
import AnimatedEmojisIcon from "../../../../images/apps/animatedemojis.png";
import BitmojiIcon from "../../../../images/apps/bitmoji.svg";
import CanvaIcon from "../../../../images/apps/canva.png";
import GiphyIcon from "../../../../images/apps/giphy.svg";
import GoogleDriveIcon from "../../../../images/apps/google-drive.svg";
import GoogleMapsIcon from "../../../../images/apps/google-maps.svg";
import SketchfabIcon from "../../../../images/apps/sketchfab.png";
import TeacherStickersIcon from "../../../../images/apps/teacher-stickers.png";
import GraphicOrganizersIcon from "../../../../images/apps/graphicorganizers.svg";
import "./index.scss";
import SvgIcon from "../../../../common/components/SvgIcon";

function AppName(props) {
  const { disabled, name, id } = props;

  const className = classNames("app-name", {
    "app-name--disabled": disabled,
  });

  let icon;

  switch (id) {
    case "animatedemojis":
      icon = AnimatedEmojisIcon;
      break;
    case "bitmoji":
      icon = BitmojiIcon;
      break;
    case "canva":
      icon = CanvaIcon;
      break;
    case "giphy":
      icon = GiphyIcon;
      break;
    case "googledrive":
      icon = GoogleDriveIcon;
      break;
    case "googlemaps":
      icon = GoogleMapsIcon;
      break;
    case "sketchfab":
      icon = SketchfabIcon;
      break;
    case "teacherstickers":
      icon = TeacherStickersIcon;
      break;
    case "graphicorganizers":
      icon = GraphicOrganizersIcon;
      break;
    default:
      break;
  }

  return (
    <div className={className}>
      {typeof icon === "string" ? (
        <img src={icon} className="app-name__icon" alt={`${name} icon`} />
      ) : (
        <SvgIcon icon={icon} className="app-name__icon" name={name} />
      )}
      <span className="app-name__name">{name}</span>
    </div>
  );
}

export default AppName;
